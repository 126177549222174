<template>
  <div>
    <v-card outlined class="mx-auto pa-6 my-12">
      <div class="d-flex justify-space-around mb-6">
        <div v-for="conteudo in conteudos.slice(0, 5)" :key="conteudo.item">
          <i class="grey--text">{{ conteudo.label }}:</i>
          <br />
          {{ acessarConteudo(setor, conteudo) }}
        </div>
      </div>
      <v-divider class="mx-5"></v-divider>
      <div class="d-flex justify-space-around mt-3 mb-6">
        <div>
          <i class="grey--text">Alocacao:</i>
          <br />
          {{ setor.fila ? 'Fila' : 'Aleatório' }}
        </div>
        <div v-for="conteudo in conteudos.slice(5, 8)" :key="conteudo.item">
          <i class="grey--text">{{ conteudo.label }}:</i>
          <br />
          {{ vagas(setor.vagas, conteudo.item) }}
        </div>
        <div>
          <i class="grey--text">Bonificada:</i>
          <br />
          <v-checkbox
            class="mt-0"
            :label="setor.bonificada ? 'Sim' : 'Não'"
            v-model="setor.bonificada"
            disabled
          ></v-checkbox>
        </div>
      </div>
      <v-divider class="mx-4"></v-divider>
      <div class="d-flex flex-wrap pa-6 mx-6">
        <div
          class="d-flex flex-column mx-2 borded"
          style="width: 100px; cursor: pointer"
          v-for="vaga in setor.vagas.filter((vaga) => vaga.ativo)"
          :key="vaga.id"
          @click="selecionarVaga({ ...vaga, setor: setor })"
        >
          <v-icon x-large :color="vaga.livre ? 'green' : 'red'">
            mdi-truck
          </v-icon>
          <p class="text-caption text-center text--disabled">
            {{ vaga.placa || vaga.descricao }}
          </p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import {
  // mapState,
  mapActions,
} from 'vuex'
export default {
  props: {
    setor: {
      type: Object,
      required: true,
    },
  },
  name: 'DiagramaSetor',
  data() {
    return {
      dialog: false,
      tab: null,
      conteudos: [
        { item: 'descricao', data: null, label: 'Setor', default: '-' },
        {
          item: 'fornecedor',
          data: 'descricao',
          label: 'Fornecedor',
          default: 'Nenhum',
        },
        {
          item: 'terminal',
          data: 'descricao',
          label: 'Terminal',
          default: 'Nenhum',
        },
        {
          item: 'produto',
          data: 'descricao',
          label: 'Produto',
          default: 'Nenhum',
        },
        {
          item: 'subProduto',
          data: 'descricao',
          label: 'Sub Produto',
          default: 'Nenhum',
        },
        { item: 'Total', data: null, label: 'Vagas Totais', default: 0 },
        { item: 'Ocupadas', data: null, label: 'Vagas Ocupadas', default: 0 },
        { item: 'Livres', data: null, label: 'Vagas Livres', default: 0 },
      ],
    }
  },
  created() {
    console.log('CREATED!!')
  },
  computed: {},
  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    selecionarVaga(item) {
      this.$emit('selecionaVaga', item)

      for (const vaga of this.setor.vagas) {
        if (vaga.placa === undefined) {
          let indexValue = this.setor.vagas
            .map((vaga) => vaga.public_id)
            .indexOf(vaga.public_id)
          this.setor.vagas[indexValue].livre = true
        }
      }

      let indexValue = this.setor.vagas
        .map((vaga) => vaga.public_id)
        .indexOf(item.public_id)
      this.setor.vagas[indexValue].livre = false
    },
    acessarConteudo(item, conteudo) {
      if (conteudo.data == null)
        return item[conteudo.item] ? item[conteudo.item] : conteudo.default
      return item[conteudo.item]
        ? item[conteudo.item][conteudo.data]
        : conteudo.default
    },
    vagas(vagas = [], value) {
      if (value == 'Total') {
        return vagas.filter((vaga) => vaga.ativo).length
      }
      if (value == 'Ocupadas') {
        return vagas.filter((vaga) => vaga.ativo && !vaga.livre).length
      }
      if (value == 'Livres') {
        return vagas.filter((vaga) => vaga.ativo && vaga.livre).length
      }
      return 0
    },
  },
}
</script>
