<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="80%"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ title }}</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialog = false"> Fechar </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <cadastro-motorista
                @addedMotorista="addedMotorista"
                :motoristaExistente="motoristaExistente"
                v-if="dialog"
              />
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import CadastroMotorista from './CadastroMotorista'
export default {
  name: 'ModalMotorista',
  props: {
    motoristaExistente: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      dialog: false,
      title: 'Adicionar Motorista',
    }
  },
  components: {
    CadastroMotorista,
  },
  methods: {
    async addedMotorista(motorista, usuario) {
      this.dialog = false
      motorista.user = usuario
      this.$emit('addedMotorista', motorista)
    },
  },
  computed: {},
}
</script>
