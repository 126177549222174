<template>
  <base-modal :formTitle="formTitle">
    <template v-slot:body>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-autocomplete
            label="Navio"
            return-object
            :items="navios"
            item-text="descricao"
            item-value="id"
            v-model="novoNavio.item"
          />
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="novoNavio.pier"
            ref="pier"
            label="Pier"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-text-field
            v-model="novoNavio.di"
            ref="di"
            label="D.I."
            v-mask="['##/#######-#']"
          ></v-text-field>
        </v-col>
      </v-row>
    </template>
    <template v-slot:buttons>
      <buttons-cadastro @close="close" @save="save" />
    </template>
  </base-modal>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import BaseModal from '@/components/BaseModal/BaseModal.vue'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro.vue'

export default {
  components: { BaseModal, ButtonsCadastro },
  name: 'FormNavio',
  props: {
    navio: {
      type: Object,
      required: false,
    },
    gate: {
      type: Object,
      required: false,
    },
    formTitle: String,
    navioIndex: Number,
    value: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      novoNavio: this.value,
      formError: false,
    }
  },

  watch: {
    value(val) {
      Object.assign(this.novoNavio, val)
      this.$forceUpdate()
    },
    novoNavio(val) {
      this.$emit('input', val)
    },
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('patio', ['navios']),
  },

  methods: {
    ...mapActions('patio', ['getNavios']),

    ...mapMutations('patio', ['setNavios']),

    close() {
      this.novoNavio.pier = null
      this.novoNavio.di = null
      this.novoNavio.id = null
      this.$emit('close')
    },

    save() {
      this.$emit('save')
      this.$emit('close')
    },

    async atualizarListaNavios() {
      try {
        let naviosEmpresaAtual = await this.getNavios({ tenant: this.empresaAtual.public_id })
        if(naviosEmpresaAtual.length === 0){
          await this.getNavios({ tenant: this.gate.public_id })
        }
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperado.')
        }
      }
    },
  },

  async created() {
    if (this.navio) {
      this.novoNavio = { ...this.navio }
    }

    await this.atualizarListaNavios()
  },
}
</script>
<style></style>
