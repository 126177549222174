<template>
  <div>
    <div class="mt-4">
      <validation-observer ref="observer" v-slot="{ invalid }">
        <v-card-text class="py-6">
          <v-container>
            <form-wizard
              color="#00aa9e"
              :title="null"
              :subtitle="null"
              @on-complete="save"
            >
              <tab-content title="Dados do veiculo" icon="ti-user">
                <etapa-dados-veiculo
                  ref="etapaDadosVeiculo"
                  v-model="novoVeiculo"
                />
              </tab-content>

              <tab-content title="Anexar documentos" icon="ti-check">
                <etapa-documentos
                  ref="etapaDocumentos"
                  v-model="documentos"
                  @setNames="setDocs"
                  @remove-documento="removeDocumento"
                />
              </tab-content>
              <tab-content title="Incluir RFIDs" icon="ti-shortcode">
                <etapa-r-f-i-d
                  ref="etapaRFID"
                  v-model="rfids"
                  :veiculo="novoVeiculo"
                />
              </tab-content>
              <v-btn color="black" dark slot="prev">
                <i class="mdi mdi-chevron-left mr-1"></i>
                Anterior
              </v-btn>
              <v-btn color="info" slot="next">
                Próximo
                <i class="mdi mdi-chevron-right ml-1"></i>
              </v-btn>
              <v-btn color="success" slot="finish" :disabled="invalid">
                Finalizar
                <i class="mdi mdi-bookmark-check ml-1"></i>
              </v-btn>
            </form-wizard>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black darken-1" text @click="close">Cancelar</v-btn>
        </v-card-actions>
      </validation-observer>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import UtilsApi from '@/services/utils/utils.api'
import { cpf } from 'cpf-cnpj-validator'
import PatioApi from '@/services/patio/index'
import MotoristasApi from '@/services/tenants/motoristas.js'
import EtapaDadosVeiculo from './etapas/EtapaDadosVeiculo'
import EtapaDocumentos from './etapas/EtapaDocumentos'
import EtapaRFID from './etapas/EtapaRFID'

import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  components: {
    EtapaDadosVeiculo,
    EtapaDocumentos,
    EtapaRFID,
    FormWizard,
    TabContent,
  },

  name: 'CadastroVeiculo',
  props: {
    veiculoEdit: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isEdit: false,
      rfids: [],
      novoVeiculo: {},
      documentos: [],
      estados: [],
      cidades: [],
      motoristasSelecionados: [],
      motoristaVeiculo: [],
      search: '',
      errorsInput: {
        placa_cavalo: false,
        placa_carreta_1: false,
        placa_carreta_2: false,
        placa_carreta_3: false,
      },
      hasTransportadora: false,
    }
  },

  computed: {
    ...mapState('patio', ['carrocerias', 'modalidades', 'cores', 'marcas']),
    ...mapState('motoristas', ['motoristas']),
    ...mapState('auth', ['empresaAtual']),
    // ...mapState('transportadoras', ['transportadoras']),
  },

  async created() {
    if (!this.empresaAtual.public_id) return

    await this.getEstados()
    await this.getCarrocerias()
    await this.getCores()
    await this.getMarcas()
    await this.getModalidades()
    await this.getMotoristas()
    // await this.getTransportadoras()

    if (Object.keys(this.veiculoEdit || {}).length) {
      if (this.veiculoEdit.placa_cavalo !== '') {
        this.novoVeiculo = { ...this.veiculoEdit }
        this.atualizaDados()
        await this.onlyMotoristaAssociado({
          search: this.novoVeiculo.motorista,
        })
      }
    }
  },

  methods: {
    ...mapActions('patio', [
      'getCarrocerias',
      'getCores',
      'getMarcas',
      'getModalidades',
      'addVeiculo',
      'editVeiculo',
      'updateDocumentosVeiculo',
    ]),
    ...mapActions('motoristas', ['getMotoristas']),
    // ...mapActions('transportadoras', ['getTransportadoras']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    close() {
      this.$emit('addedVeiculo')
    },
    async updateDocs(listaDocs) {
      try {
        await this.updateDocumentosVeiculo({
          veiculo: this.novoVeiculo,
          documentos: listaDocs,
        })
        this.$emit('close')
        this.successMessage(
          `Veiculo ${this.novoVeiculo.placa_cavalo} editado com sucesso`
        )
      } catch (error) {
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },

    setDocs() {},
    removeDocumento(doc) {
      const index = this.documentosNome.indexOf(doc.attachment_key)
      if (index >= 0) {
        delete this.documentosNome[index]
        this.documentosNome = this.documentosNome.filter(
          (documento) => documento !== null
        )
      }

      if (this.documentosNome.length > 0) {
        this.btnConfirm = false
      } else {
        this.btnConfirm = true
      }
    },
    async addedMotorista(motorista) {
      this.motoristaVeiculo = motorista
      this.novoVeiculo.motorista = motorista.user.public_id
    },
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },

    atualizaDados() {
      Object.keys(this.novoVeiculo).forEach((key) => {
        if (
          typeof this.novoVeiculo[key] === 'object' &&
          this.novoVeiculo[key] !== null
        ) {
          if (key === 'motorista') {
            if (this.novoVeiculo[key].user !== null) {
              this.novoVeiculo[key] = this.novoVeiculo[key].user.public_id
            }
          } else {
            if (this.novoVeiculo[key].public_id)
              this.novoVeiculo[key] = this.novoVeiculo[key].public_id
            else if (this.novoVeiculo[key].id)
              this.novoVeiculo[key] = this.novoVeiculo[key].id
          }
        }
      })

      this.documentos = this.novoVeiculo.documentos || []
      this.$refs.etapaDocumentos.setDocumentosList(
        this.novoVeiculo.documentos || []
      )
      this.isEdit = Object.keys(this.novoVeiculo).length
    },

    async save() {
      if (this.validate()) {
        try {
          const documentosAdicionados = await this.$refs.etapaDocumentos.save()
          this.novoVeiculo.documentos = [...documentosAdicionados]
          let veiculo
          if (this.isEdit || this.novoVeiculo.id) {
            let proprietario = this.empresaAtual.public_id
            if (
              this.novoVeiculo.proprietario !== null &&
              this.novoVeiculo.proprietario !== '' &&
              this.novoVeiculo.proprietario !== undefined
            ) {
              proprietario = this.novoVeiculo.proprietario
            }
            veiculo = await this.editVeiculo({
              ...this.novoVeiculo,
              proprietario: proprietario,
            })
            this.$emit('addedVeiculo', veiculo)
            this.successMessage('Veículo atualizado com sucesso!')
          } else {
            if (this.novoVeiculo.marca === undefined) {
              this.novoVeiculo.marca = 1
            }
            if (this.novoVeiculo.tara === undefined) {
              this.novoVeiculo.tara = 0
            }
            if (this.novoVeiculo.cor === undefined) {
              this.novoVeiculo.cor = 1
            }
            veiculo = await this.addVeiculo({
              ...this.novoVeiculo,
              proprietario: this.empresaAtual.public_id,
            })
            this.$emit('addedVeiculo', veiculo)
            this.successMessage('Veículo adicionado com sucesso!')
          }
          await this.$refs.etapaRFID.save(veiculo)
          this.novoVeiculo.id = null
        } catch (error) {
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },

    async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },

    wordUpper(event) {
      event.target.value = event.target.value.toUpperCase()
    },

    wordUpperChange(field, value) {
      if (field === 'placa_cavalo') {
        this.novoVeiculo = {}
        this.getVeiculoPlacaCavalo(value)
      }

      this.novoVeiculo[field] = value.toUpperCase()
    },

    async getVeiculoPlacaCavalo(value) {
      const veiculo = await PatioApi.getVeiculoParams({
        placa_cavalo: value,
        tenant: this.empresaAtual.public_id,
      })
      this.isEdit = false
      if (veiculo[0]) {
        this.novoVeiculo = veiculo[0]
        this.atualizaDados()
      }
    },

    modalidadeText(item) {
      return `${item.descricao} - ${item.eixo} ${
        item.eixo > 1 ? 'eixos' : 'eixo'
      }`
    },

    motoristaText(item) {
      return `${item.user.name} - ${cpf.format(item.user.cpf)}`
    },

    async getMotoristaSearch(parametros) {
      if (!Object.keys(parametros).length) return

      try {
        const motorista = await MotoristasApi.getMotorista(
          this.empresaAtual,
          parametros
        )

        this.motoristasSelecionados = motorista
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },

    async onlyMotoristaAssociado(parametros) {
      try {
        const motoristaAssociado = await MotoristasApi.getMotorista(
          this.empresaAtual,
          parametros
        )
        this.motoristaVeiculo = motoristaAssociado
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },
  },
  watch: {
    async search(search) {
      if (search && search.length > 2 && search.length <= 3) {
        await this.getMotoristaSearch({
          search: search,
        })
      } else if (search && search.length > 3 && search.length <= 5) {
        await this.getMotoristaSearch({
          search: search,
        })
      } else if (search && search.length < 2) {
        this.motoristasSelecionados = []
      }
    },
  },
}
</script>
<style></style>
