var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',[_c('v-card',{staticClass:"mb-2"},[_c('v-card-text',{staticClass:"pa-2 border-bottom"},[_c('h3',{staticClass:"blue-grey--text text--darken-2 font-weight-regular"},[_vm._v(" Serviços Autorizados ")])]),_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('v-form',{attrs:{"id":"formServicosAutorizados"}},[_c('v-row',{staticClass:"justify-center"},_vm._l((_vm.servicosAutorizados),function(servicoAutorizado,index){return _c('v-col',{key:index,staticClass:"servicos pt-5 border-bottom",attrs:{"cols":"12","sm":"12","lg":"3","md":"3"}},[_c('div',[(
                    servicoAutorizado.servico.foto &&
                    servicoAutorizado.servico.foto.url
                  )?_c('img',{staticClass:"pt-2",attrs:{"src":servicoAutorizado.servico.foto.url,"width":"100"}}):_c('img',{staticClass:"pt-2",attrs:{"src":require('@/assets/images/servicos/serviceGeneric.png'),"width":"100"}})]),_c('div',[_c('p',[_vm._v(_vm._s(servicoAutorizado.servico.descricao))])]),(_vm.isTriagem || _vm.isEdicaoTriado)?_c('div',{staticClass:"pt-1 align-center"},[_c('validation-provider',{attrs:{"name":"Forma de Pagamento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Forma de Pagamento","items":_vm.isServicoAgendadoNoConvenio(servicoAutorizado)
                        ? _vm.formasPagamentoConvenio
                        : _vm.formasPagamento,"item-text":"descricao","return-object":"","error-messages":errors},model:{value:(servicoAutorizado.forma_pagamento),callback:function ($$v) {_vm.$set(servicoAutorizado, "forma_pagamento", $$v)},expression:"servicoAutorizado.forma_pagamento"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Transportadora","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [(
                      servicoAutorizado.forma_pagamento &&
                      servicoAutorizado.forma_pagamento.is_convenio
                    )?_c('v-select',{attrs:{"label":"Transportadora","items":_vm.theTransportadoras,"return-object":"","item-text":function (item) { return item.transportadora.nome +
                        ' - ' +
                        "" + (item.transportadora.cnpj != null
                            ? item.transportadora.cnpj
                            : item.transportadora.cpf); },"item-value":"public_id","error-messages":errors},model:{value:(servicoAutorizado.transportadora),callback:function ($$v) {_vm.$set(servicoAutorizado, "transportadora", $$v)},expression:"servicoAutorizado.transportadora"}}):_vm._e()]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Valor unitario","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('vuetify-money',{ref:"valor",refInFor:true,attrs:{"disabled":_vm.enableValor,"readonly":servicoAutorizado.valorUnitario === 0 ||
                      _vm.isServicoAgendadoNoConvenio(servicoAutorizado),"error-messages":errors,"label":"Valor unitário","options":_vm.options},model:{value:(servicoAutorizado.valorUnitario),callback:function ($$v) {_vm.$set(servicoAutorizado, "valorUnitario", $$v)},expression:"servicoAutorizado.valorUnitario"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Valor total","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('vuetify-money',{ref:"valor",refInFor:true,attrs:{"disabled":_vm.enableValor,"readonly":servicoAutorizado.valorTotal === 0 ||
                      _vm.isServicoAgendadoNoConvenio(servicoAutorizado),"error-messages":errors,"label":"Valor total","options":_vm.options},model:{value:(servicoAutorizado.valorTotal),callback:function ($$v) {_vm.$set(servicoAutorizado, "valorTotal", $$v)},expression:"servicoAutorizado.valorTotal"}})]}}],null,true)})],1):_vm._e(),(servicoAutorizado.tempo_empatio)?_c('div',{staticClass:"text-center"},[_c('h2',[_vm._v(" Tempo em patio: "+_vm._s(servicoAutorizado.tempo_empatio)+" Horas ")])]):_vm._e(),(servicoAutorizado.entrada)?_c('div',{staticClass:"text-center"},[_c('h2',[_vm._v("Entrada: "+_vm._s(servicoAutorizado.entrada))])]):_vm._e(),(servicoAutorizado.saida)?_c('div',{staticClass:"text-center"},[_c('h2',[_vm._v("Saida: "+_vm._s(servicoAutorizado.saida))])]):_vm._e(),_c('div',[_c('div',{staticClass:"text-center"},[_c('v-icon',{staticStyle:{"font-size":"36px"},attrs:{"color":"error"},on:{"click":function($event){return _vm.$emit('deleteServico', index)}}},[_vm._v("mdi-delete")])],1)])])}),1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }