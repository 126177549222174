var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer"},[_c('v-card-text',{staticClass:"py-6"},[_c('v-row',{staticClass:"d-flex justify-center mb-0 pb-0"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"placa","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['AAA-XXX', 'AAA-XXXX']),expression:"['AAA-XXX', 'AAA-XXXX']"}],attrs:{"label":"Placa","prepend-icon":"mdi-caravan","hint":"Digite a placa do implemento.","persistent-hint":"","error-messages":errors,"disabled":_vm.isEdit},on:{"keyup":_vm.wordUpper},model:{value:(_vm.value.placa),callback:function ($$v) {_vm.$set(_vm.value, "placa", $$v)},expression:"value.placa"}})]}}])})],1)],1),_c('v-row',{staticClass:"d-flex justify-center mb-0 pb-0"},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"chassis"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Chassis","prepend-icon":"mdi-alphabetical","hint":"Digite a chassis do implemento.","persistent-hint":"","error-messages":errors},on:{"change":function($event){return _vm.validarChassis(_vm.value.chassis)}},model:{value:(_vm.value.chassis),callback:function ($$v) {_vm.$set(_vm.value, "chassis", $$v)},expression:"value.chassis"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"renavam"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Renavam","prepend-icon":"mdi-alphabetical","hint":"Digite o renavam do implemento.","persistent-hint":"","error-messages":errors},model:{value:(_vm.value.renavam),callback:function ($$v) {_vm.$set(_vm.value, "renavam", $$v)},expression:"value.renavam"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"manufacturing_date"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Data de fabricação","prepend-icon":"mdi-calendar-range","hint":"Digite a data de fabricação do implemento.","persistent-hint":"","type":"date","error-messages":errors},model:{value:(_vm.value.manufacturing_date),callback:function ($$v) {_vm.$set(_vm.value, "manufacturing_date", $$v)},expression:"value.manufacturing_date"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"modalidade"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Modalidade","prepend-icon":"mdi-tag-outline","hint":"Escolha a modalidade do implemento.","persistent-hint":"","items":_vm.modalidades,"item-text":_vm.modalidadeText,"item-value":"id","error-messages":errors},model:{value:(_vm.value.modalidade),callback:function ($$v) {_vm.$set(_vm.value, "modalidade", $$v)},expression:"value.modalidade"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{attrs:{"disabled":false,"label":"PBT"},model:{value:(_vm.value.tara),callback:function ($$v) {_vm.$set(_vm.value, "tara", $$v)},expression:"value.tara"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"3"}},[_c('validation-provider',{attrs:{"name":"estados","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Estado","items":_vm.estados,"item-text":"nome","item-value":"id","error-messages":errors},model:{value:(_vm.value.estado),callback:function ($$v) {_vm.$set(_vm.value, "estado", $$v)},expression:"value.estado"}})]}}])})],1)],1)],1),(_vm.hasButtonSave)?_c('v-card-actions',[_c('v-spacer'),_c('buttons-cadastro',{on:{"close":_vm.close,"save":_vm.save}})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }