<template>
  <div>
    <validation-observer ref="observer">
      <v-card-text class="py-6">
        <v-row class="d-flex justify-center mb-0 pb-0">
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              name="placa"
              rules="required"
            >
              <v-text-field
                v-model="value.placa"
                label="Placa"
                prepend-icon="mdi-caravan"
                hint="Digite a placa do implemento."
                persistent-hint
                v-mask="['AAA-XXX', 'AAA-XXXX']"
                @keyup="wordUpper"
                :error-messages="errors"
                :disabled="isEdit"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center mb-0 pb-0">
          <v-col cols="12" sm="12" md="4">
            <validation-provider v-slot="{ errors }" name="chassis">
              <v-text-field
                v-model="value.chassis"
                label="Chassis"
                prepend-icon="mdi-alphabetical"
                hint="Digite a chassis do implemento."
                @change="validarChassis(value.chassis)"
                persistent-hint
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <validation-provider v-slot="{ errors }" name="renavam">
              <v-text-field
                v-model="value.renavam"
                label="Renavam"
                prepend-icon="mdi-alphabetical"
                hint="Digite o renavam do implemento."
                persistent-hint
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <validation-provider v-slot="{ errors }" name="manufacturing_date">
              <v-text-field
                v-model="value.manufacturing_date"
                label="Data de fabricação"
                prepend-icon="mdi-calendar-range"
                hint="Digite a data de fabricação do implemento."
                persistent-hint
                type="date"
                :error-messages="errors"
              />
            </validation-provider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="4">
            <validation-provider v-slot="{ errors }" name="modalidade">
              <v-autocomplete
                v-model="value.modalidade"
                label="Modalidade"
                prepend-icon="mdi-tag-outline"
                hint="Escolha a modalidade do implemento."
                persistent-hint
                :items="modalidades"
                :item-text="modalidadeText"
                item-value="id"
                :error-messages="errors"
              ></v-autocomplete>
            </validation-provider>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              :disabled="false"
              v-model="value.tara"
              label="PBT"
            />
          </v-col>
          <v-col cols="12" sm="12" md="3">
            <validation-provider
              v-slot="{ errors }"
              name="estados"
              rules="required"
            >
              <v-autocomplete
                label="Estado"
                :items="estados"
                v-model="value.estado"
                item-text="nome"
                item-value="id"
                :error-messages="errors"
              ></v-autocomplete>
            </validation-provider>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="hasButtonSave">
        <v-spacer></v-spacer>
        <buttons-cadastro @close="close" @save="save" />
      </v-card-actions>
    </validation-observer>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import UtilsApi from '@/services/utils/utils.api'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'

export default {
  name: 'EtapaDadosImplemento',
  props: {
    value: {
      type: Object,
      required: false,
    },
    hasButtonSave: {
      type: Boolean,
      required: false,
      default: false,
    },
    edit: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      estados: [],
    }
  },
  components: { ButtonsCadastro },

  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('patio', ['modalidades']),
    isEdit() {
      return this.edit
    },
  },
  async created() {
    await this.getModalidadesImplementos()
    await this.getEstados()
  },
  methods: {
    ...mapActions('implemento', ['addImplemento']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    ...mapActions('patio', ['getModalidadesImplementos']),
    close() {
      this.$emit('close')
    },
    async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },
    modalidadeText(item) {
      return `${item.descricao} - ${item.eixo} ${
        item.eixo > 1 ? 'eixos' : 'eixo'
      }`
    },
    wordUpper(event) {
      event.target.value = event.target.value.toUpperCase()
    },
    wordUpperChange(field, value) {
      this.value[field] = value.toUpperCase()
    },
    async save() {
      this.$emit('save')
    },
    validarChassis(chassis) {
      if (chassis.length < 8) {
        this.value.chassis = ""
        alert("Chassi invalido deve ser maior 8 caracteres")
      }
      if (chassis.length > 20) {
        this.value.chassis = ""
        alert("Chassi invalido deve ser menor 20 caracteres")
      }
    },
  },
}
</script>
<style></style>
