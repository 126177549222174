<template>
  <v-card-text class="pa-5 border-bottom">
    <validation-observer ref="observer">
      <v-row class="justify-center d-flex py-10">
        <v-data-table
          :headers="headers"
          :items="documentosList"
          sort-by="descricao"
          class="border"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Documentos adicionados</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" @click="abrirArquivo"
                >Novo Documento</v-btn
              >
              <input
                type="file"
                ref="file"
                @change="changeArquivo"
                style="display: none"
                accept="application/pdf"
              />
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              class="mx-2"
              @click="verDocumento(item)"
              v-if="!item.necessity_send"
              >mdi-eye</v-icon
            >
            <v-icon small @click="excluirDocumento(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <span>Sem documentos adicionados</span>
          </template>
        </v-data-table>
      </v-row>
    </validation-observer>
    <v-card-actions v-if="hasButtonSave">
      <v-spacer></v-spacer>
      <buttons-cadastro @close="$emit('close')" @save="save" />
    </v-card-actions>
  </v-card-text>
</template>

<style lang="scss">
.button-submit-green {
  background-color: #00aa9e !important;
  color: white !important;
}
</style>

<script>
import { mapActions } from 'vuex'

import FileApi from '@/services/files/index'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'

export default {
  name: 'EtapaDocumentos',
  props: {
    value: Array,
    hasButtonSave: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { ButtonsCadastro },
  data() {
    return {
      documentoAtual: {},
      editandoContato: false,
      documentosList: this.value,
      documento_id: 0,
      documentos: [],
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'description',
        },
        { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
      ],
    }
  },
  watch: {
    value(val) {
      Object.assign(this.documentosList, val)
      this.$forceUpdate()
    },
    documentosList(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    ...mapActions('template', ['errorMessage']),

    async sendDocs() {
      const documentos = this.documentos
      let names = []
      try {
        for (const documento of documentos) {
          if (!documento.necessity_send) {
            names.push(documento.attachment_key)
          } else {
            let formData = new FormData()
            formData.append('description', documento.name)
            formData.append('file', documento)
            const data = await FileApi.postDocsMotorista(formData)
            if (data.attachment_key) {
              names.push(data.attachment_key)
            }
          }
        }
        return names
      } catch (e) {
        if (e.response.status === 413) {
          this.errorMessage('Arquivo(s) muito longo(s)')
        }
      }
      //   this.$emit('setNames', names)
    },

    async save() {
      const listaDocumentos = this.documentosList
        .filter((e) => e.attachment_key)
        .map((e) => e.attachment_key)
      const novosDocs = await this.sendDocs()
      const listaAtualizada = [...listaDocumentos, ...novosDocs]
      this.$emit('updateDocs', listaAtualizada)
      return listaAtualizada
    },
    async verDocumento(documento) {
      if (documento.url) {
        window.open(documento.url, '_blank')
      }
    },
    async fecharDocumento() {
      this.dialog = false
      this.documento = undefined
    },
    excluirDocumento(documento) {
      if (documento.id) {
        const indexDocList = this.documentosList.indexOf(
          this.documentosList.filter((doc) => doc.id == documento.id)[0]
        )
        const indexDoc = this.documentos.indexOf(
          this.documentos.filter((doc) => doc.id == documento.id)[0]
        )

        // this.$emit('remove-documento', this.documentosList[indexDocList])

        // if (indexDoc > -1 && indexDocList > -1) {
        if (indexDoc > -1) {
          this.documentosList.splice(indexDocList, 1)
          this.documentos.splice(indexDoc, 1)
        }
      } else {
        const indexDocList = this.documentosList.indexOf(
          this.documentosList.filter(
            (doc) => doc.public_id == documento.public_id
          )[0]
        )
        // const indexDoc = this.documentos.indexOf(
        //   this.documentos.filter(
        //     (doc) => doc.public_id == documento.public_id
        //   )[0]
        // )

        // this.$emit('remove-documento', this.documentosList[indexDocList])

        // if (indexDoc > -1 && indexDocList > -1) {
        if (indexDocList > -1) {
          this.documentosList.splice(indexDocList, 1)
          //   this.documentos.splice(indexDoc, 1)
        }
      }
    },

    setDocumentosList(documentos) {
      this.documentosList = [...documentos]
    },

    abrirArquivo() {
      this.$refs['file'].click()
    },

    changeArquivo(e) {
      const files = e.target.files
      this.documento_id += 1
      files[0].id = this.documento_id
      this.documentosList.push({
        description: files[0].name,
        id: this.documento_id,
        necessity_send: true,
      })
      files.forEach((file) => {
        this.documentos.push(file)
        this.documentos[this.documentos.length - 1].necessity_send = true
      })
    },
  },
}
</script>
