<template>
  <v-card-text class="pa-5 border-bottom">
    <validation-observer ref="observer">
      <v-row class="justify-center d-flex py-10">
        <v-data-table
          :headers="headers"
          :items="documentosList"
          sort-by="descricao"
          class="border"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-toolbar-title>Documentos adicionados</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn color="primary" dark class="mb-2" @click="abrirArquivo"
                >Novo Documento</v-btn
              >
              <input
                type="file"
                ref="file"
                @change="changeArquivo"
                style="display: none"
                accept="application/pdf"
              />
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="excluirDocumento(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <span>Sem documentos adicionados</span>
          </template>
        </v-data-table>
      </v-row>
    </validation-observer>
  </v-card-text>
</template>

<style lang="scss">
.button-submit-green {
  background-color: #00aa9e !important;
  color: white !important;
}
</style>

<script>
import FileApi from '@/services/files/index'
import { mapActions } from 'vuex'
export default {
  name: 'EtapaDocumentos',
  props: {
    documentos: Array,
    documentosList: Array,
  },
  data() {
    return {
      editandoAgendamento: false,
      documento_id: 0,
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'description',
        },
        { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
      ],
    }
  },
  methods: {
    ...mapActions('template', ['errorMessage']),
    async sendDocs() {
      const documentos = this.documentos
      let names = []
      let errors = {}
      await Promise.all(
        documentos.map(async (documento) => {
          if (!documento.necessity_send) {
            names.push(documento.attachment_key)
            return
          }

          let formData = new FormData()
          formData.append('description', documento.name)
          formData.append('file', documento)
          try {
            let data = await FileApi.postAnexosAgendamento(formData)
            if (data.attachment_key) {
              names.push(data.attachment_key)
            }
          } catch (error) {
            if (error.response) {
              errors[documento.name] = error.response.data
              return
            }
            if (error.message) {
              errors[documento.name] = error.message
              return
            }
            errors[documento.name] = error
          }
        })
      )
      if (Object.entries(errors).length) {
        this.errorMessage(errors)
      }
      this.$emit('setNames', names)
    },

    excluirDocumento(documento) {
      if (documento.id) {
        const indexDocList = this.documentosList.indexOf(
          this.documentosList.filter((doc) => doc.id == documento.id)[0]
        )
        const indexDoc = this.documentos.indexOf(
          this.documentos.filter((doc) => doc.id == documento.id)[0]
        )

        this.$emit('remove-documento', this.documentosList[indexDocList])

        if (indexDoc > -1 && indexDocList > -1) {
          this.documentosList.splice(indexDocList, 1)
          this.documentos.splice(indexDoc, 1)
        }
      } else {
        const indexDocList = this.documentosList.indexOf(
          this.documentosList.filter(
            (doc) => doc.public_id == documento.public_id
          )[0]
        )
        const indexDoc = this.documentos.indexOf(
          this.documentos.filter(
            (doc) => doc.public_id == documento.public_id
          )[0]
        )

        this.$emit('remove-documento', this.documentosList[indexDocList])

        if (indexDoc > -1 && indexDocList > -1) {
          this.documentosList.splice(indexDocList, 1)
          this.documentos.splice(indexDoc, 1)
        }
      }
    },

    setDocumentosList(documentos) {
      this.documentosList = [...documentos]
    },

    abrirArquivo() {
      this.$refs['file'].click()
    },

    changeArquivo(e) {
      const files = e.target.files
      this.documento_id += 1

      this.documentosList.forEach((data) => {
        Object.keys(data).forEach((key) => {
          if (data[key] == this.documento_id) {
            this.documento_id += 1
          }
        })
      })

      files[0].id = this.documento_id
      this.documentosList.push({
        description: files[0].name,
        id: this.documento_id,
        necessity_send: true,
      })
      files.forEach((file) => {
        this.documentos.push(file)
        this.documentos[this.documentos.length - 1].necessity_send = true
      })

      this.sendDocs()
    },
  },
}
</script>
