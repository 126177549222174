import axios from 'axios'

class FileApi {
  async postFotoMotorista(foto) {
    try {
      const { data } = await axios.post('api/v1/media/images/', foto, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async postDocsMotorista(docs) {
    try {
      const { data } = await axios.post('api/v1/media/documents/', docs, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async postDocsVeiculo(docs) {
    try {
      const { data } = await axios.post('api/v1/media/documents/', docs, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async postDocs(docs) {
    try {
      const { data } = await axios.post('api/v1/media/documents/', docs, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async postAnexosAgendamento(docs) {
    try {
      const { data } = await axios.post('api/v1/media/documents/', docs, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
  async postImagensEmpresa(docs) {
    try {
      const { data } = await axios.post('api/v1/media/images/', docs, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  async convertXmlToJson(formData) {
    try {
      const {
        data,
      } = await axios.post(
        'api/v1/media/documents/convert_xml_to_json/',
        formData,
        { headers: { 'Content-Type': 'application/xml' } }
      )
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

export default new FileApi()
