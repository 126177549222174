<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Nova triagem">
          <form-adicionar-agendamento @exibirComprovante="exibirComprovante" />
          <v-dialog v-model="showComprovante" fullscreen>
            <v-card>
              <comprovante ref="comprovante" />
            </v-card>
          </v-dialog>
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import TipoAgendamento from '@/utils/agendamento'

import FormAdicionarAgendamento from '@/components/patio/NovoAgendamento/FormAdicionarAgendamento.vue'
import Comprovante from '@/components/patio/NovoAgendamento/comprovante/Componente.vue'

export default {
  name: 'Triagem',
  provide() {
    return {
      fecharComprovanteTriagem: this.fecharComprovanteTriagem,
    }
  },
  props: {
    tipoAgendamento: {
      type: Number,
      default: TipoAgendamento.Triagem,
    },
    agendamentoSelecionado: {
      type: Object,
    },
  },
  data() {
    return {
      showForm: false,
      showComprovante: false,

      page: {
        title: 'Nova Triagem',
      },
      breadcrumbs: [
        {
          text: 'Logística',
          disabled: false,
          to: '/patio/patio',
        },
        {
          text: 'Triagem',
          disabled: true,
        },
      ],
    }
  },
  components: {
    FormAdicionarAgendamento,
    Comprovante,
  },
  computed: {
    ...mapState('agendamentos', ['agendamento']),
  },
  created() {
    this.reset()
    this.updateAtributo({
      valor: this.tipoAgendamento,
      nomeAtributo: 'tipoAgendamento',
    })
    if (
      this.tipoAgendamento === TipoAgendamento.Triagem ||
      this.tipoAgendamento === TipoAgendamento.EdicaoTriado ||
      this.tipoAgendamento === TipoAgendamento.EdicaoAgendado
    ) {
      this.updateAtributo({
        valor: this.agendamentoSelecionado,
        nomeAtributo: 'agendamentoSelecionado',
      })
      this.updateAtributoAgendamento({
        valor: this.agendamentoSelecionado.tipo_documento,
        nomeAtributo: 'tipo_documento',
      })
      this.updateAtributoAgendamento({
        valor: this.agendamentoSelecionado.anexos,
        nomeAtributo: 'anexos',
      })
    }

    this.agendamentoSelecionado.veiculo.conjunto_atual = this.agendamentoSelecionado.conjunto
    this.agendamentoSelecionado.veiculo.placa_carreta_1 = this.agendamentoSelecionado.conjunto.carreta_1
    this.agendamentoSelecionado.veiculo.placa_carreta_2 = this.agendamentoSelecionado.conjunto.carreta_2
    this.agendamentoSelecionado.veiculo.placa_carreta_3 = this.agendamentoSelecionado.conjunto.carreta_3
    this.agendamentoSelecionado.veiculo.dolly = this.agendamentoSelecionado.conjunto.dolly
  },
  methods: {
    ...mapActions('agendamentos', [
      'reset',
      'updateAtributo',
      'updateAtributoAgendamento',
    ]),

    async exibirComprovante() {
      this.showComprovante = true
      await this.$nextTick()
      await this.$refs.comprovante.imprimirComprovante()
    },

    fecharComprovanteTriagem() {
      this.showComprovante = false
      this.$router.push({
        name: 'Gerenciamento de Serviços',
      })
    },
  },
}
</script>

<style></style>
