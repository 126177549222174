<template>
  <v-container class="container-comprovante text-center text-subtitle-2">
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <span class="text-caption">{{ agendamento.public_id }}</span>
      </v-col>
    </v-row>
    <v-row id="actions">
      <v-col class="d-flex pa-8">
        <v-btn type="button" color="primary" @click="fecharComprovanteTriagem">
          Fechar
        </v-btn>
        <v-spacer />
        <v-btn color="secondary" @click="imprimirComprovante">Imprimir</v-btn>
      </v-col>
    </v-row>
    <v-row
      v-if="
        agendamento.gate.public_id !== '4856f9c1-94d6-4daa-90b6-5b9d4e5c44c8' ||
        agendamento.gate.public_id !== '9c027477-7337-4b6f-b686-9079a35dd1c0'
      "
    >
      <v-col class="col-comprovante">
        <p><strong>Comprovante de Entrada</strong></p>
        <p class="mt-2 aviso">Ao descer do veículo, uso obrigatório de</p>
        <p class="mt-2 aviso">calço na roda, enquanto no pátio.</p>
      </v-col>
    </v-row>
    <v-row class="row-gate" v-if="mostraItem">
      <v-col class="col-comprovante">
        <strong>{{
          agendamento.gate.business_name ||
          agendamento.gate.tenant ||
          defaultValue
        }}</strong>
        <p>
          CNPJ: <strong>{{ agendamento.gate.cnpj || defaultValue }}</strong>
        </p>
      </v-col>
    </v-row>

    <v-row class="row-cliente">
      <v-col class="col-comprovante">
        <p>Cliente:</p>
        <p>
          <strong>{{
            agendamento.cliente.business_name || defaultValue
          }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="row-cliente">
      <v-col class="col-comprovante">
        <p>Transportadora:</p>
        <p>
          <strong>{{
            agendamento.transportadora.business_name || defaultValue
          }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="row-cliente">
      <v-col class="col-comprovante">
        <p>Usuário:</p>
        <p>
          <strong>{{ agendamento.agendado_por || defaultValue }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="row-veiculo">
      <v-col class="col-comprovante">
        <p>
          Cavalo:
          <strong>{{
            agendamento.veiculo.placa_cavalo || defaultValue
          }}</strong>
        </p>
        <div
          v-if="
            agendamento.fornecedor.public_id ==
            '11bdbc50-57c0-40db-bd96-f28a02b964b5'
          "
        >
          <p>
            Carreta 1:
            <strong>{{
              agendamento.veiculo.placa_carreta_1 || defaultValue
            }}</strong>
          </p>
          <p>
            Carreta 2:
            <strong>{{
              agendamento.veiculo.placa_carreta_2 || defaultValue
            }}</strong>
          </p>
        </div>
        <p>
          Modalidade:
          <strong>{{
            agendamento.veiculo.modalidade.descricao || defaultValue
          }}</strong>
        </p>
        <p>
          Carroceria:
          <strong>{{
            agendamento.veiculo.carroceria.descricao || defaultValue
          }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row class="row-motorista">
      <v-col class="col-comprovante">
        <p>Motorista:</p>
        <p>
          <strong>{{ agendamento.motorista.user.name || defaultValue }}</strong>
        </p>
        <p>
          Cpf:
          <strong>{{ agendamento.motorista.user.cpf || defaultValue }}</strong>
        </p>
        <p>
          Celular:
          <strong>{{
            agendamento.motorista.user.celular || defaultValue
          }}</strong>
        </p>
        <div
          v-if="
            agendamento.fornecedor.public_id ==
            '11bdbc50-57c0-40db-bd96-f28a02b964b5'
          "
        >
          <p>Tag:</p>
          <p>
            <strong>{{ agendamento.access_credential || defaultValue }}</strong>
          </p>
          <p>Cracha:</p>
          <p>
            <strong>{{ agendamento.access_credential || defaultValue }}</strong>
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="row-horarios">
      <v-col class="col-comprovante">
        <div v-if="agendamento.data_cota">
          <p>Data da cota para Carga/Descarga:</p>
          <p>
            <strong v-if="cota_dia_todo(agendamento.data_cota)">{{
              agendamento.data_cota.data_inicio | iso2br
            }}</strong>
            <strong v-else-if="cota_mesmo_dia(agendamento.data_cota)">
              {{ mostra_cota_mesmo_dia(agendamento.data_cota) }}</strong
            >
            <strong v-else>
              {{ agendamento.data_cota.data_inicio | toBrazilianDateTime }}
              -
              {{ agendamento.data_cota.data_fim | toBrazilianDateTime }}
            </strong>
          </p>
        </div>
        <div v-if="agendamento.data_janela">
          <p>Data da janela para Carga/Descarga:</p>
          <p>
            <strong>
              {{
                agendamento.data_janela.data_inicio | toBrazilianDateTimeAdded
              }}
              -
              {{ agendamento.data_janela.data_fim | toBrazilianDateTimeAdded }}
            </strong>
          </p>
        </div>
        <div v-if="agendamento.data_pedido">
          <p>Data da pedido para Carga/Descarga:</p>
          <p>
            <strong>{{ agendamento.data_pedido | date2br }}</strong>
          </p>
        </div>
        <p>Data/Hora de chegada na Triagem:</p>
        <p>
          <strong>{{
            agendamento.triado_em || new Date() | toBrazilianDateTime
          }}</strong>
        </p>
        <div v-if="agendamento.cancelado_em">
          <p>Data/Hora do Cancelamento:</p>
          <p>
            <strong>{{
              agendamento.cancelado_em | toBrazilianDateTime
            }}</strong>
          </p>
          <p>
            Motivo do Cancelamento:
            <strong>{{ agendamento.motivo }}</strong>
          </p>
          <p>
            Cancelado por:
            <strong>{{ agendamento.cancelado_por }}</strong>
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row class="row-senhas">
      <v-col class="col-comprovante my-3" cols="12">
        <p><strong> Senha </strong></p>
        <p style="font-size: 30px">
          {{ getSenha ? getSenha.sequencia : '---' }}
        </p>
      </v-col>
    </v-row>
    <v-row class="row-senhas">
      <v-col class="col-comprovante">
        <p><strong> Setor </strong></p>
        <p style="font-size: 30px">
          {{
            agendamento.vaga != null
              ? agendamento.vaga.setor.descricao.toUpperCase()
              : '-'
          }}
        </p>
      </v-col>
    </v-row>
    <v-row
      class="row-senhas"
      v-if="
        agendamento.gate.public_id == '79dd342d-c45e-49b8-8daa-d65e47ecd562'
      "
    >
      <v-col class="col-comprovante">
        <p><strong> Vaga </strong></p>
        <p style="font-size: 30px">
          {{
            agendamento.vaga != null
              ? agendamento.vaga.descricao.toUpperCase()
              : '-'
          }}
        </p>
      </v-col>
    </v-row>
    <v-row class="row-cliente">
      <v-col class="col-comprovante">
        <div
          style="margin: 30px"
          v-for="(pedido, index) in agendamento.pedidos_agendados"
          :key="index"
        >
          <p>
            SubProduto:
            <strong>{{ pedido.pedido.subProduto.descricao }}</strong>
          </p>
          <p v-if="mostraItem">
            Armazem:
            <strong>{{
              pedido.armazem != null
                ? pedido.armazem.descricao.toUpperCase()
                : '-'
            }}</strong>
          </p>
          <p v-if="mostraItem">
            Quantidade:
            <strong>{{ converteKg(pedido.quantidade_agendada) }} KG</strong>
          </p>
          <p v-if="mostraItem">
            N° NFE: <strong>{{ pedido.numero_nfe }}</strong>
          </p>
          <p v-if="mostraItem">
            Embalagem: <strong>{{ pedido.pedido.embalagem.descricao }}</strong>
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-comprovante">
        <div
          style="margin: 30px"
          v-for="(servico, index) in agendamento.servicos_triados"
          :key="index"
        >
          <p>
            Servico: <strong>{{ servico.servico.descricao }}</strong>
          </p>
          <p
            v-if="
              agendamento.gate.public_id ==
              '50566915-61a2-4b1c-8c7f-35f605aff94f'
            "
          >
            Placa:
            <spam class="placa">{{ agendamento.veiculo.placa_cavalo }}</spam>
          </p>
          <p
            v-if="
              agendamento.gate.public_id ==
              '50566915-61a2-4b1c-8c7f-35f605aff94f'
            "
          >
            Data:
            <strong
              ><strong>{{
                agendamento.triado_em || new Date() | toBrazilianDateTime
              }}</strong></strong
            >
          </p>
          <p
            v-if="
              agendamento.gate.public_id !==
              '21fcdd4f-38ec-4901-96a0-915616c84ed7'
            "
          >
            Forma Pagamento:
            <strong>{{ servico.forma_pagamento.descricao }}</strong>
          </p>
          <p
            v-if="
              agendamento.gate.public_id !==
              '21fcdd4f-38ec-4901-96a0-915616c84ed7'
            "
          >
            Valor: <strong>{{ 'R$ ' + getItemValor(servico) }}</strong>
          </p>
          <div
            v-if="
              agendamento.gate.public_id ===
              '21fcdd4f-38ec-4901-96a0-915616c84ed7'
            "
          >
            <div
              style="margin: 30px"
              v-for="(movimentacao, index) in servico.movimentacao"
              :key="index"
            >
              <p>
                Forma Pagamento:
                <strong>{{ movimentacao.forma_pagamento.descricao }}</strong>
              </p>
              <p>
                Valor:
                <strong>{{ movimentacao.valor }}</strong>
              </p>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="this.exibeClassificacao === true">
      <v-col class="col-comprovante">
        <div
          style="margin: 30px"
          v-for="(classificacao, index) in classificacoes"
          :key="index"
        >
          <p>
            Caracteristica: <strong>{{ classificacao.caracteristica }}</strong>
          </p>
          <p>
            Valor: <strong>{{ classificacao.valor }}</strong>
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="
        agendamento.gate.public_id == '79dd342d-c45e-49b8-8daa-d65e47ecd562'
      "
    >
      <v-col cols="12">
        <p>
          <strong>{{ agendamento.observacao }}</strong>
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-end">
        <span class="text-caption">{{ agendamento.public_id }}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { toReal, date2br } from '@/mixins/convertion.js'
import {
  cota_dia_todo,
  cota_mesmo_dia,
  mostra_cota_mesmo_dia,
} from '@/mixins/cotas.js'
import { data } from './_data'
import { computed } from './_computed'
import { filters } from './_filters'
import { methods } from './_methods'
import classificacaoAPI from '@/services/classificacao'

export default {
  name: 'Comprovante',
  inject: ['fecharComprovanteTriagem'],
  mixins: [
    toReal,
    date2br,
    cota_dia_todo,
    cota_mesmo_dia,
    mostra_cota_mesmo_dia,
  ],
  data,
  computed,
  filters,
  async created() {
    try {
      const data = await classificacaoAPI.listClassificacoes(
        this.empresaAtual.public_id,
        {
          agendamento: this.agendamento.public_id,
        }
      )
      if (
        data.results[0] !== null &&
        data.results[0] !== '' &&
        data.results[0] !== undefined
      ) {
        this.classificacoes = data.results[0].classificacoes
      }
      this.exibeClassificacao = this.agendamento.fornecedor.config.agendamento.exibirClassificacao
    } catch (error) {
      console.log(error)
    }
  },
  methods,
}
</script>

<style>
.container-comprovante p {
  text-transform: uppercase;
  line-height: 5px;
}
.placa {
  font-size: 20px;
}
.col-comprovante {
  padding: 1px;
}

.row-senhas strong {
  font-size: 20px;
  line-height: 20px;
}
strong {
  font-size: 15px;
  line-height: 15px;
}

p {
  font-size: 15px;
  line-height: 15px;
}
/* configuração para impressora térmica */
@media print {
  .v-application--wrap,
  .v-dialog button {
    display: none;
  }

  .v-dialog {
    display: block;
    height: 100vh;
    width: 100vw;
    margin: 0;
  }
  .v-dialog--fullscreen {
    position: absolute;
    overflow: visible;
  }
  .v-card {
    height: 100%;
    width: 100%;
  }

  .v-data-table th,
  .v-data-table td {
    font-size: 10px !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .aviso {
    font-size: 10px;
  }

  @page {
    margin: 0;
  }
}
</style>
