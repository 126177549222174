<template>
  <v-row>
    <v-col class="12">
      <v-card class="mb-2">
        <v-card-text class="pa-2 border-bottom">
          <h3
            class="subtitle blue-grey--text text--darken-2 font-weight-regular"
          >
            Dados do Veículo
          </h3>
        </v-card-text>
        <v-card-text class="pa-5 border-bottom">
          <v-form id="formDadosVeiculo">
            <v-row align="center">
              <v-col class="pt-0" cols="12" sm="12" lg="2" md="2">
                <validation-provider
                  v-slot="{ errors }"
                  name="Placa do Cavalo"
                  rules="required"
                >
                  <v-text-field
                    label="Placa Cavalo"
                    v-mask="['AAA-XXX', 'AAA-XXXX']"
                    ref="placa_cavalo"
                    v-model="agendamento.veiculo.placa_cavalo"
                    :error-messages="errors"
                    @change="atualizaVeiculo()"
                  />
                </validation-provider>
              </v-col>
              <v-col class="pt-0" cols="12" sm="6" lg="2" md="2">
                <v-row align="center">
                  <v-col class="pt-0" cols="10" sm="10" lg="10" md="10">
                    <v-text-field
                      label="Placa Carreta 01"
                      v-mask="['AAA-XXXX']"
                      v-model="agendamento.conjunto.carreta_1"
                    />
                  </v-col>
                  <v-col class="pt-0" cols="2" sm="2" lg="2" md="2">
                    <v-icon
                      small
                      @click="
                        editarImplemento(
                          agendamento.conjunto.carreta_1_public_id
                        )
                      "
                      >mdi-pencil</v-icon
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0" cols="12" sm="6" lg="2" md="2">
                <v-row align="center">
                  <v-col class="pt-0" cols="10" sm="10" lg="10" md="10">
                    <v-text-field
                      label="Placa Carreta 02"
                      v-mask="['AAA-XXXX']"
                      v-model="agendamento.conjunto.carreta_2"
                    />
                  </v-col>
                  <v-col class="pt-0" cols="2" sm="2" lg="2" md="2">
                    <v-icon
                      small
                      @click="
                        editarImplemento(
                          agendamento.conjunto.carreta_2_public_id
                        )
                      "
                      >mdi-pencil</v-icon
                    >
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="pt-0" cols="12" sm="12" lg="2" md="2">
                <v-select
                  label="Modalidade"
                  item-text="descricao"
                  item-value="id"
                  :items="modalidades"
                  v-model="agendamento.veiculo.modalidade.id"
                  @change="modalidadeSelecionada(modalidades)"
                />
              </v-col>
              <v-col class="pt-0" cols="12" sm="12" lg="1" md="1">
                <v-text-field
                  label="Eixos"
                  v-model="agendamento.veiculo.modalidade.eixo"
                />
              </v-col>
              <v-col class="pt-0" cols="12" sm="12" lg="2" md="2">
                <v-select
                  label="Carroceria"
                  item-text="descricao"
                  item-value="id"
                  :items="carrocerias"
                  v-model="agendamento.veiculo.carroceria"
                />
              </v-col>
              <v-col class="pt-0" cols="12" sm="12" lg="1" md="1">
                <modal-veiculo
                  :veiculo="agendamento.veiculo"
                  @addedVeiculo="addedVeiculo"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="dialogUpdateItem" max-width="700px">
      <v-card>
        <v-card-title>
          <div class="d-flex flex-column align-start">
            <span class="text-h5">Editar {{ implementoUpdate.placa }}</span>
            <span class="text-caption">{{ implementoUpdate.public_id }}</span>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogUpdateItem = false">
            Fechar
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <EditarImplemento
              @addedImplemento="addedImplemento"
              v-model="implementoUpdate"
              @close="dialogUpdateItem = false"
              v-if="dialogUpdateItem"
            />
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ModalVeiculo from '@/components/patio/veiculos/Veiculo/ModalVeiculo'
import PatioAPI from '@/services/patio'
import EditarImplemento from '@/components/patio/veiculos/Implemento/EditarImplemento.vue'

export default {
  components: { ModalVeiculo, EditarImplemento },
  computed: {
    ...mapState('agendamentos', ['agendamento']),
    ...mapState('patio', ['modalidades', 'carrocerias']),
    ...mapState('auth', ['empresaAtual']),
  },
  data() {
    return {
      dialogUpdateItem: false,
      implementoUpdate: {},
    }
  },
  methods: {
    ...mapActions('implemento', ['retrieveImplemento']),
    ...mapActions('patio', ['getModalidades']),
    ...mapActions('agendamentos', ['buscaVeiculo']),
    ...mapActions('template', ['errorMessage']),
    async addedImplemento() {
      this.dialogUpdateItem = false
      this.implementoUpdate = {}
      await this.getModalidades()
    },
    async editarImplemento(item) {
      const implementoEdit = {}
      implementoEdit['public_id'] = item
      const implemento = await this.retrieveImplemento({
        implemento: implementoEdit,
        empresa: this.agendamento.veiculo
          ? this.agendamento.veiculo.proprietario
          : undefined,
      })
      this.dialogUpdateItem = true
      this.implementoUpdate = implemento
    },
    async addedVeiculo(veiculo) {
      if (veiculo) {
        this.agendamento.veiculo = veiculo
        this.agendamento.conjunto = veiculo.conjunto_atual
        await this.atualizaVeiculo(veiculo)
      }
    },
    async verificarVeiculo() {
      try {
        await PatioAPI.verificarAgendamentoEmAbertoVeiculo(this.agendamento)
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessage(error.response ? error.response.data : error)
          this.agendamento.veiculo.placa_cavalo = ''
          this.agendamento.veiculo.carroceria = ''
          this.agendamento.veiculo.modalidade.eixo = ''
          this.agendamento.veiculo.placa_carreta_1 = ''
          this.agendamento.veiculo.placa_carreta_2 = ''
          this.agendamento.veiculo.modalidade = null
        }
      }
    },

    async verificarBlackList() {
      try {
        await PatioAPI.verificarBlackList(this.agendamento)
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessage(error.response ? error.response.data : error)
          this.agendamento.veiculo.placa_cavalo = ''
          this.agendamento.veiculo.carroceria = ''
          this.agendamento.veiculo.modalidade.eixo = ''
          this.agendamento.veiculo.placa_carreta_1 = ''
          this.agendamento.veiculo.placa_carreta_2 = ''
        }
      }
    },

    async atualizaVeiculo(veiculoParam) {
      if (this.agendamento.tipo_operacao == null) {
        this.agendamento.veiculo.placa_cavalo = ''
        this.errorMessage('Favor preencher o tipo de operação!')
      } else {
        let veiculo = ''
        if (
          veiculoParam !== null &&
          veiculoParam !== '' &&
          veiculoParam !== undefined
        ) {
          veiculo = await this.buscaVeiculo({
            id: this.agendamento.veiculo.id,
          })
        } else {
          if (this.agendamento.veiculo.placa_cavalo != '') {
            veiculo = await this.buscaVeiculo({
              placa_cavalo: this.agendamento.veiculo.placa_cavalo,
              owner: this.empresaAtual.public_id,
              cadastroCompleto: this.agendamento?.fornecedor?.config
                ?.agendamento?.cadastroCompleto,
            })
          }
        }
        if (veiculo.length == '0') {
          this.agendamento.veiculo.placa_cavalo = ''
          this.errorMessage(
            'Veiculo sem cadastro, favor cadastrar o veiculo no Menu: (Administrativo > Veículos).'
          )
        } else {
          Object.assign(this.agendamento.conjunto, veiculo.conjunto_atual)
          this.verificarBlackList()
          this.verificarVeiculo()
        }
      }
    },
  },
}
</script>

<style></style>
