<template>
  <div>
    <v-row justify="space-between" class="pb-4">
      <v-col cols="2">
        <validation-provider name="documentos" v-slot="{ errors }">
          <div style="display: inline; white-space: nowrap">
            <v-btn color="primary" dark @click="abrirInsercaoArquivo">
              Adicionar documento
            </v-btn>
            <input
              :accept="uploadFileType"
              type="file"
              id="file"
              style="display: none"
              ref="file"
              @change="handleUpload"
              :error-messages="errors"
            />
            <span class="ml-5">
              {{
                value.documentos
                  ? value.documentos.length > 0
                    ? value.documentos[0].name
                    : ''
                  : ''
              }}
            </span>
          </div>
        </validation-provider>
      </v-col>
      <v-col cols="2" class="text-end">
        <v-btn
          :disabled="
            !valid ||
            (exigePedido && !value.numero_pedido) ||
            (obrigaXML && value.documentos.length == 0)
          "
          @click="salvar"
          color="primary"
          >{{ buttonText }}</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col class="pt-0" cols="12">
        <div class="previous">
          <v-row>
            <v-col
              v-if="agendamento.tipo_documento == 'NF'"
              class="pt-0"
              cols="12"
              sm="12"
              lg="3"
              md="3"
            >
              <v-text-field
                label="Chave Acesso"
                v-model="value.chave_acesso"
                @change="getInfoChaveNfe"
                :readonly="readonlyItens"
              />
            </v-col>
            <v-col
              v-if="agendamento.tipo_documento == 'NF'"
              class="pt-0"
              cols="12"
              sm="12"
              lg="2"
              md="2"
            >
              <v-text-field label="N° NFE" :readonly="readonlyItens" v-model="value.numero_nfe" />
            </v-col>
            <v-col
              v-if="agendamento.tipo_documento == 'NF'"
              class="pt-0"
              cols="12"
              sm="12"
              lg="2"
              md="2"
            >
              <v-text-field label="Serie NFE" :readonly="readonlyItens" v-model="value.numero_serie" />
            </v-col>

            <v-col
              v-if="agendamento.tipo_documento == 'NF'"
              class="pt-0"
              cols="12"
              sm="12"
              lg="2"
              md="2"
            >
              <v-text-field
                label="Data emissão"
                v-model="value.dataEmissaoNf"
                type="date"
                :readonly="readonlyItens"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              v-if="exigePedido"
              class="pt-0"
              cols="12"
              sm="12"
              lg="2"
              md="2"
            >
              <v-text-field
                label="N° Pedido"
                v-model="value.numero_pedido"
                @change="buscaPedidoExistente"
              />
            </v-col>
            <v-col class="pt-0" cols="12" sm="12" lg="2" md="2">
              <v-autocomplete
                id="armazem"
                label="Armazem"
                return-object
                item-text="descricao"
                item-value="public_id"
                :items="armazens"
                v-model="value.armazem"
                @change="setProdutos"
              />
            </v-col>
            <v-col class="pt-0" cols="12" sm="12" lg="2" md="2">
              <v-autocomplete
                id="produto"
                label="Produto"
                return-object
                item-text="descricao"
                item-value="id"
                :items="agendamento.produtos"
                v-model="value.produto"
              />
            </v-col>
            <v-col class="pt-0" cols="12" sm="12" lg="2" md="2">
              <v-autocomplete
                label="Sub-Produto"
                return-object
                item-text="descricao"
                item-value="id"
                :items="agendamento.subProdutos"
                v-model="value.subProduto"
              />
            </v-col>
            <v-col class="pt-0" cols="12" sm="4" lg="2" md="2">
              <validation-provider
                v-slot="{ errors }"
                name="quantidade (KG)"
                :rules="{
                  min_value: 0.1,
                  max_value: value.quantidade_restante * 1000 || 50000,
                }"
              >
                <v-text-field
                  type="string"
                  label="Quantidade (KG)"
                  v-mask="['#', '##', '###', '#.###', '##.###']"
                  v-model="value.quantidade"
                  :error-messages="errors"
                  :readonly="readonlyItens"
                />
              </validation-provider>
            </v-col>
            <v-col class="pt-0" cols="12" sm="8" lg="2" md="2">
              <v-autocomplete
                label="Embalagem"
                item-text="descricao"
                item-value="id"
                return-object
                :items="agendamento.embalagens"
                v-model="value.embalagem"
              />
            </v-col>
          </v-row>
        </div>
      </v-col>
      <confirm-dialog @confirm="confirm" />
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapActions } from 'vuex'

import patioApi from '@/services/patio'
import FileApi from '@/services/files/index'
import ConfirmDialog from '@/components/template/ConfirmDialog'

const pesoMask = (value) => {
  return ('00000' + value).slice(-4)
}

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    exigePedido: Boolean,
    obrigaXML: Boolean,
  },

  components: { ConfirmDialog },

  data() {
    return {
      numero_nfe_isvalid: true,
      pesoMask,
    }
  },

  computed: {
    ...mapState('agendamentos', ['agendamento']),
    ...mapState('contratos', ['armazens']),
    ...mapState('clientes', ['clientes']),
    ...mapState('auth', ['empresaAtual']),
    buttonText() {
      return this.edit ? 'Salvar' : 'Adicionar'
    },

    valid() {
      return !!(
        (this.value.quantidade ? this.value.quantidade.toString() : 0) &&
        this.value.quantidade >= 0.1 &&
        !_.isEmpty(this.value.embalagem) &&
        !_.isEmpty(this.value.produto) &&
        !_.isEmpty(this.value.subProduto)
      )
    },
    uploadFileType() {
      return this.agendamento.tipo_documento === 'NF' ? 'text/xml' : '.pdf'
    },

    readonlyItens() {
      return this.value.documentos.length > 0
    },
  },

  methods: {
    ...mapActions('template', [
      'errorMessage',
      'successMessage',
      'setDialogMessage',
    ]),
    ...mapActions('clientes', ['getClientesByParams']),
    ...mapActions('agendamentos', ['reset', 'updateAtributoAgendamento']),
    
    async handleUpload(e) {
      const file = e.target.files[0]
      if (this.obrigaXML && file.type != 'text/xml') {
        this.errorMessage('É necessário um arquivo XML')
        return
      }
      file.description = file.name
      file.necessity_send = true
      this.value.documentos.pop()
      this.value.documentos.push(file)
      // const get_ext = (filename) => {
      //   try {
      //     const parts = filename.split('.')
      //     return parts[parts.length - 1]
      //   } catch {
      //     return ''
      //   }
      // }
      // const ext = get_ext(file.name)
      if (file.type == 'text/xml') {
        await this.handleFileXmlUpload(file)
      }
    },
    async handleFileXmlUpload(file) {
      let formData = new FormData()
      formData.append('file', file)

      let data = await FileApi.convertXmlToJson(formData)
      // console.log(data)
      let dadosNotaFiscal = JSON.parse(data)
      // dadosNotaFiscal.file = file
      this.getDadosNotaFiscal(dadosNotaFiscal)
    },
    getDadosNotaFiscal(notaFiscal) {
      const getProd = (det, key) => {
        try {
          if (Array.isArray(det)) {
            if (det.length > 0) {
              return det[0].prod[key]
            }
          } else {
            return det.prod[key]
          }
        } catch (error) {
          console.log('error!')
        }
      }
      let pedido = {
        numero_documento: notaFiscal.nfeProc.NFe.infNFe.ide.nNF,
        numero_serie: notaFiscal.nfeProc.NFe.infNFe.ide.serie,
        chave_acesso: notaFiscal.nfeProc.protNFe.infProt.chNFe,
        data_emissao: notaFiscal.nfeProc.NFe.infNFe.ide.dhEmi,
        cfop: getProd(notaFiscal.nfeProc.NFe.infNFe.det, 'CFOP'),
        ncm: getProd(notaFiscal.nfeProc.NFe.infNFe.det, 'NCM'),
        pedido: getProd(notaFiscal.nfeProc.NFe.infNFe.det, 'qCom'),
        unidade_medida: getProd(notaFiscal.nfeProc.NFe.infNFe.det, 'uCom'),
      }
      this.$emit('setDadosXml', pedido)
    },
    async abrirInsercaoArquivo() {
      await this.$refs['file'].click()
    },
    setProdutos(armazem) {
      const subProdutos = armazem.sub_produtos
      const foo = subProdutos.map((s) => ({
        ...s.produto,
        sub_produto: {
          id: s.id,
          descricao: s.descricao,
        },
      }))
      const bar = foo.reduce((acc, i) => {
        if (acc[i.id] === undefined) {
          acc[i.id] = { ...i, subprodutos: [i.sub_produto] }
        } else {
          acc[i.id].subprodutos.push(i.sub_produto)
        }
        return acc
      }, {})
      this.agendamento.produtos = Object.values(bar)
    },

    confirm() {
      this.value.criar = true
    },

    getInfoChaveNfe() {
      this.value.chave_acesso = this.value.chave_acesso
        .replaceAll('.', '')
        .replaceAll(' ', '')
      this.value.numero_serie = this.value.chave_acesso.substr(22, 3)
      this.value.numero_nfe = this.value.chave_acesso.substr(28, 6)
      this.getClientesByCnpj(this.value.chave_acesso.substr(6, 14))
      this.getInfoNumeroNfe()
    },

    async getInfoNumeroNfe() {
      try {
        await patioApi.verificarNumeroNfe(
          this.value.chave_acesso,
          this.value.public_id
        )

        this.numero_nfe_isvalid = true
      } catch (error) {
        this.numero_nfe_isvalid = false
        this.value.numero_serie = ''
        this.value.numero_nfe = ''
        this.value.chave_acesso = ''
        this.value.numero_nfe = ''
        if (error.response) {
          this.errorMessage(error.response.data)
          return
        }
        if (error.message) {
          this.errorMessage(error.message)
          return
        }
        this.errorMessage(error)
      }
    },

    async getClientesByCnpj(cnpj) {
      await this.getClientesByParams({
        public_id: this.empresaAtual.public_id,
        params: {
          cnpj: cnpj,
        },
      })
      this.updateAtributoAgendamento({
        valor: this.clientes[0],
        nomeAtributo: 'remetente',
      })
    },

    async buscaPedidoExistente() {
      const { cliente, fornecedor, gate } = this.agendamento

      if (_.isEmpty(cliente) || _.isEmpty(fornecedor) || _.isEmpty(gate)) {
        this.errorMessage(
          'Necessário preencher os campos: cliente, fornecedor, gate'
        )
        this.value.numero_pedido = ''
        return
      }

      const pedido = await patioApi.getPedidos({
        cliente: cliente.cliente_id,
        fornecedor: fornecedor.fornecedor_id,
        gate: gate.public_id,
        numero_pedido: this.value.numero_pedido,
      })

      if (pedido.length > 0) {
        this.temPedido = true

        const pedidoAtual = pedido[0]

        this.value.public_id = pedidoAtual.public_id
        this.value.produto = this.agendamento.produtos.find(
          (p) => p.id === pedidoAtual.produto.id
        )
        this.value.embalagem = this.agendamento.embalagens.find(
          (e) => e.id === pedidoAtual.embalagem.id
        )
        this.value.quantidade_restante = pedidoAtual.quantidade_restante

        setTimeout(
          () =>
            (this.value.subProduto = this.agendamento.subProdutos.find(
              (p) => p.id === pedidoAtual.subProduto.id
            )),
          50
        )
      } else {
        this.successMessage(
          'Pedido não encontrado. Será criado automaticamente.'
        )
        this.value.criar = true
      }
    },
    salvar() {
      this.$emit('salvarPedido', this.value)
    },
  },
}
</script>

<style></style>
