<template>
  <v-container fluid>
    <form-adicionar-empresa-relacao
      @adicionar="adicionarEmpresaRelacao"
      campoEmpresaOrigem="empresa"
      campoEmpresaDestino="cliente"
    />
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormAdicionarEmpresaRelacao from '@/components/empresaRelacao/adicionar/FormAdicionarEmpresaRelacao.vue'
import { BasicPermissions, Sequences } from '@/utils/permissions'

export default {
  props: {
    empresa: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  data() {
    return {}
  },
  components: {
    FormAdicionarEmpresaRelacao,
  },
  computed: {
    permission() {
      return Sequences.Clientes.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {},
  methods: {
    ...mapActions('clientes', ['adicionarClienteEmpresa']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async addedCliente(cliente) {
      this.$emit('addedCliente', cliente)
    },
    async adicionarEmpresaRelacao(empresa) {
      try {
        console.log(this.empresa)
        const cliente = await this.adicionarClienteEmpresa({
          cliente: empresa,
          empresa: this.empresa,
        })
        this.addedCliente(cliente)
      } catch (error) {
        console.log(error)
        window.error = error
        if (error.response) {
          this.errorMessage(error.response.data)
        } else {
          this.errorMessage(error)
        }
      }
    },
  },
}
</script>

<style></style>
