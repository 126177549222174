var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"12"},[_c('v-card',{staticClass:"mb-2"},[_c('v-card-text',{staticClass:"pa-2 border-bottom"},[_c('h3',{staticClass:"blue-grey--text text--darken-2 font-weight-regular"},[_vm._v(" Informações do Documento ")])]),_c('v-card-text',{staticClass:"pa-5 border-bottom"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{attrs:{"id":"formDadosDocumento"}},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","lg":"4","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":"Tipo de Documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Tipo de Documento","required":"","item-text":"label","item-value":"value","items":_vm.tipoDocumento,"value":_vm.agendamento.tipo_documento,"error-messages":errors},on:{"input":function($event){return _vm.updateAtributoAgendamento({
                        valor: $event,
                        nomeAtributo: 'tipo_documento',
                      })}}})]}}])})],1),(_vm.agendamento && _vm.agendamento.tipo_documento === 'NF')?_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","lg":"2","sm":"4","md":"4"}},[_c('validation-provider',{attrs:{"name":_vm.labelFileUpload},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.abrirInsercaoArquivo}},[_vm._v(_vm._s(_vm.textUpload))]),_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"accept":_vm.uploadFileType,"type":"file","id":"file","label":_vm.labelFileUpload,"error-messages":errors},on:{"change":_vm.handleUpload}})]}}],null,false,2493332753)})],1):_vm._e()],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }