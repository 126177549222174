<template>
  <div>
    <v-card-text class="pa-5 border-bottom">
      <validation-observer ref="observer">
        <v-row class="justify-center d-flex py-10">
          <v-data-table
            :headers="headers"
            :items="rfidsList"
            sort-by="descricao"
            class="border"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>RFIDs adicionados</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="700px" persistent>
                  <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark class="mb-2" v-on="on"
                      >Novo RFID</v-btn
                    >
                  </template>
                  <r-f-i-d
                    ref="modal_rfid"
                    :rfid="rfid"
                    @adicionarRFID="adicionarRFID"
                    @close="closeRFID"
                  />
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon
                small
                class="mx-2"
                @click="verRFID(item)"
                v-if="item.public_id"
                >mdi-eye</v-icon
              >
              <v-icon small @click="excluirRFID(item)">mdi-delete</v-icon>
            </template>
            <template v-slot:no-data>
              <span>Sem RFID adicionados</span>
            </template>
          </v-data-table>
        </v-row>
      </validation-observer>
    </v-card-text>
    <v-card-actions v-if="hasButtonSave">
      <v-spacer></v-spacer>
      <buttons-cadastro @close="$emit('close')" @save="save" />
    </v-card-actions>
  </div>
</template>

<style lang="scss">
.button-submit-green {
  background-color: #00aa9e !important;
  color: white !important;
}
</style>

<script>
import { mapActions } from 'vuex'
import RFID from './RFID.vue'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'

export default {
  name: 'EtapaRFID',
  props: {
    value: Array,
    veiculo: {
      type: Object,
      required: true,
    },
    hasButtonSave: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { ButtonsCadastro, RFID },
  data() {
    return {
      dialog: false,
      rfid: null,
      rfids: [],
      rfidsList: this.value,
      headers: [
        {
          text: 'rfid',
          align: 'start',
          value: 'rfid',
        },
        {
          text: 'Descrição',
          align: 'start',
          value: 'descricao',
        },
        {
          text: 'Empresa',
          align: 'start',
          value: 'empresa.business_name',
        },
        { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
      ],
    }
  },
  watch: {
    value(val) {
      Object.assign(this.rfidsList, val)
      this.$forceUpdate()
    },
    rfidsList(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    ...mapActions('patio', ['addRFID', 'editRFID', 'deleteRFID']),
    closeRFID() {
      this.dialog = false
      this.rfid = null
    },
    close() {
      this.$emit('close')
    },
    verRFID(item) {
      this.dialog = true
      this.$nextTick(() => {
        console.log(this.$refs)
        this.$refs.modal_rfid.editItem(item)
      })
    },
    adicionarRFID(item) {
      const empresas = item.empresas
      delete item.empresas
      if (Array.isArray(empresas)) {
        empresas.forEach((element) => {
          if (!item.public_id) {
            this.rfidsList.push({ ...item, empresa: element })
          } else {
            const i = this.rfidsList.findIndex(
              (rfid) => rfid.public_id == item.public_id
            )
            Object.assign(this.rfidsList[i], { ...item, empresa: element })
          }
        })
      } else {
        if (!item.public_id) {
          this.rfidsList.push({ ...item, empresa: empresas })
        } else {
          const i = this.rfidsList.findIndex(
            (rfid) => rfid.public_id == item.public_id
          )
          Object.assign(this.rfidsList[i], { ...item, empresa: empresas })
        }
      }
    },
    async save(veiculo = null) {
      if (!veiculo) {
        veiculo = this.veiculo
      }
      const l = []
      const errors = {}
      await Promise.all(
        this.rfidsList.map(async (rfid) => {
          try {
            let data
            if (rfid.public_id) {
              data = await this.editRFID({
                veiculo,
                rfid: { ...rfid, empresa: rfid.empresa?.public_id },
              })
            } else {
              data = await this.addRFID({
                veiculo,
                rfid: { ...rfid, empresa: rfid.empresa?.public_id },
              })
            }
            if (data) {
              l.push(data)
            }
          } catch (error) {
            rfid.error = error
            l.push(rfid)
            if (error.response) {
              errors[rfid.rfid] = error.response.data
              return
            }
            if (error.message) {
              errors[rfid.rfid] = error.message
              return
            }
            errors[rfid.rfid] = error
          }
        })
      )
      if (Object.entries(errors).length) {
        this.errorMessage(errors)
      } else {
        this.successMessage('RFIDs salvos!')
      }
      this.rfidsList = l
      console.log(this.rfidsList)
      return this.rfidsList
    },
    async excluirRFID(rfid) {
      const i = this.rfidsList.findIndex((item) => item == rfid)
      if (i >= 0) {
        if (rfid.public_id) {
          await this.deleteRFID({ veiculo: this.veiculo, rfid })
        }
        this.rfidsList.splice(i, 1)
      }
    },
  },
}
</script>
