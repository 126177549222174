<template>
  <div class="text-center">
    <form-wizard
      @on-complete="submitAdicionar"
      class="cadastroEmpresaRelação"
      :class="{ hasError }"
      color="#00aa9e"
      :title="null"
      :subtitle="null"
    >
      <tab-content
        title="CPF/CNPJ da empresa"
        icon="ti-user"
        :before-change="validarCPFCNPJInformado"
      >
        <etapa-cnpj
          ref="etapaCnpj"
          v-model="cpfOuCNPJ"
          :cnpjProp="cpfOuCNPJ"
        ></etapa-cnpj>
      </tab-content>

      <tab-content
        title="Informações Gerais"
        icon="ti-settings"
        :before-change="validarEmpresa"
      >
        <etapa-dados-empresa
          v-model="empresa"
          :novaEmpresa="!empresaJaExistente"
          :disabled="empresaJaExistente"
          :estados="estados"
          ref="EtapaDadosEmpresa"
        ></etapa-dados-empresa>
      </tab-content>
      <tab-content title="Informações do contrato" icon="ti-check">
        <etapa-dados-contrato
          v-model="empresa"
          @atualizar-contatos="atualizarContatos"
          ref="etapaDadosContrato"
        ></etapa-dados-contrato>
      </tab-content>
      <v-btn color="black" dark slot="prev">
        <i class="mdi mdi-chevron-left mr-1"></i>
        Anterior
      </v-btn>
      <v-btn color="info" slot="next">
        Próximo
        <i class="mdi mdi-chevron-right ml-1"></i>
      </v-btn>
      <v-btn color="success" slot="finish">
        Finalizar
        <i class="mdi mdi-bookmark-check ml-1"></i>
      </v-btn>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { cpf, cnpj } from 'cpf-cnpj-validator'

import EtapaCnpj from '@/components/cadastros/geral/EtapaCnpj'
import EtapaDadosEmpresa from '@/components/cadastros/geral/EtapaDadosEmpresa'
import EtapaDadosContrato from './EtapaDadosContrato'

import UtilsApi from '@/services/utils/utils.api'
import TenantsApi from '@/services/tenants'

import { mapState, mapActions } from 'vuex'
import { jsonp } from 'vue-jsonp'

export default {
  props: {
    campoEmpresaOrigem: {
      type: String,
      required: true,
    },
    campoEmpresaDestino: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      hasError: false,
      tab: null,
      empresa: {},
      estados: [],
      cpfOuCNPJ: '',
      empresaJaExistente: false,
      novaEmpresa: {
        contatos: {},
      },
    }
  },

  components: {
    FormWizard,
    TabContent,
    EtapaCnpj,
    EtapaDadosEmpresa,
    EtapaDadosContrato,
  },

  created() {
    this.getEstados()
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
  },

  methods: {
    ...mapActions('tenants', ['addEmpresa']),
    ...mapActions('template', ['successMessage', 'errorMessage']),

    async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },

    validarCPFCNPJInformado() {
      const refToValidate = this.$refs['etapaCnpj']
      const isValid = refToValidate.validate()

      this.hasError = !isValid

      this.verificarCPFCNPJExistente()

      return isValid
    },

    async validarEmpresa() {
      if (this.empresaJaExistente) {
        this.novaEmpresa[this.campoEmpresaOrigem] = this.empresaAtual.public_id
        this.novaEmpresa[this.campoEmpresaDestino] = this.empresa.public_id

        this.hasError = false

        return true
      } else {
        const refToValidate = this.$refs['EtapaDadosEmpresa']
        const isValid = refToValidate.validate()

        this.hasError = !isValid

        return isValid
      }
    },

    async submitAdicionar() {
      try {
        const campoDigitado = this.empresa.cpf ? 'cpf' : 'cnpj'
        const campoStriped =
          campoDigitado === 'cpf'
            ? cpf.strip(this.empresa.cpf)
            : cnpj.strip(this.empresa.cnpj)

        let empresa = {
          ...this.empresa,
          tenant_criador: this.empresaAtual.public_id,
          [campoDigitado]: campoStriped,
        }
        if (!this.empresaJaExistente) {
          empresa = await this.addEmpresa(empresa)
        }

        this.novaEmpresa[this.campoEmpresaOrigem] = this.empresaAtual.public_id
        this.novaEmpresa[this.campoEmpresaDestino] = empresa.public_id

        this.hasError = false

        this.$emit('adicionar', this.novaEmpresa)

        // this.successMessage('Empresa adicionada com sucesso.')
        this.$emit('addedEmpresa')
      } catch (error) {
        this.hasError = true
        if (error.response) {
          this.errorMessage(error.response.data)
        } else {
          this.errorMessage(error)
        }
      }
    },

    reset() {
      this.$emit('reset')
    },

    atualizarContatos(contatos) {
      Object.assign(this.novaEmpresa.contatos, contatos)
    },

    async verificarCPFCNPJExistente() {
      const foiDigitadoUmCNPJ = cnpj.isValid(this.cpfOuCNPJ)

      if (foiDigitadoUmCNPJ) {
        try {
          const cnpjCliente = cnpj.strip(this.cpfOuCNPJ)
          const empresaExistentes = await TenantsApi.getTenantByCPFOrCNPJ({
            cnpj: cnpjCliente,
          })

          if (empresaExistentes.length > 0) {
            this.empresaJaExistente = true
            this.empresa = { ...empresaExistentes[0] }
          } else {
            this.empresaJaExistente = false
            this.empresa = { cnpj: cnpjCliente }
            const dadosEmpresaReceita = await this.fetchDadosEmpresaReceita()
            this.syncDadosClienteComDadosReceita(dadosEmpresaReceita)
          }
        } catch (e) {
          if (e.response) {
            this.errorMessage(e.response.data)
          } else {
            this.errorMessage(e)
          }
        }
      } else {
        try {
          const cpfCliente = cpf.strip(this.cpfOuCNPJ)
          const empresaExistentes = await TenantsApi.getTenantByCPFOrCNPJ({
            cpf: cpfCliente,
          })

          if (empresaExistentes.length > 0) {
            this.empresaJaExistente = true
            this.empresa = { ...empresaExistentes[0] }
          } else {
            this.empresa = { cpf: cpfCliente }
          }
        } catch (e) {
          if (e.response) {
            this.errorMessage(e.response.data)
          } else {
            this.errorMessage(e)
          }
        }
      }
    },

    syncDadosClienteComDadosReceita(dadosEmpresaReceita) {
      if (!dadosEmpresaReceita.message) {
        this.empresa = {
          business_name: dadosEmpresaReceita.fantasia,
          trade_name: dadosEmpresaReceita.nome,
          address: dadosEmpresaReceita.logradouro,
          district: dadosEmpresaReceita.bairro,
          city: dadosEmpresaReceita.municipio,
          zip_code: dadosEmpresaReceita.cep,
          numero: dadosEmpresaReceita.numero,
          cnpj: dadosEmpresaReceita.cnpj,
        }
        const estadoEncontrado = this.estados.filter(
          (estado) => estado.sigla === dadosEmpresaReceita.uf
        )
        if (!!estadoEncontrado && !!estadoEncontrado[0]) {
          this.empresa = { ...this.empresa, state: estadoEncontrado[0].nome }
        }
        if (dadosEmpresaReceita.telefone) {
          this.empresa = {
            ...this.empresa,
            phone: dadosEmpresaReceita.telefone.replace(/[(, ), -]+/g, ''),
          }
        }
      }
    },

    async fetchDadosEmpresaReceita() {
      try {
        const qCnpj = await jsonp(
          `https://www.receitaws.com.br/v1/cnpj/${this.empresa.cnpj}`
        )
        return Promise.resolve(qCnpj)
      } catch (e) {
        return Promise.reject(e)
      }
    },
  },
}
</script>

<style lang="scss">
.hasError .wizard-navigation .wizard-nav {
  .active a .wizard-icon-circle > div {
    background-color: transparent !important;
    i {
      color: #fff !important;
    }
  }
}

.cadastroEmpresaRelação .wizard-navigation .wizard-nav {
  .active {
    a .wizard-icon-circle > div {
      background-color: transparent !important;
      i {
        color: #00aa9e;
      }
    }
  }
  li:not(.active ~ li):not(.active) {
    .wizard-icon-circle {
      background-color: #00aa9e;
      i {
        color: white;
      }
    }
    span {
      color: #00aa9e;
    }
  }
}
</style>
