<template>
  <validation-observer v-slot="{ invalid }">
    <v-card class="pa-2">
      <v-card-title>
        Classificação Pedido Nº {{ pedidoAgendado.pedido.numero_pedido }}
      </v-card-title>
      <v-card-text>
        <v-card
          class="d-flex pa-4 my-8"
          v-for="caracteristica in caracteristicasAClassificar"
          :key="caracteristica.id"
        >
          <v-row>
            <v-col>
              <v-text-field
                label="Característica"
                :value="caracteristica.descricao"
                disabled
                readonly
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Limite Mínimo"
                :value="caracteristica.valor_minimo"
                disabled
                readonly
              />
            </v-col>
            <v-col>
              <v-text-field
                label="Limite Máximo"
                :value="caracteristica.valor_maximo"
                disabled
                readonly
              />
            </v-col>
            <v-col>
              <validation-provider
                :name="caracteristica.descricao"
                v-slot="{ errors }"
                :rules="{
                  required: true,
                  min_value: caracteristica.valor_minimo,
                  max_value: caracteristica.valor_maximo,
                }"
              >
                <v-text-field
                  v-model="caracteristica.valor"
                  label="Valor"
                  v-mask="['#.##', '##.##']"
                  :name="caracteristica.descricao"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <div>
          <v-btn plain @click="$emit('cancelar')">Cancelar</v-btn>
        </div>
        <div>
          <v-btn
            class="mx-4"
            color="error"
            @click="save(true, true)"
          >
            Refugar
          </v-btn>
          <v-btn
            class="mx-4"
            color="primary"
            :disabled="invalid"
            @click="save(invalid)"
          >
            {{ classificacaoID ? 'Atualizar' : 'Finalizar' }}
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </validation-observer>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ClassificacaoModal',
  props: {
    pedidoAgendado: {
      type: Object,
      required: true,
    },
    origem: {
      type: String,
      default: null,
    },
    classificacaoID: {
      type: String,
    },
  },
  data() {
    return {
      caracteristicasAClassificar: [],
    }
  },
  computed: {
    ...mapGetters('fornecedores', ['caracteristicasAtivas']),
  },
  methods: {
    ...mapActions('fornecedores', ['listCaracteristicas']),
    ...mapActions('classificacao', [
      'addClassificacao',
      'getClassificacao',
      'editClassificacao',
    ]),
    ...mapActions('template', ['successMessage', 'errorMessage']),

    async add(refugar) {
      try {
        const dados = {
          pedido_agendado: this.pedidoAgendado.public_id,
          caracteristicas: this.caracteristicasAClassificar.map((cac) => ({
            caracteristica: cac.caracteristica,
            valor: cac.valor,
          })),
          origem: this.origem,
          refugar,
        }
        await this.addClassificacao({ dados })
        this.successMessage('Classificacação adicionada com sucesso')
        this.pedidoAgendado.classificado = true
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },
    async edit(refugar) {
      try {
        const dados = {
          caracteristicas: this.caracteristicasAClassificar.map((cac) => ({
            caracteristica: cac.caracteristica,
            valor: cac.valor,
          })),
          refugar,
        }
        await this.editClassificacao({
          classificacaoID: this.classificacaoID,
          dados,
        })
        this.successMessage('Classificacação editada com sucesso')
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    },

    async save(invalid, refugar = false) {
      if (invalid && !refugar) return
      if (this.classificacaoID) {
        await this.edit(refugar)
      } else {
        await this.add(refugar)
      }
      this.$emit('cancelar')
    },
  },
  async created() {
    if (this.classificacaoID) {
      try {
        const classificacao = await this.getClassificacao({
          classificacaoID: this.classificacaoID,
        })
        this.caracteristicasAClassificar = classificacao.caracteristicas.map(
          (c) => ({
            ...c,
            descricao: c.caracteristica,
            caracteristica: c.caracteristica_id,
          })
        )
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    } else {
      await this.listCaracteristicas({
        fornecedorID: this.pedidoAgendado.pedido.fornecedor,
        produtoID: this.pedidoAgendado.pedido.produto.id,
      })

      this.caracteristicasAClassificar = this.caracteristicasAtivas.map(
        (ca) => ({
          ...ca,
          caracteristica: ca.public_id,
          valor: null,
        })
      )
    }
  },
}
</script>
