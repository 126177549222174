var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-card',{staticClass:"pa-2"},[_c('v-card-title',[_vm._v(" Classificação Pedido Nº "+_vm._s(_vm.pedidoAgendado.pedido.numero_pedido)+" ")]),_c('v-card-text',_vm._l((_vm.caracteristicasAClassificar),function(caracteristica){return _c('v-card',{key:caracteristica.id,staticClass:"d-flex pa-4 my-8"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Característica","value":caracteristica.descricao,"disabled":"","readonly":""}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Limite Mínimo","value":caracteristica.valor_minimo,"disabled":"","readonly":""}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Limite Máximo","value":caracteristica.valor_maximo,"disabled":"","readonly":""}})],1),_c('v-col',[_c('validation-provider',{attrs:{"name":caracteristica.descricao,"rules":{
                required: true,
                min_value: caracteristica.valor_minimo,
                max_value: caracteristica.valor_maximo,
              }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['#.##', '##.##']),expression:"['#.##', '##.##']"}],attrs:{"label":"Valor","name":caracteristica.descricao,"error-messages":errors},model:{value:(caracteristica.valor),callback:function ($$v) {_vm.$set(caracteristica, "valor", $$v)},expression:"caracteristica.valor"}})]}}],null,true)})],1)],1)],1)}),1),_c('v-card-actions',{staticClass:"d-flex justify-space-between"},[_c('div',[_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")])],1),_c('div',[_c('v-btn',{staticClass:"mx-4",attrs:{"color":"error"},on:{"click":function($event){return _vm.save(true, true)}}},[_vm._v(" Refugar ")]),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"primary","disabled":invalid},on:{"click":function($event){return _vm.save(invalid)}}},[_vm._v(" "+_vm._s(_vm.classificacaoID ? 'Atualizar' : 'Finalizar')+" ")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }