<template>
  <v-row>
    <v-col>
      <v-card>
        <v-card-text class="border-bottom">
          <h3 class="blue-grey--text text--darken-2 font-weight-regular">
            {{ title }}
          </h3>
        </v-card-text>
        <v-card-text>
          <v-form id="formServicosTriagem">
            <v-row class="justify-center">
              <div
                v-for="(
                  servicoAutorizado, index
                ) in servicosAutorizadosDisponiveis"
                :key="index"
              >
                <v-col cols="12" sm="4" lg="2" md="3">
                  <v-btn
                    color="blue"
                    @click="$emit('addServico', servicoAutorizado)"
                    height="120"
                    width="180"
                  >
                    <span class="text--primary" style="color: white !important"
                      ><img
                        v-if="
                          servicoAutorizado.servico.foto &&
                          servicoAutorizado.servico.foto.url
                        "
                        contain
                        aspect-ratio="1.5"
                        width="60"
                        :src="servicoAutorizado.servico.foto.url"
                      />
                      <img
                        v-else
                        contain
                        aspect-ratio="1.5"
                        width="60"
                        src="@/assets/images/servicos/serviceGeneric.png"
                      /><br />
                      <h4>{{ servicoAutorizado.servico.descricao }}</h4></span
                    >
                  </v-btn>
                </v-col>
              </div>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

import TipoAgendamento from '@/utils/agendamento'

export default {
  name: 'ServicosDisponiveis',
  
  computed: {
    ...mapState('agendamentos', ['agendamento', 'agendamentoSelecionado', 'tipoAgendamento']),

    isTriagem() {
      return this.tipoAgendamento === TipoAgendamento.Triagem
    },
    isEdicaoTriado() {
      return this.tipoAgendamento === TipoAgendamento.EdicaoTriado
    },
    isEdicaoAgendado() {
      return this.tipoAgendamento === TipoAgendamento.EdicaoAgendado
    },

    servicosAutorizadosDisponiveis() {
      const servicosAutorizadosFornecedor =
        this.agendamento.fornecedor.servicos_autorizados || []

      if (this.isTriagem || this.isEdicaoTriado){
        for (const servicoAutorizado of servicosAutorizadosFornecedor) {
          
          if(servicoAutorizado.servico.exige_classificacao)
          {
            if(this.exigeClassificacao()){
              this.$emit('addServico', servicoAutorizado)
            }
            else
            {
              const indice = servicosAutorizadosFornecedor.indexOf(servicoAutorizado)
              servicosAutorizadosFornecedor.splice(indice, 1)
            }
          }

        }
      }
     
      if (this.isTriagem || this.isEdicaoTriado)
        return servicosAutorizadosFornecedor

      return servicosAutorizadosFornecedor.filter((saf) =>
        this.agendamento.convenios.servicos.some(
          (obj) => obj.id === saf.servico.id
        )
      )
    },

    title() {
      return this.servicosAutorizadosDisponiveis.length > 0
        ? 'Selecione Serviço'
        : 'Não existe serviço cadastrado'
    },
  },
  methods: {
    exigeClassificacao(){
      const exige_classificacao = this.agendamentoSelecionado.pedidos_agendados.filter(s => s.pedido.subProduto.exige_classificacao)
      return exige_classificacao.length > 0
    },
  },
}
</script>
