<template>
  <v-card class="mb-7">
    <v-form>
      <v-card-text class="pa-5 border-bottom">
        <servicos-disponiveis @addServico="addServico" />
        <servicos-autorizados
          v-if="isTriagem || isEdicaoAgendado || isEdicaoTriado"
          :servicosAutorizados="servicosAutorizados"
          :servicosAgendados="agendamento.servicos_agendados"
          @deleteServico="deleteServico"
          ref="servicosAutorizados"
        />
        <servicos-agendados
          v-else
          :servicosAgendados="agendamento.servicos_agendados"
          @deleteServico="deleteServico"
        />
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import TipoAgendamento, {
  TipoTarifa,
  TipoPeso,
  Charge,
} from '@/utils/agendamento'
import patioApi from '@/services/patio'
import moment from 'moment'
import ServicosDisponiveis from './Servicos/ServicosDisponiveis.vue'
import ServicosAutorizados from './Servicos/ServicosAutorizados.vue'
import ServicosAgendados from './Servicos/ServicosAgendados.vue'

export default {
  name: 'EtapaServicos',

  components: { ServicosDisponiveis, ServicosAutorizados, ServicosAgendados },

  data() {
    return {
      servicosAutorizados: [],
      antecipado: false,
      servicosAgendados: [],
    }
  },

  computed: {
    ...mapState('agendamentos', [
      'agendamento',
      'agendamentoSelecionado',
      'tipoAgendamento',
    ]),

    isTriagem() {
      return this.tipoAgendamento === TipoAgendamento.Triagem
    },
    isEdicaoTriado() {
      return this.tipoAgendamento === TipoAgendamento.EdicaoTriado
    },
    isEdicaoAgendado() {
      return this.tipoAgendamento === TipoAgendamento.EdicaoAgendado
    },
  },

  async created() {
    if (this.isTriagem || this.isEdicaoTriado) {
      this.servicosAutorizados = this.agendamento.fornecedor.servicos_autorizados.filter(
        (fsa) =>
          this.agendamento.servicos_agendados.some(
            (obj) => obj.id === fsa.servico.id
          )
      )

      for (const servicoAutorizado of this.servicosAutorizados) {
        await this.calculaValorServicoAutorizado(servicoAutorizado)
      }

      this.updateAtributoAgendamento({
        valor: this.servicosAutorizados,
        nomeAtributo: 'servicos_triados',
      })
    }
  },

  methods: {
    ...mapActions('agendamentos', ['updateAtributoAgendamento']),
    ...mapActions('programacao', ['getProgramacaoUnidade']),
    exigeClassificacao() {
      const exige_classificacao = this.agendamentoSelecionado.pedidos_agendados.filter(
        (s) => s.pedido.subProduto.exige_classificacao
      )
      return exige_classificacao.length > 0
    },
    bonificarServico(servicoAutorizado) {
      this.$set(servicoAutorizado, 'valorTotal', 0)
    },
    bonificaServico(servicoAutorizado) {
      console.log('->', this.agendamento.vaga)
      if (this.agendamento.vaga && this.agendamento.vaga.setor.bonificada) {
        if ('bonificado' in servicoAutorizado.servico.config) {
          return Boolean(servicoAutorizado.servico.config.bonificado)
        }
      }
      return false
    },
    bonificaServicos() {
      this.servicosAutorizados.forEach((servicoAutorizado) => {
        if (this.bonificaServico(servicoAutorizado)) {
          this.bonificarServico(servicoAutorizado)
        }
      })
    },
    async addServico(servicoAutorizado) {
      const servicoAutorizadoCopy = Object.assign({}, servicoAutorizado)
      if (this.isTriagem || this.isEdicaoTriado) {
        let thisServiceHasAdded = this.servicosAutorizados.some((obj) => {
          return obj.public_id === servicoAutorizado.public_id
        })
        if (!thisServiceHasAdded) {
          this.servicosAutorizados.push(servicoAutorizadoCopy)
        }
      } else {
        let thisServiceHasAdded = this.servicosAgendados.some((obj) => {
          return obj.id === servicoAutorizado.servico.id
        })
        if (!thisServiceHasAdded) {
          this.servicosAgendados.push(servicoAutorizadoCopy.servico)
        }
      }

      if (this.isTriagem || this.isEdicaoTriado) {
        this.updateAtributoAgendamento({
          valor: this.servicosAutorizados,
          nomeAtributo: 'servicos_triados',
        })
        await this.calculaValorServicoAutorizado(servicoAutorizadoCopy)
      } else {
        this.updateAtributoAgendamento({
          valor: this.servicosAgendados,
          nomeAtributo: 'servicos_agendados',
        })
      }
    },

    async calculaValorServicoAutorizado(servicoAutorizado) {
      const { public_id, servico, valor } = servicoAutorizado

      let valorUnitario = 0
      let valorTotal = 0

      if (servico.tipoTarifa === TipoTarifa.Peso) {
        let tarifas = await patioApi.getServicoAutorizadoTarifaPeso({
          servicos_autorizado: public_id,
          proprietario: this.agendamento.gate.public_id,
        })

        if (tarifas.length == 0) {
          tarifas = await patioApi.getServicoTarifaPeso({
            servico: servico.id,
            proprietario: this.agendamento.gate.public_id,
          })
        }

        if (tarifas.length > 0) {
          const tarifa = tarifas[0]
          let totalPeso = this.agendamento.pedidos_agendados.reduce(
            (acc, p) => acc + p.quantidade_agendada,
            0
          )
          if (tarifa.tipoPeso === TipoPeso.KG) totalPeso *= 1000

          valorUnitario = valor !== null ? valor : tarifa.valor
          valorTotal = valorUnitario * totalPeso
        }
      }

      if (servico.tipoTarifa === TipoTarifa.Eixo) {
        if (this.agendamento['programacao_unidade'] !== null) {
          if (
            this.agendamento['programacao_unidade'] !== undefined &&
            this.agendamento['programacao_unidade'] !== ''
          ) {
            const item = await this.getProgramacaoUnidade(
              this.agendamento['programacao_unidade']
            )
            const now = new Date()
            const data_inicio = moment(item.data_inicio)

            if (moment(data_inicio).isAfter(now)) {
              this.antecipado = true
            }
          }
        } else {
          if (this.agendamento['data_pedido'] !== null) {
            if (
              this.agendamento['data_pedido'] !== undefined &&
              this.agendamento['data_pedido'] !== ''
            ) {
              const now = new Date()
              const data_inicio = moment(this.agendamento['data_pedido'])

              if (moment(data_inicio).isAfter(now)) {
                this.antecipado = true
              }
            }
          }
        }

        const quantidadeEixosCaminhao = this.agendamento.veiculo.modalidade.eixo
        let tarifas = await patioApi.getServicoAutorizadoTarifaEixo({
          servico: servico.id,
          proprietario: this.agendamento.fornecedor.fornecedor_id,
          eixo: quantidadeEixosCaminhao,
        })

        if (tarifas.length == 0) {
          tarifas = await patioApi.getServicoTarifaEixo({
            servico: servico.id,
            proprietario: this.agendamento.gate.public_id,
            eixo: quantidadeEixosCaminhao,
            antecipado: this.antecipado,
          })
        }
        if (tarifas.length > 0) {
          valorUnitario = tarifas[0].valor
          valorTotal = tarifas[0] ? valorUnitario : 0
        }

        this.agendamento.pedidos_agendados.forEach((pedidoItem) => {
          if (pedidoItem.pedido.produto.id == 187) {
            valorUnitario = 30
            valorTotal = 30
          }
        })
      }

      // Calcular o serviço por tempo
      if (servico.tipoTarifa === TipoTarifa.Hora) {
        // calculando o tempo em que o caminhao ficou no patio
        let entrada = new Date(this.agendamento.triado_em)

        if (this.agendamento.triado_em !== null) {
          entrada = new Date(this.agendamento.triado_em)
        } else {
          entrada = new Date(Date.now())
        }

        let saida = new Date(Date.now())
        let tempo_empatio = (saida - entrada) / 3600000

        // buscado a tarifa pelo tempo que o caminhao ficou no patio

        let tarifas = null

        tarifas = await patioApi.getServicoTarifaHora({
          servico: servico.id,
          proprietario: this.agendamento.gate.public_id,
          tolerancia: parseInt(tempo_empatio),
        })
        // setando o valor da tarifa
        if (tarifas.length > 0) {
          valorUnitario = tarifas[0].valor
          valorTotal = tarifas[0] ? valorUnitario : 0
        } else {
          valorUnitario = 0
          valorTotal = 0
        }

        // Quando o tipo de cobrança for no final do processo, não cobra o serviço no inicio
        if (servico.config.charge === Charge.End) {
          valorUnitario = 0
          valorTotal = 0
          entrada = new Date(Date.now())
          saida = new Date(Date.now())
        }

        this.$set(servicoAutorizado, 'tempo_empatio', parseInt(tempo_empatio))
        this.$set(
          servicoAutorizado,
          'entrada',
          moment(entrada).format('DD/MM/YYYY, HH:mm:ss')
        )
        this.$set(
          servicoAutorizado,
          'saida',
          moment(saida).format('DD/MM/YYYY, HH:mm:ss')
        )
      }

      this.$set(servicoAutorizado, 'valorUnitario', valorUnitario)

      if (this.bonificaServico(servicoAutorizado)) {
        console.log('bonifica? ', this.bonificaServico(servicoAutorizado))
        console.log(servicoAutorizado)
        console.log('@?')
        this.bonificarServico(servicoAutorizado)
      } else {
        this.$set(servicoAutorizado, 'valorTotal', valorTotal)
      }
    },

    deleteServico(index) {
      if (this.isTriagem || this.isEdicaoTriado) {
        this.servicosAutorizados.splice(index, 1)
        this.updateAtributoAgendamento({
          valor: this.servicosAutorizados,
          nomeAtributo: 'servicos_autorizados',
        })
      } else {
        this.servicosAgendados.splice(index, 1)
        this.updateAtributoAgendamento({
          valor: this.servicosAgendados,
          nomeAtributo: 'servicos_agendados',
        })
      }
    },

    validateTransportadora() {
      const servicosAutorizados = this.$refs.servicosAutorizados;
      if (servicosAutorizados) {
        return servicosAutorizados.validateTransportadora();
      }
      return true;
    }
  },
}
</script>