<template>
  <div>
    <div class="mt-4">
      <v-card-text class="py-6">
        <v-container>
          <v-row class="d-flex justify-center mb-0 pb-0">
            <v-col cols="12" sm="12" md="3">
              <validation-provider
                v-slot="{ errors }"
                name="nome"
                rules="required"
              >
                <v-text-field
                  v-model="value.placa_cavalo"
                  label="Placa do cavalo"
                  v-mask="['AAA-XXX', 'AAA-XXXX']"
                  @keyup="wordUpper"
                  @change="wordUpperChange('placa_cavalo', $event)"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="12" md="3">
              <validation-provider
                v-slot="{ errors }"
                name="estados"
                rules="required"
              >
                <v-autocomplete
                  label="Estado"
                  :items="estados"
                  v-model="value.estado"
                  item-text="nome"
                  item-value="id"
                  :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mb-0 pb-0">
            <v-col cols="12" sm="12" md="4">
              <validation-provider v-slot="{ errors }" name="placa da carreta">
                <v-text-field
                  v-model="value.placa_carreta_1"
                  label="Placa da carreta 1"
                  v-mask="['AAA-XXX', 'AAA-XXXX']"
                  @keyup="wordUpper"
                  @change="wordUpperChange('placa_carreta_1', $event)"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="value.placa_carreta_2"
                label="Placa da carreta 2"
                v-mask="['AAA-XXX', 'AAA-XXXX']"
                @keyup="wordUpper"
                @change="wordUpperChange('placa_carreta_2', $event)"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="value.placa_carreta_3"
                label="Placa da carreta 3"
                v-mask="['AAA-XXX', 'AAA-XXXX']"
                @keyup="wordUpper"
                @change="wordUpperChange('placa_carreta_3', $event)"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mb-0 pb-0">
            <v-col cols="12" sm="12" md="4">
              <validation-provider
                v-slot="{ errors }"
                name="modalidade"
                rules="required"
              >
                <v-autocomplete
                  label="Modalidade"
                  :items="modalidades"
                  v-model="value.modalidade"
                  :item-text="modalidadeText"
                  item-value="id"
                  :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <validation-provider
                v-slot="{ errors }"
                name="carroceria"
                rules="required"
              >
                <v-autocomplete
                  label="Carroceria"
                  :items="carrocerias"
                  v-model="value.carroceria"
                  item-text="descricao"
                  item-value="id"
                  :error-messages="errors"
                ></v-autocomplete>
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                :disabled="false"
                v-model="value.tara"
                label="Tara"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mb-0 pb-0">
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="value.chassis"
                label="Chassis"
                @change="validarChassis(value.chassis)"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="value.renavam"
                label="Renavam"
              />
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-text-field
                v-model="value.manufacturing_date"
                label="Data de fabricação"
                persistent-hint
                type="date"
              />
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mb-0 pb-0">
            <v-col cols="12" sm="12" md="4">
              <v-autocomplete
                label="Cor"
                :items="cores"
                :disabled="false"
                v-model="value.cor"
                item-text="descricao"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" md="4">
              <v-autocomplete
                label="Marca"
                :disabled="false"
                :items="marcas"
                v-model="value.marca"
                item-text="descricao"
                item-value="id"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center mb-0 pb-0">
            <v-col cols="12" sm="12" md="6">
              <v-autocomplete
                label="Motorista"
                :items="motoristas"
                :search-input.sync="search"
                v-model="value.motorista"
                :item-text="motoristaText"
                item-value="user.public_id"
                :error-messages="errors"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="12" sm="12" lg="1" md="1" class="my-5">
              <modal-motorista @addedMotorista="addedMotorista" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="hasButtonSave">
        <v-spacer></v-spacer>
        <buttons-cadastro @close="close" @save="save" />
      </v-card-actions>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import UtilsApi from '@/services/utils/utils.api'
import { cpf } from 'cpf-cnpj-validator'
import PatioApi from '@/services/patio/index'
import MotoristasApi from '@/services/tenants/motoristas.js'
import ModalMotorista from '@/components/administrativo/motorista/ModalMotorista'
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'

export default {
  components: { ModalMotorista, ButtonsCadastro },

  name: 'EtapaDadosVeiculo',
  props: {
    value: {
      type: Object,
      required: false,
    },
    hasButtonSave: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isEdit: false,
      estados: [],
      cidades: [],
      motoristasSelecionados: [],
      motoristaVeiculo: [],
      motorista: null,
      search: '',
      errorsInput: {
        placa_cavalo: false,
        placa_carreta_1: false,
        placa_carreta_2: false,
        placa_carreta_3: false,
      },
      hasTransportadora: false,
    }
  },

  computed: {
    ...mapState('patio', ['carrocerias', 'modalidades', 'cores', 'marcas']),
    ...mapState('motoristas', ['motoristas']),
    ...mapState('auth', ['empresaAtual']),
    // ...mapState('transportadoras', ['transportadoras']),

    motoristas() {
      const l = []
      if (this.motorista) {
        l.push(this.motorista)
      }
      return l.concat(
        this.motoristasSelecionados.length > 0
          ? this.motoristasSelecionados
          : this.motoristaVeiculo
      )
    },
  },

  async created() {
    if (!this.empresaAtual.public_id) return

    await this.getEstados()
    await this.getCarrocerias()
    await this.getCores()
    await this.getMarcas()
    await this.getModalidades()
    await this.getMotoristas()
    // await this.getTransportadoras()

    if (Object.keys(this.value || {}).length) {
      this.atualizaDados()
      await this.onlyMotoristaAssociado({ search: this.value.motorista })
    }
  },

  methods: {
    ...mapActions('patio', [
      'getCarrocerias',
      'getCores',
      'getMarcas',
      'getModalidades',
      'addVeiculo',
      'editVeiculo',
      'updateDocumentosVeiculo',
    ]),
    ...mapActions('motoristas', ['getMotoristas']),
    // ...mapActions('transportadoras', ['getTransportadoras']),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    async addedMotorista(motorista) {
      this.motoristaVeiculo = motorista
      this.value.motorista = motorista.user.public_id
    },
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },
    validarChassis(chassis) {
      if (chassis.length < 8) {
        this.value.chassis = ""
        alert("Chassi invalido deve ser maior 8 caracteres")
      }
      if (chassis.length > 20) {
        this.value.chassis = ""
        alert("Chassi invalido deve ser menor 20 caracteres")
      }
    },

    close() {
      this.$emit('close')
    },
    async save() {
      this.$emit('save')
    },

    atualizaDados() {
      Object.keys(this.value).forEach((key) => {
        if (typeof this.value[key] === 'object' && this.value[key] !== null) {
          if (key === 'motorista') {
            if (this.value[key].user !== null) {
              this.motorista = { ...this.value[key] }
              this.value[key] = this.value[key].user.public_id
              this.getMotoristaSearch({ user: this.value[key] })
            }
          } else {
            if (this.value[key].public_id)
              this.value[key] = this.value[key].public_id
            else if (this.value[key].id) this.value[key] = this.value[key].id
          }
        }
      })
      this.isEdit = Object.keys(this.value).length
    },

    async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },

    wordUpper(event) {
      event.target.value = event.target.value.toUpperCase()
    },

    wordUpperChange(field, value) {
      if (field === 'placa_cavalo') {
        // this.value = {}
        this.getVeiculoPlacaCavalo(value)
      }

      this.value[field] = value.toUpperCase()
    },

    async getVeiculoPlacaCavalo(value) {
      const veiculo = await PatioApi.getVeiculoParams({
        placa_cavalo: value,
        tenant: this.empresaAtual.public_id,
      })
      this.isEdit = false
      if (veiculo[0]) {
        Object.assign(this.value, veiculo[0])
        this.atualizaDados()
      }
    },

    modalidadeText(item) {
      return `${item.descricao} - ${item.eixo} ${
        item.eixo > 1 ? 'eixos' : 'eixo'
      }`
    },

    motoristaText(item) {
      return `${item.user.name} - ${cpf.format(item.user.cpf)}`
    },

    async getMotoristaSearch(parametros) {
      if (!Object.keys(parametros).length) return

      try {
        const motorista = await MotoristasApi.getMotorista(
          this.empresaAtual,
          parametros
        )

        this.motoristasSelecionados = motorista
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },

    async onlyMotoristaAssociado(parametros) {
      try {
        const motoristaAssociado = await MotoristasApi.getMotorista(
          this.empresaAtual,
          parametros
        )
        this.motoristaVeiculo = motoristaAssociado
      } catch (e) {
        if (e.response.status === 404) {
          this.errorMessage('Página não encontrada')
        }
      }
    },
  },
  watch: {
    async search(search) {
      if (search && search.length > 2 && search.length <= 3) {
        await this.getMotoristaSearch({
          search: search,
        })
      } else if (search && search.length > 3 && search.length <= 5) {
        await this.getMotoristaSearch({
          search: search,
        })
      } else if (search && search.length < 2) {
        this.motoristasSelecionados = []
      }
    },
  },
}
</script>
<style></style>
