<template>
  <v-row>
    <v-col>
      <v-card class="mb-2">
        <v-card-text class="pa-2 border-bottom">
          <h3 class="blue-grey--text text--darken-2 font-weight-regular">
            Serviços Autorizados
          </h3>
        </v-card-text>
        <v-card-text class="pa-5 border-bottom">
          <v-form id="formServicosAutorizados">
            <v-row class="justify-center">
              <v-col
                cols="12"
                sm="12"
                lg="3"
                md="3"
                class="servicos pt-5 border-bottom"
                v-for="(servicoAutorizado, index) in servicosAutorizados"
                :key="index"
              >
                <div>
                  <img
                    v-if="
                      servicoAutorizado.servico.foto &&
                      servicoAutorizado.servico.foto.url
                    "
                    class="pt-2"
                    :src="servicoAutorizado.servico.foto.url"
                    width="100"
                  />
                  <img
                    v-else
                    class="pt-2"
                    :src="
                      require('@/assets/images/servicos/serviceGeneric.png')
                    "
                    width="100"
                  />
                </div>
                <div>
                  <p>{{ servicoAutorizado.servico.descricao }}</p>
                </div>
                <div
                  class="pt-1 align-center"
                  v-if="isTriagem || isEdicaoTriado"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="Forma de Pagamento"
                    rules="required"
                  >
                    <v-select
                      label="Forma de Pagamento"
                      :items="
                        isServicoAgendadoNoConvenio(servicoAutorizado)
                          ? formasPagamentoConvenio
                          : formasPagamento
                      "
                      item-text="descricao"
                      return-object
                      v-model="servicoAutorizado.forma_pagamento"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Transportadora"
                    rules="required"
                  >
                    <v-select
                      label="Transportadora"
                      :items="theTransportadoras"
                      return-object
                      v-if="
                        servicoAutorizado.forma_pagamento &&
                        servicoAutorizado.forma_pagamento.is_convenio
                      "
                      v-model="servicoAutorizado.transportadora"
                      :item-text="
                        (item) =>
                          item.transportadora.nome +
                          ' - ' +
                          `${
                            item.transportadora.cnpj != null
                              ? item.transportadora.cnpj
                              : item.transportadora.cpf
                          }`
                      "
                      item-value="public_id"
                      :error-messages="errors"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Valor unitario"
                    rules="required"
                  >
                    <vuetify-money
                      ref="valor"
                      v-model="servicoAutorizado.valorUnitario"
                      v-bind:disabled="enableValor"
                      :readonly="
                        servicoAutorizado.valorUnitario === 0 ||
                        isServicoAgendadoNoConvenio(servicoAutorizado)
                      "
                      :error-messages="errors"
                      label="Valor unitário"
                      v-bind:options="options"
                    />
                  </validation-provider>
                  <validation-provider
                    v-slot="{ errors }"
                    name="Valor total"
                    rules="required"
                  >
                    <vuetify-money
                      ref="valor"
                      v-model="servicoAutorizado.valorTotal"
                      v-bind:disabled="enableValor"
                      :readonly="
                        servicoAutorizado.valorTotal === 0 ||
                        isServicoAgendadoNoConvenio(servicoAutorizado)
                      "
                      :error-messages="errors"
                      label="Valor total"
                      v-bind:options="options"
                    />
                  </validation-provider>
                </div>
                <div class="text-center" v-if="servicoAutorizado.tempo_empatio">
                  <h2>
                    Tempo em patio: {{ servicoAutorizado.tempo_empatio }} Horas
                  </h2>
                </div>
                <div class="text-center" v-if="servicoAutorizado.entrada">
                  <h2>Entrada: {{ servicoAutorizado.entrada }}</h2>
                </div>
                <div class="text-center" v-if="servicoAutorizado.saida">
                  <h2>Saida: {{ servicoAutorizado.saida }}</h2>
                </div>
                <div>
                  <div class="text-center">
                    <v-icon
                      style="font-size: 36px"
                      @click="$emit('deleteServico', index)"
                      color="error"
                      >mdi-delete</v-icon
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import TipoAgendamento from '@/utils/agendamento'

export default {
  name: 'ServicosAutorizados',
  props: {
    servicosAutorizados: {
      type: Array,
      required: true,
    },
    servicosAgendados: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      options: {
        locale: 'pt-BR',
        prefix: 'R$',
        suffix: '',
        length: 11,
        precision: 2,
      },
    }
  },
  computed: {
    ...mapState('patio', ['convenios', 'conveniosNaoAtivos']),
    ...mapState('agendamentos', [
      'agendamento',
      'agendamentoSelecionado',
      'transportadoras',
      'tipoAgendamento',
    ]),
    ...mapGetters('formaPagamento', [
      'formasPagamento',
      'formasPagamentoConvenio',
    ]),
    theTransportadoras() {
      return this.convenios
    },
    isTriagem() {
      return this.tipoAgendamento === TipoAgendamento.Triagem
    },
    enableValor() {
      return true
    },
    isEdicaoTriado() {
      return this.tipoAgendamento === TipoAgendamento.EdicaoTriado
    },
    isEdicaoAgendado() {
      return this.tipoAgendamento === TipoAgendamento.EdicaoAgendado
    },
  },

  methods: {
    ...mapActions('formaPagamento', ['getFormasPagamento']),
    ...mapActions('patio', ['getConvenios']),
    ...mapActions('template', ['errorMessage']),
    isServicoAgendadoNoConvenio(servicoAutorizado) {
      const ehConveniado = this.servicosAgendados.some(
        (s) => s.id === servicoAutorizado.servico.id
      )
      return ehConveniado
    },
    validateTransportadora() {
      return this.servicosAutorizados.every(servico => 
        !servico.forma_pagamento ||
        servico.forma_pagamento.descricao !== 'A PRAZO' ||
        servico.transportadora
      );
    }
  },
  async mounted() {
    try {
      await this.getFormasPagamento()
      await this.getConvenios()
    } catch (e) {
      this.errorMessage(e.response.data)
    }
  },
}
</script>