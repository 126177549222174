<template>
  <v-row>
    <v-col>
      <v-card class="mb-2">
        <v-card-text class="pa-2 border-bottom">
          <h3 class="blue-grey--text text--darken-2 font-weight-regular">
            Serviços Autorizados para faturamento
          </h3>
        </v-card-text>
        <v-card-text class="pa-5 border-bottom">
          <v-form>
            <v-row class="justify-center">
              <v-col
                cols="12"
                sm="12"
                lg="3"
                md="3"
                class="servicos pt-5 border-bottom"
                v-for="(servicoAgendado, index) in servicosAgendados"
                :key="index"
              >
                <div>
                  <img
                    v-if="servicoAgendado.foto && servicoAgendado.foto.url"
                    class="pt-2"
                    width="100"
                    :src="servicoAgendado.foto.url"
                  />
                  <img
                    v-else
                    class="pt-2"
                    :src="
                      require('@/assets/images/servicos/serviceGeneric.png')
                    "
                    width="100"
                  />
                </div>
                <div>
                  <p>{{ servicoAgendado.descricao }}</p>
                </div>
                <div>
                  <div class="text-center">
                    <v-icon
                      style="font-size: 36px"
                      @click="$emit('deleteServico', index)"
                      color="error"
                      >mdi-delete</v-icon
                    >
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ServicosAgendados',

  props: {
    servicosAgendados: {
      type: Array,
      required: true,
    },
  },

  computed: {
    ...mapState('agendamentos', ['agendamento', 'tipoAgendamento']),
  },
}
</script>
