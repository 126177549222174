<template>
  <div>
    <v-row v-if="!isUnidadeGate">
      <v-col class="12" cols="12" sm="12" lg="3" md="3">
        <seleciona-empresa
          label="Gate"
          :items="gates"
          v-model="agendamento.gate"
          @change="handleGateSelecionado"
          :disabled="this.disable()"
        />
      </v-col>
      <v-dialog v-model="janelaCargaDescargaDialog" max-width="1000px">
        <form-janela-carga-descarga
          v-model="janelaCargaDescargaModel"
          :janelaCargaDescarga="agendamento.janelaCargaDescarga"
          :formTitle="janelaCargaDescargaformTitle"
          :navioIndex="navioIndex"
          :headers="janelaCargaDescargaheaders"
          :itens="janelaCargaDescargaItens"
          @close="closedModalJanela"
        />
      </v-dialog>
    </v-row>
    <v-row>
      <v-col class="12" cols="12" sm="12" lg="3" md="3">
        <seleciona-empresa
          label="Fornecedor"
          :items="theFornecedores"
          v-model="agendamento.fornecedor"
          @change="handleFornecedorSelecionado"
          :readonly="fornecedorReadonly"
          :disabled="this.disable()"
        />
      </v-col>
      <v-col class="12" cols="12" sm="12" lg="3" md="3">
        <seleciona-empresa
          label="Cliente"
          placeholder="Digite aqui para pesquisar!"
          :items="theClientes"
          v-model="agendamento.cliente"
          :readonly="clienteReadonly"
          :disabled="this.disable()"
          @keyup.native="customChange($event, handleGetClientes)"
          @change="handleGetCliente"
          :loading="calling"
        />
      </v-col>
      <v-col cols="12" sm="12" lg="2" md="2">
        <seleciona-empresa
          label="Transportadora"
          :items="theTransportadoras"
          v-model="agendamento.transportadora"
          @change="handleTransportadoraSelecionada"
          :readonly="transportadoraReadonly"
          :disabled="this.disableTransportadora()"
        />
      </v-col>
      <v-col class="12" sm="6" lg="2" md="2">
        <validation-provider
          v-slot="{ errors }"
          name="Tipo de frete"
          rules="required"
        >
          <v-autocomplete
            label="Tipo Frete"
            item-text="descricao"
            required
            return-object
            :items="tiposFrete"
            :disabled="disable()"
            :value="agendamento.tipo_frete"
            @input="
              updateAtributoAgendamento({
                valor: $event,
                nomeAtributo: 'tipo_frete',
              })
            "
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" sm="6" lg="2" md="2">
        <validation-provider
          v-slot="{ errors }"
          name="Tipo de Operação"
          rules="required"
        >
          <v-autocomplete
            label="Tipo de Operação"
            item-text="descricao"
            required
            return-object
            :items="tiposOperacao"
            :disabled="disable()"
            :value="agendamento.tipo_operacao"
            @input="
              updateAtributoAgendamento({
                valor: $event,
                nomeAtributo: 'tipo_operacao',
              })
            "
            :error-messages="errors"
          />
        </validation-provider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="8" lg="4" md="4">
        <seleciona-empresa
          label="Terminal"
          :items="theTerminais"
          v-model="agendamento.terminal"
          :readonly="terminalReadonly"
          :disabled="this.disable()"
          @change="handleGetArmazens"
        />
      </v-col>
      <v-col cols="12" sm="4" lg="3" md="3" v-if="agendamentoComRFID">
        <validation-provider
          v-slot="{ errors }"
          name="Preencha o Tag/Cracha"
          :rules="{ required: agendamentoComRFID }"
        >
          <v-text-field
            v-model="agendamento.access_credential"
            @change="handleValidaTag"
            :error-messages="errors"
            label="Tag/Cracha"
            maxlength="30"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" sm="12" lg="2" md="2">
        <v-dialog v-model="dialog" max-width="700px">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" dark class="my-2" v-on="on"
              >Adicionar Navio
            </v-btn>
          </template>
          <form-navio
            v-model="novoNavio"
            :navio="agendamento.navio"
            :gate="agendamento.gate"
            :formTitle="formTitle"
            @close="close"
            @save="save"
            :navioIndex="navioIndex"
          />
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash'

import { mapState, mapActions, mapMutations } from 'vuex'
import FormNavio from './FormNavio.vue'
import FormJanelaCargaDescarga from './FormJanelaCargaDescarga.vue'
import SelecionaEmpresa from './SelecionaEmpresa.vue'
import PatioAPI from '@/services/patio'
import ContratosApi from '@/services/tenants/contratos'
import Perfil from '@/utils/perfil'
import TipoAgendamento from '@/utils/agendamento'
import { customChange } from '@/mixins/autocomplete.js'
import moment from 'moment'

import { Sequences, CustomPermissions } from '@/utils/permissions'

export default {
  inject: {
    agendamento_xml: {
      from: 'agendamento_xml',
      default: () => {},
    },
    setCallbackXml: {
      from: 'setCallbackXml',
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      antecipado: false,
      formTitle: 'Adicionar Navio',
      navioIndex: -1,
      novoNavio: {},
      consultados: { cliente: false },
      janelaCargaDescargaIndex: -1,
      janelaCargaDescargaDialog: false,
      janelaCargaDescargaformTitle: 'Janela Carga/Descarga',
      janelaCargaDescargaModel: {},
      janelaCargaDescargaItens: [],
      janelaCargaDescargaheaders: [
        { text: 'Ações', value: 'actions' },
        { text: 'Data/Hora inicio', value: 'data_inicio' },
        { text: 'Data/Hora fim', value: 'data_fim' },
        { text: 'Quantidade de caminhões', value: 'qt_caminhoes' },
        { text: 'Triagem', value: 'proprietario.business_name' },
        { text: 'Fornecedor', value: 'fornecedor.business_name' },
        { text: 'Terminal', value: 'terminal.business_name' },
        { text: 'Produto', value: 'produto.descricao' },
        { text: 'Sub Produto', value: 'sub_produto.descricao' },
        { text: 'Triagem', value: 'proprietario.business_name' },
        { text: 'Serviço', value: 'servico.descricao' },
      ],
    }
  },
  components: { FormNavio, SelecionaEmpresa, FormJanelaCargaDescarga },
  mixins: [customChange],
  computed: {
    ...mapState('agendamentos', [
      'agendamento',
      'fornecedores',
      'clientes',
      'gates',
      'transportadoras',
      'terminais',
      'produtos',
      'subProdutos',
      'tipoAgendamento',
      'pedidoSelecionado',
      'agendamentoSelecionado',
      'programacaoUnidade',
      'programacaoFornecedor',
      'programacaoRegiao',
      'programacaoCliente',
      'estacionamentos',
    ]),
    ...mapState('auth', ['empresaAtual', 'perfilAtual']),
    ...mapState('patio', ['tiposFrete', 'tiposOperacao']),

    canBypassDate() {
      return this.$canDo(
        CustomPermissions.IGNORAR_DATA_TRIAGEM,
        Sequences.GerenciamentoServico.toString()
      )
    },
    
    validaAgendamentoSemJanelaDeHorario() {
     
      if (
        this.configFornecedor &&
        this.configFornecedor.agendamento &&
        this.configFornecedor.agendamento.janelaDeHorario 
      ) {
        if (this.agendamento.janela_carga_descarga) {
          return true
        }else{
          return false
        }
      }

      return true
    },
    validaAgendamentoSemCotaPedido() {

      if (
        this.configFornecedor &&
        this.configFornecedor.agendamento &&
        !this.configFornecedor.agendamento.agendamentoSemCota 
      ) {
        // caso ele não aceite, verificar o agendamento tem um pedido vinculado
        if (this.tipoAgendamento === TipoAgendamento.DoPedido) {
          return true
        }

        if (this.tipoAgendamento === TipoAgendamento.EdicaoAgendado) {
          return true
        }

        // caso ele não aceite, verificar o agendamento tem uma programacao vinculada
        if (this.tipoAgendamento === TipoAgendamento.DaProgramacao) {
          return true
        }
        if (this.agendamento.janela_carga_descarga) {
          return true
        }

        return false
      }

      return true
    },

    configFornecedor() {
      return this.agendamento.fornecedor?.config
    },

    agendamentoComRFID() {
      return (
        this.configFornecedor &&
        this.configFornecedor.agendamento &&
        this.configFornecedor.agendamento.exigeRFID
      )
    },
    exigeJanelaDeHorario() {
      return (
        this.configFornecedor &&
        this.configFornecedor.agendamento &&
        this.configFornecedor.agendamento.janelaDeHorario
      )
    },
    agendamentoSemCota() {
      return (
        this.configFornecedor &&
        this.configFornecedor.agendamento &&
        this.configFornecedor.agendamento.agendamentoSemCota
      )
    },
    fornecedorAceitaAntecipado() {
      return (
        this.configFornecedor &&
        this.configFornecedor.agendamento &&
        this.configFornecedor.agendamento.aceitaAntecipado
      )
    },
    fromCota() {
      return this.tipoAgendamento === TipoAgendamento.DaProgramacao
    },
    theClientes() {
      if (this.fromCota) {
        return [this.agendamento.cliente]
      } else if (
        this.tipoAgendamento === TipoAgendamento.Triagem ||
        this.tipoAgendamento === TipoAgendamento.DoPedido
      ) {
        return this.agendamento.cliente ? [this.agendamento.cliente] : []
      } else {
        return []
          .concat(this.clientes, this.agendamento.cliente)
          .filter((item) => item)
      }
    },

    theTransportadoras() {
      if (
        this.tipoAgendamento === TipoAgendamento.DoPedido &&
        this.agendamento?.transportadora
      ) {
        return [this.agendamento.transportadora]
      } else {
        return this.transportadoras
      }
    },
    theTerminais() {
      if (
        this.tipoAgendamento === TipoAgendamento.DoPedido &&
        this.agendamento?.terminal
      ) {
        return [this.agendamento.terminal]
      } else {
        return this.terminais
      }
    },
    theFornecedores() {
      if (this.tipoAgendamento === TipoAgendamento.DoPedido) {
        return [this.agendamento.fornecedor]
      } else {
        return this.fornecedores
      }
    },
    isUnidadeGate() {
      return this.empresaAtual.tiposContrato.includes(Perfil.Unidade)
    },
    isFornecedor() {
      if (this.perfilAtual == Perfil.Fornecedor) {
        return this.empresaAtual.tiposContrato.includes(Perfil.Fornecedor)
      }
      return false
    },
    fornecedorReadonly() {
      return (
        this.isFornecedor || this.tipoAgendamento === TipoAgendamento.DoPedido
      )
    },
    clienteReadonly() {
      return this.tipoAgendamento === TipoAgendamento.DoPedido
    },
    transportadoraReadonly() {
      return Boolean(
        (this.tipoAgendamento === TipoAgendamento.DoPedido ||
          this.perfilAtual == Perfil.Transportadora) &&
          this.agendamento.transportadora
      )
    },
    terminalReadonly() {
      return Boolean(
        this.tipoAgendamento === TipoAgendamento.DoPedido &&
          this.agendamento.terminal
      )
    },
    podeBuscarTransportadoras() {
      return (
        !_.isEmpty(this.agendamento.fornecedor) &&
        this.agendamento.fornecedor.fornecedor_id &&
        !_.isEmpty(this.agendamento.cliente) &&
        this.agendamento.cliente.cliente_id
      )
    },
    is_default() {
      return ['NF', 'OC', 'VR'].includes(this.agendamento.tipo_documento)
    },
  },
  async created() {
    if (!this.empresaAtual.public_id) return
    this.setCallbackXml('info_geral', this.setDadosXml)
    this.setDadosXml()
    if (this.isUnidadeGate) {
      await this.atualizaFornecedoresAgendamento(this.empresaAtual)
      this.updateAtributoAgendamento({
        valor: this.empresaAtual,
        nomeAtributo: 'gate',
      })
      // Preecher automaticamente os campos do free pass
      console.log("Preenchendo...")
      setTimeout(() => {
        this.preencheCampos()
      }, 3000)
    } else {
      await this.atualizaGatesAgendamento(this.empresaAtual)
      await this.atualizaTransportadorasAgendamento(this.empresaAtual.public_id)
    }

    if (this.tipoAgendamento === TipoAgendamento.DoPedido) {
      this.handlePedido()
    } else if (
      this.tipoAgendamento === TipoAgendamento.Triagem ||
      this.tipoAgendamento === TipoAgendamento.EdicaoAgendado ||
      this.tipoAgendamento === TipoAgendamento.EdicaoTriado
    ) {
      this.handleAgendamento()
    } else if (this.tipoAgendamento === TipoAgendamento.DaProgramacao) {
      this.handleProgramacao()
    }

    if (this.perfilAtual == Perfil.Transportadora) {
      this.agendamento.transportadora = this.theTransportadoras.filter(
        (t) => t.cnpj === this.empresaAtual.cnpj
      )[0]
    }
  },
  methods: {
    ...mapActions('agendamentos', [
      'updateAtributoAgendamento',
      'updateAtributo',
      'atualizaGatesAgendamento',
      'atualizaFornecedoresAgendamento',
      'atualizaClientesAgendamento',
      'buscaClientesAgendamento',
      'atualizaTransportadorasAgendamento',
      'atualizaTerminaisAgendamento',
      'atualizaEstacionamentoAgendamento',
      'concatTransportadoras',
      'buscaClienteAgendamento',
    ]),
    ...mapActions('programacao', ['getProgramacaoUnidade']),
    ...mapActions('patio', ['getConveniosByParams', 'getNavios']),
    ...mapActions('contratos', ['getArmazens']),
    ...mapMutations('patio', ['setTiposFrete']),
    ...mapActions('template', ['errorMessage']),
    closedModalJanela(){
      if (this.validaAgendamentoSemJanelaDeHorario && !this.empresaAtual.gate) {
        this.janelaCargaDescargaDialog = false
      }else{
        alert('Fornecedor não aceita agendamento sem uma janela de Carga/Descarga')
        this.janelaCargaDescargaDialog = true
      }
    },
    clienteConsultado() {
      this.consultados.cliente = true
    },
    resetaClienteConsultado() {
      this.consultados.cliente = false
    },
    async handleValidaTag() {
      try {
        if (
          this.agendamentoSelecionado.fornecedor.public_id ===
          '11bdbc50-57c0-40db-bd96-f28a02b964b5'
        ) {
          await PatioAPI.validaAgendamentoTesc(
            this.empresaAtual,
            this.agendamento
          )
        }
      } catch (error) {
        if (error.response.status === 422) {
          alert(error.response.data.msg)
          this.$router.push({
            name: 'Gerenciamento de Serviços',
          })
        }
      }
    },
    async handleGetCliente() {
      if (this.consultados.cliente) {
        this.resetaClienteConsultado()
        return
      }
      const cliente = await this.buscaClienteAgendamento({
        empresa: this.empresaAtual,
        cliente: this.agendamento.cliente,
      })
      this.clienteConsultado()
      this.updateAtributoAgendamento({
        valor: cliente,
        nomeAtributo: 'cliente',
      })
    },
    async handleGetClientes(event) {
      const value = event.target.value
      await this.buscaClientesAgendamento({
        fornecedor: this.empresaAtual.public_id,
        pesquisa: value,
      })
    },
    async handleGetArmazens(terminal) {
      await this.getArmazens({
        contratoPublicID: terminal.public_id,
        empresa: { public_id: this.agendamento.fornecedor.fornecedor_id },
      })
    },
    disable() {
      if (this.programacaoUnidade) {
        return true
      }
      return false
    },
    disableTransportadora() {
      if (this.programacaoCliente) {
        return true
      }
      return this.transportadoraReadonly
    },
    close() {
      this.dialog = false
    },
    save() {
      this.updateAtributoAgendamento({
        valor: this.novoNavio,
        nomeAtributo: 'navio',
      })
    },
    async handleProgramacao() {
      this.handleProgramacaoUnidade()
      this.handleProgramacaoFornecedor()
      this.handleProgramacaoRegiao()
      this.handleProgramacaoCliente()
    },
    async handleProgramacaoUnidade() {
      if (this.programacaoUnidade) {
        this.updateAtributoAgendamento({
          valor: this.programacaoUnidade.data_inicio,
          nomeAtributo: 'data_cota',
        })

        this.updateAtributoAgendamento({
          valor: this.programacaoUnidade,
          nomeAtributo: 'programacao_unidade',
        })
        this.updateAtributoAgendamento({
          valor: this.programacaoUnidade.proprietario,
          nomeAtributo: 'gate',
        })
        this.updateAtributoAgendamento({
          valor: this.programacaoUnidade.fornecedor,
          nomeAtributo: 'fornecedor',
        })
        await this.handleFornecedorSelecionado(
          this.programacaoUnidade.fornecedor
        )
        this.updateAtributoAgendamento({
          valor: this.programacaoUnidade.tipo_operacao,
          nomeAtributo: 'tipo_operacao',
        })
        this.updateAtributoAgendamento({
          valor: this.programacaoUnidade.terminal,
          nomeAtributo: 'terminal',
        })
        this.updateAtributoAgendamento({
          valor: [this.programacaoUnidade.produto],
          nomeAtributo: 'produtos',
        })
        this.updateAtributoAgendamento({
          valor: [this.programacaoUnidade.sub_produto],
          nomeAtributo: 'subProdutos',
        })
      }
    },
    async handleProgramacaoFornecedor() {
      if (this.programacaoFornecedor) {
        this.updateAtributoAgendamento({
          valor: this.programacaoFornecedor,
          nomeAtributo: 'programacao_fornecedor',
        })
        this.setTiposFrete(this.programacaoFornecedor.tipos_frete)
        if (this.programacaoFornecedor.tipos_frete.length == 1) {
          this.updateAtributoAgendamento({
            valor: this.programacaoFornecedor.tipos_frete[0],
            nomeAtributo: 'tipo_frete',
          })
        }
      }
    },
    async handleProgramacaoRegiao() {
      if (this.programacaoRegiao) {
        this.updateAtributoAgendamento({
          valor: this.programacaoRegiao,
          nomeAtributo: 'programacao_regiao',
        })
        this.updateAtributoAgendamento({
          valor: this.programacaoRegiao.cliente,
          nomeAtributo: 'cliente',
        })
      }
    },
    async handleProgramacaoCliente() {
      if (this.programacaoCliente) {
        this.updateAtributoAgendamento({
          valor: this.programacaoCliente,
          nomeAtributo: 'programacao_cliente',
        })
        this.updateAtributoAgendamento({
          valor: this.programacaoCliente.transportadora,
          nomeAtributo: 'transportadora',
        })
      }
    },
    async handlePedido() {

      this.updateAtributoAgendamento({
          valor: this.pedidoSelecionado.tipo_operacao,
          nomeAtributo: 'tipo_operacao',
      })
      this.updateAtributoAgendamento({
        valor: this.pedidoSelecionado.gate,
        nomeAtributo: 'gate',
      })
      this.updateAtributoAgendamento({
        valor: this.pedidoSelecionado.fornecedor,
        nomeAtributo: 'fornecedor',
      })
      await this.handleFornecedorSelecionado(this.pedidoSelecionado.fornecedor)
      this.updateAtributoAgendamento({
        valor: this.pedidoSelecionado.cliente,
        nomeAtributo: 'cliente',
      })
      this.updateAtributoAgendamento({
        valor: this.pedidoSelecionado.tipoFrete,
        nomeAtributo: 'tipo_frete',
      })
      this.updateAtributoAgendamento({
        valor: this.pedidoSelecionado.terminal,
        nomeAtributo: 'terminal',
      })
    },
    async handleAgendamento() {
      this.updateAtributoAgendamento({
        valor: this.agendamentoSelecionado.fornecedor,
        nomeAtributo: 'fornecedor',
      })
      await this.handleFornecedorSelecionado(
        this.agendamentoSelecionado.fornecedor
      )
      await this.atualizaEstacionamentoAgendamento(
        this.agendamentoSelecionado.gate
      )

      await this.handleGateSelecionado(this.agendamentoSelecionado.gate)
      if (this.tipoAgendamento === TipoAgendamento.Triagem) {
        if (
          this.agendamentoSelecionado['programacao_unidade'] !== undefined &&
          this.agendamentoSelecionado['programacao_unidade'] !== ''
        ) {
          const item = await this.getProgramacaoUnidade(
            this.agendamentoSelecionado['programacao_unidade']
          )
          const now = new Date()
          const data_inicio = moment(item.data_inicio)
          const data_fim = moment(item.data_fim)

          if (moment(now).isAfter(data_fim) && !this.canBypassDate) {
            alert(
              'Caminhão marcando depois de sua janela de carga/descarga! Horário da janela inicio: ' +
                moment(data_inicio).format('DD/MM/yyyy HH:MM:ss') +
                ' Até as ' +
                moment(data_fim).format('DD/MM/yyyy HH:MM:ss')
            )
            this.$router.push({
              name: 'Gerenciamento de Serviços',
            })
          }

          if (moment(data_inicio).isAfter(now)) {
            if (this.fornecedorAceitaAntecipado === true) {
              this.antecipado = true
            } else {
              alert(
                'Caminhão marcando antes de sua janela de carga/descarga! Horário da janela inicio: ' +
                  moment(data_inicio).format('DD/MM/yyyy HH:MM:ss') +
                  ' Até as ' +
                  moment(data_fim).format('DD/MM/yyyy HH:MM:ss')
              )
              this.$router.push({
                name: 'Gerenciamento de Serviços',
              })
            }
          }
        }
      }

      Object.keys(this.agendamentoSelecionado).forEach((key) => {
        if (key !== 'fornecedor') {
          this.updateAtributoAgendamento({
            valor: this.agendamentoSelecionado[key],
            nomeAtributo: key,
          })
        }
      })
    },
    async handleGateSelecionado(gate) {
      

      await this.atualizaFornecedoresAgendamento(gate)
      await this.atualizaEstacionamentoAgendamento(gate)

      if (this.isFornecedor) {
        this.agendamento.fornecedor = this.fornecedores.filter(
          (f) => f.fornecedor_id === this.empresaAtual.public_id
        )[0]
        await this.handleFornecedorSelecionado(this.agendamento.fornecedor)
      }

      await this.preencheCampos()

      await this.handleTransportadoraSelecionadaPedido(
        this.agendamento.transportadora
      )

      this.$forceUpdate()
    },

    async preencheCampos() {
      if (
        !this.is_default &&
        this.agendamento.gate.public_id ==
          '21fcdd4f-38ec-4901-96a0-915616c84ed7'
      ) {
        let fornecedor_id
        if (this.agendamento.tipo_documento == 'FP') {
          fornecedor_id = '6857b694-d96c-4fa3-b50e-fb2f7b0700b0'
        }
        if (this.agendamento.tipo_documento == 'PK') {
          fornecedor_id = '21fcdd4f-38ec-4901-96a0-915616c84ed7'
        }
        const fornecedor = this.theFornecedores.find(
          (i) => i.fornecedor_id === fornecedor_id
        )
        if (fornecedor) {
          this.updateAtributoAgendamento({
            valor: fornecedor,
            nomeAtributo: 'fornecedor',
          })
          await this.handleFornecedorSelecionado(this.agendamento.fornecedor)
        }

        this.updateAtributoAgendamento({
          valor: { id: 2, descricao: 'Descarga' },
          nomeAtributo: 'tipo_operacao',
        })
        this.agendamento.tipo_operacao = { id: 2, descricao: 'Descarga' }

        this.updateAtributoAgendamento({
          valor: { id: 2, descricao: 'FOB' },
          nomeAtributo: 'tipo_frete',
        })
        this.agendamento.tipo_frete = { id: 2, descricao: 'FOB' }
      }

      this.$forceUpdate()
    },

    async handleFornecedorSelecionado(fornecedor) {
      await this.atualizaTerminaisAgendamento(fornecedor.fornecedor_id)

      let gate__public_id = this.agendamento.gate.public_id

      if (
        this.tipoAgendamento === TipoAgendamento.Triagem ||
        this.tipoAgendamento === TipoAgendamento.EdicaoAgendado ||
        this.tipoAgendamento === TipoAgendamento.EdicaoTriado
      ) {
        gate__public_id = this.agendamentoSelecionado.gate.public_id
      }

      const terminais_gate = await ContratosApi.getContratos(gate__public_id, {
        perfil: Perfil.Terminal,
      })
      const terminais_map = terminais_gate.map((item) => item.cliente_id)

      const terminais = [].concat(
        terminais_gate,
        this.terminais.filter(
          (terminal) => !terminais_map.includes(terminal.cliente_id)
        )
      )
      
      this.updateAtributo({
        valor: terminais,
        nomeAtributo: 'terminais',
      })

      this.updateAtributoAgendamento({
        valor: fornecedor.produtos,
        nomeAtributo: 'produtos',
      })
      this.updateAtributoAgendamento({
        valor: fornecedor.embalagens,
        nomeAtributo: 'embalagens',
      })

      if (this.agendamento.produtos) {
        const subProdutos = []
        this.agendamento.produtos.forEach((produto) => {
          if (produto.subprodutos) {
            produto.subprodutos.forEach((subproduto) => {
              subProdutos.push({
                id: subproduto.id,
                descricao: subproduto.descricao,
              })
            })
          }
        })
        this.updateAtributoAgendamento({
          valor: subProdutos,
          nomeAtributo: 'subProdutos',
        })
      }

      this.embalagens = fornecedor.embalagens

      if (
        !this.is_default &&
        gate__public_id == '21fcdd4f-38ec-4901-96a0-915616c84ed7'
      ) {
        let terminal_id
        if (this.agendamento.tipo_documento == 'FP') {
          terminal_id = '6857b694-d96c-4fa3-b50e-fb2f7b0700b0'
        }
        if (this.agendamento.tipo_documento == 'PK') {
          terminal_id = '21fcdd4f-38ec-4901-96a0-915616c84ed7'
        }
        const terminal = this.theTerminais.find(
          (i) => i.cliente_id === terminal_id
        )
        if (terminal) {
          this.updateAtributoAgendamento({
            valor: terminal,
            nomeAtributo: 'terminal',
          })
          this.handleGetArmazens(this.agendamento.terminal)
        }

        if (this.agendamento.tipo_documento == 'PK') {
          const cliente_id = 'f208501b-009d-427f-8b41-e107ed763b7d'
          await this.buscaClientesAgendamento({
            fornecedor: this.empresaAtual.public_id,
            public_id: cliente_id,
          })
          const cliente = this.theClientes.find(
            (i) => i.public_id == cliente_id
          )
          if (cliente) {
            this.updateAtributoAgendamento({
              valor: cliente,
              nomeAtributo: 'cliente',
            })
          }
        }
      }

      // Verificar se o fornecedor aceita agendamento sem cota
      if (!this.validaAgendamentoSemCotaPedido && !this.empresaAtual.gate) {
        alert('Fornecedor não aceita agendamento sem uma cota ou pedido')
        this.$router.push({
          name: 'Gerenciamento de Serviços',
        })
      }

      await this.verificarBlackList()
      // let data_inicio_programacao_unidade = null
      // if (
      //     this.agendamentoSelecionado['programacao_unidade'] !== undefined &&
      //     this.agendamentoSelecionado['programacao_unidade'] !== ''
      //   ) {
      //     const item = await this.getProgramacaoUnidade(
      //       this.agendamentoSelecionado['programacao_unidade']
      //     )
      //     data_inicio_programacao_unidade = moment(item.data_inicio)
      //   }

      const janelas = await PatioAPI.getJanelasCargaDescarga({
        gate: this.agendamento.gate.public_id,
        // data_inicio: data_inicio_programacao_unidade,
        valido: true,
      })
      
      this.janelaCargaDescargaItens = janelas.results || []
      if (this.janelaCargaDescargaItens.length > 0) {
        this.janelaCargaDescargaDialog = true
      }
    },
    async setDadosXml() {
      const fornecedor_cnpj = this.agendamento_xml?.fornecedor?.cnpj
      const tipo_operacao_id = this.agendamento_xml?.tipo_operacao?.id
      const tipo_frete_id = this.agendamento_xml?.tipo_frete?.id
      const cliente_id = this.agendamento_xml?.cliente?.public_id

      if (fornecedor_cnpj) {
        const fornecedor = this.theFornecedores.find(
          (i) => i.cnpj == fornecedor_cnpj
        )
        if (fornecedor) {
          this.updateAtributoAgendamento({
            valor: fornecedor,
            nomeAtributo: 'fornecedor',
          })
          this.handleFornecedorSelecionado(fornecedor)
        }
      }
      if (tipo_operacao_id) {
        const tipo_operacao = this.tiposOperacao.find(
          (i) => i.id == tipo_operacao_id
        )
        if (tipo_operacao) {
          this.updateAtributoAgendamento({
            valor: tipo_operacao,
            nomeAtributo: 'tipo_operacao',
          })
        }
      }
      if (tipo_frete_id) {
        const tipo_frete = this.tiposFrete.find((i) => i.id == tipo_operacao_id)
        if (tipo_frete) {
          this.updateAtributoAgendamento({
            valor: tipo_frete,
            nomeAtributo: 'tipo_frete',
          })
        }
      }
      if (cliente_id) {
        await this.buscaClientesAgendamento({
          fornecedor: this.empresaAtual.public_id,
          public_id: cliente_id,
        })
        const cliente = this.theClientes.find((i) => i.public_id == cliente_id)
        if (cliente) {
          this.updateAtributoAgendamento({
            valor: cliente,
            nomeAtributo: 'cliente',
          })
        }
      }
    },
    async atualizarListaNavios() {
      try {
        await this.getNavios({
          tenant: this.agendamento.fornecedor.fornecedor_id,
        })
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperados.')
        }
      }
    },
    async verificarBlackList() {
      try {
        await PatioAPI.verificarBlackList(this.agendamento)
      } catch (error) {
        if (error.response && error.response.status === 400) {
          this.errorMessage(error.response ? error.response.data : error)
          this.agendamento.transportadora = null
        }
      }
    },
    async verificaBiometriaCadastrada() {
      if (this.agendamentoSelecionado.tipo_operacao.descricao === 'Descarga') {
        try {
          if (
            this.agendamentoSelecionado.fornecedor.public_id ===
            '11bdbc50-57c0-40db-bd96-f28a02b964b5'
          ) {
            await PatioAPI.verificaBiometriaCadastrada(this.agendamento)
          }
        } catch (error) {
          if (error.response && error.response.status === 406) {
            alert(
              'Motorista com a biometria não cadastrada no sistema do ronda!'
            )
            this.$router.push({
              name: 'Gerenciamento de Serviços',
            })
          }
        }
      }
    },
    async handleTransportadoraSelecionada() {
      if (
        this.agendamento.gate &&
        this.agendamento.gate.public_id !== undefined
      ) {
        if (
          this.agendamento.transportadora &&
          this.agendamento.transportadora.transportadora_id !== undefined
        ) {
          const transportadora = this.agendamento.transportadora.cnpj
          const data = await this.getConveniosByParams({
            empresa: this.agendamento.gate,
            transportadora: transportadora,
          })
          
          this.updateAtributoAgendamento({
            valor: data[0],
            nomeAtributo: 'convenios',
          })
        }
      }
    },
    async handleTransportadoraSelecionadaPedido(transportadoraParam) {
      if (
        this.agendamento.gate &&
        this.agendamento.gate.public_id !== undefined
      ) {
        if (transportadoraParam && transportadoraParam.transportadora_id) {
          const transportadora = transportadoraParam.cnpj
          const data = await this.getConveniosByParams({
            empresa: this.agendamento.gate,
            transportadora: transportadora,
          })
          this.updateAtributoAgendamento({
            valor: data[0],
            nomeAtributo: 'convenios',
          })
        }
      }
    },
  },
  watch: {
    async podeBuscarTransportadoras(val) {
      if (val) {
        await this.concatTransportadoras(
          this.agendamento.fornecedor.fornecedor_id
        )
        await this.concatTransportadoras(this.agendamento.cliente.cliente_id)

        await this.concatTransportadoras(this.empresaAtual.public_id)
      }
    },
  },
}
</script>
