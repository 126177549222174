<template>
  <v-card-text class="mb-7">
    <validation-observer ref="observer" v-slot="{}">
      <v-form>
        <v-card-text class="pa-5 border-bottom">
          <v-row>
            <v-col cols="12" sm="2" md="2" class="pt-0">
              <validation-provider
                v-slot="{ errors }"
                name="nome"
                rules="required"
              >
                <v-text-field
                  v-model="motorista.cpf"
                  :error-messages="errors"
                  label="Documento"
                  readonly
                  disabled
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pt-0">
              <validation-provider
                v-slot="{ errors }"
                name="nome"
                rules="required"
              >
                <v-text-field
                  v-model="motorista.name"
                  label="Nome"
                  required
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="pt-0">
              <validation-provider
                v-slot="{ errors }"
                name="celular"
                rules="required"
              >
                <v-text-field
                  v-model="motorista.celular"
                  label="Celular"
                  required
                  :error-messages="errors"
                  v-mask="['(##) #####-####']"
                />
              </validation-provider>
            </v-col>
            <v-col cols="12" sm="3" md="3" class="pt-0">
              <validation-provider
                v-slot="{ errors }"
                name="cnh_registro"
                rules="required"
              >
                <v-text-field
                  v-model="motorista.cnh_registro"
                  label="Registro da CNH"
                  required
                  :error-messages="errors"
                  v-mask="['###########']"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <h3
            class="subtitle blue-grey--text text--darken-2 font-weight-regular"
          >
            Dados da habilitação
          </h3>
          <v-row class="d-flex justify-center pt-7">
            <v-col cols="12" sm="5" md="2" class="pt-0">
              <v-text-field
                v-model="motorista.data_nascimento"
                label="Data de nascimento"
                persistent-hint
                type="date"
              />
            </v-col>
            <v-col cols="12" sm="5" md="3" class="pt-0">
              <v-text-field
                v-model="motorista.cnh_emissao"
                label="Data de emissão da CNH"
                persistent-hint
                type="date"
              />
            </v-col>
            <v-col cols="12" sm="5" md="3" class="pt-0">
              <v-text-field
                v-model="motorista.cnh_validade"
                label="Data de validade da CNH"
                persistent-hint
                type="date"
              />
            </v-col>
            <v-col cols="12" sm="5" md="2" class="pt-0">
              <v-select
                v-model="motorista.cnh_categoria"
                :items="categoriaCNH"
                label="Categoria da CNH"
                item-text="categoria"
                item-value="categoria"
              />
            </v-col>
            <v-col cols="12" sm="5" md="2" class="pt-0">
              <v-select
                v-model="motorista.cnh_uf_emissor"
                :items="estados"
                label="Estado emissor da CNH"
                item-text="nome"
                item-value="sigla"
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="pa-5">
          <v-row>
            <v-col cols="12" sm="2" md="2" class="pt-0">
              <v-text-field v-model="motorista.rg" label="RG" />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="pt-0">
              <v-text-field
                v-model="motorista.rg_orgao_emissor"
                label="Orgão emissor"
              />
            </v-col>
            <v-col cols="12" sm="2" md="2" class="pt-0">
              <v-select
                v-model="motorista.rg_uf_orgao_emissor"
                :items="estados"
                label="Estado do orgão emissor"
                item-text="nome"
                item-value="sigla"
              />
            </v-col>

            <v-col cols="12" sm="2" md="2" class="pt-0">
              <v-select
                v-model="motorista.nacionalidade"
                :items="nacionalidades"
                label="Nacionalidade"
                item-text="pais"
                item-value="pais"
              />
            </v-col>
            <v-col cols="12" sm="4" md="4" class="pt-0">
              <v-text-field
                v-model="motorista.email"
                label="Email"
                @change="verificaDadoCadastradoChange($event, 'email')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="hasButtonSave">
          <v-spacer></v-spacer>
          <buttons-cadastro
            @close="close"
            @save="save"
            :disabled="emailJaCadastrado"
          />
        </v-card-actions>
      </v-form>
    </validation-observer>
  </v-card-text>
</template>

<script>
import { cpf } from 'cpf-cnpj-validator'
import { validationMixin } from 'vuelidate'

import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'

import UsuarioApi from '@/services/usuario'
import UtilsApi from '@/services/utils/utils.api'

export default {
  mixins: [validationMixin],

  components: { ButtonsCadastro },

  props: {
    value: {
      type: Object,
      required: true,
    },
    hasButtonSave: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      emailJaCadastrado: false,
      motorista: this.value,
      estados: [],
      categoriaCNH: [
        { categoria: 'A' },
        { categoria: 'B' },
        { categoria: 'C' },
        { categoria: 'D' },
        { categoria: 'E' },
        { categoria: 'AB' },
        { categoria: 'AC' },
        { categoria: 'AD' },
        { categoria: 'AE' },
      ],
      nacionalidades: [
        { pais: 'Brasil' },
        { pais: 'Paraguai' },
        { pais: 'Argentina' },
        { pais: 'Uruguay' },
      ],
    }
  },
  watch: {
    value(val) {
      Object.assign(this.motorista, val)
      this.$forceUpdate()
    },
    motorista(val) {
      this.$emit('input', val)
    },
  },
  methods: {
    close: function () {
      this.$emit('close')
    },
    save: function () {
      this.$emit('save')
    },
    async getEstados() {
      this.estados = await UtilsApi.getEstadosBrasil()
    },
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid && !this.emailJaCadastrado
    },
    resetForm() {
      this.$nextTick(() => {
        this.$refs.observer.reset()
      })
    },

    async verificaDadoCadastradoChange(valor, campo) {
      if (valor) {
        if (campo === 'cpf') valor = cpf.strip(valor)

        const params = { [campo]: valor }
        this[`${campo}JaCadastrado`] = await this.verificaDadoCadastrado(params)
      } else {
        this[`${campo}JaCadastrado`] = false
      }
    },

    async verificaDadoCadastrado(params) {
      const data = await UsuarioApi.getUsuarioByParams(params)

      return data.length > 0
    },
  },
  created() {
    this.getEstados()
  },
}
</script>

<style lang="scss" scoped>
.habilitacao_title__borderbottom {
  border-bottom: 1px solid;
}
</style>
