<template>
  <v-row>
    <v-col cols="6">
      <v-card class="mb-2">
        <v-card-text class="pa-2 border-bottom">
          <h3 class="blue-grey--text text--darken-2 font-weight-regular">
            Dados do Remetente
          </h3>
        </v-card-text>
        <v-card-text class="pa-5 border-bottom">
          <v-form id="formDadosCliente">
            <v-row>
              <v-col class="pt-0" cols="10" sm="10" lg="10" md="10">
                <seleciona-empresa
                  label="Remetente"
                  :items="theRemetente"
                  v-model="agendamento.remetente"
                  @keyup.native="customChange($event, handleGetClientes)"
                /> </v-col
              ><v-col class="mt-3" cols="2" sm="2" lg="2" md="2">
                <modal-cliente @addedCliente="addedRemetente"></modal-cliente>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="6">
      <v-card class="mb-2">
        <v-card-text class="pa-2 border-bottom">
          <h3 class="blue-grey--text text--darken-2 font-weight-regular">
            Dados do Destinatário
          </h3>
        </v-card-text>
        <v-card-text class="pa-5 border-bottom">
          <v-form id="formDadosDestinatario">
            <v-row>
              <v-col class="pt-0" cols="10" sm="10" lg="10" md="10">
                <seleciona-empresa
                  label="Destinatário"
                  :items="theDestinatario"
                  v-model="agendamento.destinatario"
                  @keyup.native="customChange($event, handleGetClientes)"
                /> </v-col
              ><v-col class="mt-3" cols="2" sm="2" lg="2" md="2">
                <modal-cliente
                  @addedCliente="addedDestinatario"
                ></modal-cliente>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import TipoAgendamento from '@/utils/agendamento'
import SelecionaEmpresa from './SelecionaEmpresa.vue'
import ModalCliente from '@/components/administrativo/cliente/adicionarCliente/ModalCliente.vue'
import { customChange } from '@/mixins/autocomplete.js'

export default {
  inject: {
    agendamento_xml: {
      from: 'agendamento_xml',
      default: () => {},
    },
    setCallbackXml: {
      from: 'setCallbackXml',
      default: () => {},
    },
  },
  components: { SelecionaEmpresa, ModalCliente },
  mixins: [customChange],
  computed: {
    ...mapState('agendamentos', ['agendamento', 'clientes', 'tipoAgendamento', 'pedidoSelecionado']),
    ...mapState('auth', ['empresaAtual']),
    theRemetente() {
      if (this.tipoAgendamento === TipoAgendamento.Triagem) {
        return [this.agendamento.remetente]
      } else {
        return []
          .concat(this.clientes, this.agendamento.remetente)
          .filter((item) => item)
      }
    },

    theDestinatario() {
      if (this.tipoAgendamento === TipoAgendamento.Triagem) {
        return [this.agendamento.destinatario]
      } else {
        return []
          .concat(this.clientes, this.agendamento.destinatario)
          .filter((item) => item)
      }
    },
  },
  async created() {
    this.setCallbackXml('remetente_destinatario', this.setDadosXml)
    this.setDadosXml()

    if (this.tipoAgendamento === TipoAgendamento.DoPedido) {
      this.handlePedido()
    }
  },
  methods: {
    ...mapActions('agendamentos', [
      'updateAtributoAgendamento',
      'updateAtributo',
      'buscaClientesAgendamento',
    ]),
    ...mapActions('clientes', ['getCliente']),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    async setDadosXml() {
      const remetente_id = this.agendamento_xml?.remetente?.public_id
      const destinatario_id = this.agendamento_xml?.destinatario?.public_id

      if (remetente_id) {
        await this.buscaClientesAgendamento({
          fornecedor: this.empresaAtual.public_id,
          public_id: remetente_id,
        })
        const remetente = this.theRemetente.find(
          (i) => i.public_id == remetente_id
        )
        if (remetente) {
          this.updateAtributoAgendamento({
            valor: remetente,
            nomeAtributo: 'remetente',
          })
        }
      }
      if (destinatario_id) {
        await this.buscaClientesAgendamento({
          fornecedor: this.empresaAtual.public_id,
          public_id: destinatario_id,
        })
        const destinatario = this.theDestinatario.find(
          (i) => i.public_id == destinatario_id
        )
        if (destinatario) {
          this.updateAtributoAgendamento({
            valor: destinatario,
            nomeAtributo: 'destinatario',
          })
        }
      }
    },
    async handleGetClientes(event) {
      const value = event.target.value
      await this.buscaClientesAgendamento({
        fornecedor: this.empresaAtual.public_id,
        pesquisa: value,
      })
    },
    async handlePedido() {

      const remetente_id = this.pedidoSelecionado?.fornecedor?.cnpj
      const destinatario_id = this.pedidoSelecionado?.cliente?.public_id

      if (remetente_id) {
        await this.buscaClientesAgendamento({
          fornecedor: this.empresaAtual.public_id,
          value: remetente_id,
        })

        const remetente = this.theRemetente.find(
          (i) => i.cnpj == remetente_id
        )
        if (remetente) {
          this.updateAtributoAgendamento({
            valor: remetente,
            nomeAtributo: 'remetente',
          })
        }
      }
      if (destinatario_id) {
        await this.buscaClientesAgendamento({
          fornecedor: this.empresaAtual.public_id,
          public_id: destinatario_id,
        })
        const destinatario = this.theDestinatario.find(
          (i) => i.public_id == destinatario_id
        )
        if (destinatario) {
          this.updateAtributoAgendamento({
            valor: destinatario,
            nomeAtributo: 'destinatario',
          })
        }
      }
    },
    async buscaCliente(cliente) {
      const _cliente = await this.getCliente({
        public_id: cliente.public_id,
      })
      return _cliente
    },
    async addedRemetente(cliente) {
      const _cliente = await this.buscaCliente(cliente)
      console.log(_cliente)
      try {
        this.agendamento.remetente = _cliente
        this.updateAtributo({
          valor: [...this.clientes, _cliente],
          nomeAtributo: 'clientes',
        })
        this.updateAtributoAgendamento({
          valor: _cliente,
          nomeAtributo: 'remetente',
        })
        this.successMessage('Empresa adicionada com sucesso.')
        this.showForm = false
      } catch (error) {
        console.log(error)
        if (error.response) {
          this.errorMessage(error.response.data)
        } else {
          this.errorMessage(error)
        }
      }
    },
    async addedDestinatario(cliente) {
      const _cliente = await this.buscaCliente(cliente)
      try {
        this.agendamento.destinatario = _cliente
        this.updateAtributo({
          valor: [...this.clientes, _cliente],
          nomeAtributo: 'clientes',
        })
        this.updateAtributoAgendamento({
          valor: _cliente,
          nomeAtributo: 'destinatario',
        })
        this.successMessage('Empresa adicionada com sucesso.')
        this.showForm = false
      } catch (error) {
        console.log(error)
        if (error.response) {
          this.errorMessage(error.response.data)
        } else {
          this.errorMessage(error)
        }
      }
    },
  },
}
</script>

<style></style>
