var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-modal',{attrs:{"formTitle":_vm.formTitle},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-row',[_c('v-data-table',{staticClass:"border",attrs:{"headers":_vm.headers,"items":_vm.itens},scopedSlots:_vm._u([{key:"item.data_inicio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.ajustaData(new Date(item.data_inicio)).toLocaleString('pt-BR'))+" ")]}},{key:"item.data_fim",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.ajustaData(new Date(item.data_fim)).toLocaleString('pt-BR'))+" ")]}},{key:"item.qt_caminhoes",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.qt_restante(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-2 mb-2",attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.handleAgendar(item)}}},[_vm._v("Agendar")]),_c('v-list-item',{staticClass:"pointer",on:{"click":function($event){return _vm.handleDetalhes(item)}}},[_vm._v("Detalhes")])],1)],1)]}},{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem janelas")])]},proxy:true}],null,true)})],1)]},proxy:true},{key:"buttons",fn:function(){return [_c('div',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" Fechar ")])],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }