<template>
  <v-card>
    <v-card-title class="v-card mb-4">
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>

    <v-card-text class="py-6">
      <v-container>
        <slot name="header" />
        <slot name="body" />
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <slot name="buttons" />
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'BaseModal',
  props: {
    formTitle: String,
  },
}
</script>
<style></style>
