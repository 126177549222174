<template>
  <v-card-text class="pa-5 border-bottom">
    <validation-observer ref="observer">
      <v-form>
        <v-row class="justify-center d-flex py-10">
          <v-col cols="12" sm="2" md="2" class="pt-0">
            <v-select
              v-model="nacionalidade"
              :items="nacionalidades"
              label="Nacionalidade"
              item-text="pais"
              item-value="pais"
              @change="changeTypeMask(nacionalidade)"
            />
          </v-col>
          <v-col cols="12" sm="5" md="5" class="pt-0">
            <validation-provider v-slot="{ errors }" name="Documento">
              <v-text-field
                label="Documento"
                :error-messages="errors"
                required
                v-model="cpf"
                v-mask="mask"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-form>
    </validation-observer>
  </v-card-text>
</template>

<script>
export default {
  name: 'EtapaCpf',
  data() {
    return {
      cpf: '',
      nacionalidade: '',
      mask: {
        required: false,
        type: String,
      },
      nacionalidades: [
        { pais: 'Brasil' },
        { pais: 'Paraguai' },
        { pais: 'Argentina' },
        { pais: 'Uruguay' },
      ],
    }
  },
  methods: {
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      this.$emit('input', this.cpf)
      this.$emit('on-validate', isValid)
      return isValid
    },
    created() {
      this.mask = '###.###.###-##'
    },
    changeTypeMask(nacionalidade) {
      switch (nacionalidade) {
        case 'Brasil':
          this.mask = '###.###.###-##'
          break
        case 'Paraguai':
          this.mask = '#######'
          break
        case 'Argentina':
          this.mask = '##.###.###'
          break
        case 'Uruguay':
          this.mask = '#.###.###.#'
          break
        default:
          this.mask = '###########'
      }
    },
  },
}
</script>
