<template>
  <v-row>
    <v-col>
      <v-textarea
        full-width
        single-line
        label="Obs.:"
        :value="agendamento.observacao"
        @input="
          updateAtributoAgendamento({
            valor: $event,
            nomeAtributo: 'observacao',
          })
        "
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  inject: {
    agendamento_xml: {
      from: 'agendamento_xml',
      default: () => {},
    },
    setCallbackXml: {
      from: 'setCallbackXml',
      default: () => {},
    },
  },
  created() {
    this.setCallbackXml('observacao', this.setDadosXml)
    this.setDadosXml()
  },
  computed: {
    ...mapState('agendamentos', ['agendamento']),
  },
  methods: {
    ...mapActions('agendamentos', ['updateAtributoAgendamento']),
    async setDadosXml() {
      const observacao = this.agendamento_xml?.observacao
      if (observacao) {
        this.updateAtributoAgendamento({
          valor: observacao,
          nomeAtributo: 'observacao',
        })
      }
    },
  },
}
</script>

<style></style>
