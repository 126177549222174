var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":_vm.readonly ? 12 : 4}},[_c('validation-provider',{attrs:{"name":"peso","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"dense":"","type":"number","label":"Peso (Kg)","hint":"Valor da pesagem","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-weight","required":"","readonly":_vm.readonly,"rules":[function (v) { return v > 0 || 'Peso deve ser inserido'; }]},model:{value:(_vm.value.peso),callback:function ($$v) {_vm.$set(_vm.value, "peso", $$v)},expression:"value.peso"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":_vm.readonly ? 12 : 4}},[_c('validation-provider',{attrs:{"name":"data"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(['##/##/#### ##:##:##']),expression:"['##/##/#### ##:##:##']"}],attrs:{"dense":"","label":"Data da pesagem","hint":"Data da pesagem","type":"datetime","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-calendar","required":""},model:{value:(_vm.value.data),callback:function ($$v) {_vm.$set(_vm.value, "data", $$v)},expression:"value.data"}})]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":_vm.readonly ? 12 : 4}},[_c('validation-provider',{attrs:{"name":"tipo_pesagem"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dense":"","label":"Tipo","hint":"Tipo da pesagem","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-swap-horizontal","required":"","readonly":_vm.readonly,"item-text":"descricao","item-value":"id","items":_vm.tipos_pesagens},model:{value:(_vm.value.tipo_pesagem),callback:function ($$v) {_vm.$set(_vm.value, "tipo_pesagem", $$v)},expression:"value.tipo_pesagem"}})]}}])})],1),(!_vm.readonly && !_vm.pesagem_agendamento)?_c('v-col',{attrs:{"cols":"12","md":_vm.readonly ? 12 : 6}},[_c('validation-provider',{attrs:{"name":"Gate","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"dense":"","label":"Gate","hint":"Gate da pesagem","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-swap-horizontal","required":"","readonly":_vm.readonly,"item-text":"descricao","item-value":"public_id","items":_vm.gates_services},model:{value:(_vm.value.gate_service),callback:function ($$v) {_vm.$set(_vm.value, "gate_service", $$v)},expression:"value.gate_service"}})]}}],null,false,2196506410)})],1):_vm._e(),(!_vm.readonly && !_vm.pesagem_agendamento)?_c('v-col',{attrs:{"cols":"12","md":_vm.readonly ? 12 : 6}},[_c('validation-provider',{attrs:{"name":"justificativa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"dense":"","label":"Justificativa","hint":"Justificativa da pesagem","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-swap-horizontal","required":"","readonly":_vm.readonly,"item-text":"descricao","item-value":"public_id","items":_vm.justificativas},model:{value:(_vm.value.justificativa),callback:function ($$v) {_vm.$set(_vm.value, "justificativa", $$v)},expression:"value.justificativa"}})]}}],null,false,2561685625)})],1):_vm._e(),(!_vm.readonly)?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"name":"observacao"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"dense":"","label":"Observação","error-messages":errors,"persistent-hint":"","prepend-icon":"mdi-invoice-text-edit","readonly":_vm.readonly},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v("Observação "),_c('small',[_vm._v("(Informações breves)")])])]},proxy:true}],null,true),model:{value:(_vm.value.observacao),callback:function ($$v) {_vm.$set(_vm.value, "observacao", $$v)},expression:"value.observacao"}})]}}],null,false,3219266271)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }