<template>
  <v-row>
    <v-col class="12">
      <v-card class="mb-2">
        <v-card-text class="pa-2 border-bottom">
          <h3 class="blue-grey--text text--darken-2 font-weight-regular">
            Informações do Documento
          </h3>
        </v-card-text>
        <v-card-text class="pa-5 border-bottom">
          <validation-observer ref="observer">
            <v-form id="formDadosDocumento">
              <v-row class="justify-center">
                <v-col cols="12" lg="4" sm="4" md="4" class="pt-0">
                  <validation-provider
                    name="Tipo de Documento"
                    v-slot="{ errors }"
                    rules="required"
                  >
                    <v-select
                      label="Tipo de Documento"
                      required
                      item-text="label"
                      item-value="value"
                      :items="tipoDocumento"
                      :value="agendamento.tipo_documento"
                      @input="
                        updateAtributoAgendamento({
                          valor: $event,
                          nomeAtributo: 'tipo_documento',
                        })
                      "
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col
                  cols="12"
                  lg="2"
                  sm="4"
                  md="4"
                  class="pt-4"
                  v-if="agendamento && agendamento.tipo_documento === 'NF'"
                >
                  <validation-provider
                    :name="labelFileUpload"
                    v-slot="{ errors }"
                  >
                    <v-btn color="primary" dark @click="abrirInsercaoArquivo">{{
                      textUpload
                    }}</v-btn>
                    <input
                      :accept="uploadFileType"
                      type="file"
                      id="file"
                      style="display: none"
                      :label="labelFileUpload"
                      ref="file"
                      @change="handleUpload"
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FileApi from '@/services/files/index'
import { mapState, mapActions } from 'vuex'

export default {
  props: {
    documentosList: Array,
  },
  data() {
    return {
      tipoDocumento: [
        { label: 'Nota Fiscal', value: 'NF' },
        { label: 'Ordem de carga', value: 'OC' },
        { label: 'Free Pass', value: 'FP' },
        { label: 'Vira', value: 'VR' },
        { label: 'Estacionamento', value: 'PK' },
      ],
      dadosNotaFiscal: {},
      documentos: [],
      documento_id: 0,
    }
  },
  computed: {
    ...mapState('agendamentos', [
      'agendamento',
      'tipoAgendamento',
      'agendamentoSelecionado',
    ]),
    textUpload() {
      return this.agendamento.tipo_documento === 'NF'
        ? 'Inserir XML'
        : 'Inserir PDF'
    },
    labelFileUpload() {
      return this.agendamento.tipo_documento === 'NF'
        ? 'Arquivo XML da Nota Fiscal'
        : 'Arquivo PDF da Ordem de Carga'
    },
    uploadFileType() {
      return this.agendamento.tipo_documento === 'NF' ? 'text/xml' : '.pdf'
    },
  },
  methods: {
    ...mapActions('agendamentos', ['updateAtributoAgendamento']),
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },

    async abrirInsercaoArquivo() {
      await this.$refs['file'].click()
    },

    async sendDocs() {
      const documentos = this.documentos
      let names = []
      await documentos.forEach(async (documento) => {
        if (!documento.necessity_send) {
          names.push(documento.attachment_key)
          return
        }

        let formData = new FormData()
        formData.append('description', documento.name)
        formData.append('file', documento)
        let data = await FileApi.postAnexosAgendamento(formData)
        if (data.attachment_key) {
          names.push(data.attachment_key)
        }
      })
    },

    async handleUpload(e) {
      this.documentosList.filter((i) => !i.etapa_documento)
      const file = e.target.files[0]

      this.documento_id += 1
      file.id = this.documento_id
      this.documentosList.push({
        description: file.name,
        id: this.documento_id,
        necessity_send: true,
        etapa_documento: true,
      })
      this.documentos.push(file)
      this.documentos[this.documentos.length - 1].necessity_send = true

      if (this.agendamento.tipo_documento === 'NF')
        await this.handleFileXmlUpload(file)
      else await this.handleFilePdfUpload()

      this.$emit('insereDocumentoNosAnexos', this.documentosList)
    },

    async handleFileXmlUpload(file) {
      let formData = new FormData()
      formData.append('file', file)

      let data = await FileApi.convertXmlToJson(formData)
      this.dadosNotaFiscal = JSON.parse(data)
      this.agendamento.chave_acesso = this.dadosNotaFiscal.nfeProc.protNFe.infProt.chNFe
      this.dadosNotaFiscal.file = file
      this.$emit('informacoesNotaFiscal', this.dadosNotaFiscal)
    },

    handleFilePdfUpload: async () => this.$emit('informacoesTipoDocumento'),
  },
}
</script>

<style></style>
