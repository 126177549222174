<template>
  <div class="text-center">
    <form-wizard
      color="#00aa9e"
      :title="null"
      :subtitle="null"
      @on-complete="submitAdicionarMotorista"
      class="cadastroEmpresa"
    >
      <tab-content
        title="CPF do motorista"
        icon="ti-user"
        :before-change="() => validarCPFInformado()"
      >
        <etapa-cpf
          ref="etapaCpf"
          @on-validate="verificaCPFExistente"
          v-model="cpf"
        />
      </tab-content>
      <tab-content
        title="Dados do motorista"
        icon="ti-user"
        :before-change="() => cadastrarContaMotorista()"
      >
        <etapa-dados-motorista
          ref="etapaDadosMotorista"
          v-model="usuario"
          :isUserRegistred="isMotoristaRegistred"
        />
      </tab-content>

      <tab-content
        title="Foto e biometria"
        icon="ti-settings"
        :before-change="validarFotoBiometria"
      >
        <etapa-foto-biometria
          ref="etapaFotoBiometria"
          :fotoBiometria="fotoBiometria"
        />
      </tab-content>

      <tab-content title="Anexar documentos" icon="ti-check">
        <etapa-documentos
          ref="etapaDocumentos"
          v-model="documentos"
          @setNames="setDocs"
          @remove-documento="removeDocumento"
        />
      </tab-content>
      <v-btn color="black" dark slot="prev">
        <i class="mdi mdi-chevron-left mr-1"></i>
        Anterior
      </v-btn>
      <v-btn color="info" slot="next">
        Próximo
        <i class="mdi mdi-chevron-right ml-1"></i>
      </v-btn>
      <v-btn color="success" slot="finish">
        Finalizar
        <i class="mdi mdi-bookmark-check ml-1"></i>
      </v-btn>
    </form-wizard>
    <div>
      <v-dialog v-model="dialog" persistent max-width="700">
        <v-card>
          <v-card-title class="text-h5">
            Motorista encontrado mas não vinculado!
          </v-card-title>
          <v-card-text>Deseja vincular esse motorista?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="dialog = false">
              Não
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="
                () => {
                  submitAdicionarMotorista()
                  dialog = false
                }
              "
            >
              Sim
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import { mapActions, mapState } from 'vuex'

import EtapaCpf from './EtapasCadastro/EtapaCpf'
import EtapaDadosMotorista from './EtapasCadastro/EtapaDadosMotorista'
import EtapaFotoBiometria from './EtapasCadastro/EtapaFotoBiometria'
import EtapaDocumentos from './EtapasCadastro/EtapaDocumentos'

import EmpresaApi from '@/services/tenants/empresa'
import MotoristasApi from '@/services/tenants/motoristas'
import UsuarioApi from '@/services/usuario'
import moment from 'moment'
import FileApi from '@/services/files/index'

import { cpf } from 'cpf-cnpj-validator'

export default {
  props: {
    motoristaExistente: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      dialog: false,
      title: 'Adicionar Motorista',
      cpf: '',
      isMotoristaRegistred: false,
      isUserRegistred: false,
      usuario: {
        cpf: '',
        name: '',
        celular: '',
        data_nascimento: '2022-01-01',
        rg: '',
        rg_orgao_emissor: '',
        rg_uf_orgao_emissor: '',
        nacionalidade: 'Brasil',
        email: '',
        cnh_registro: '000000',
        cnh_emissao: '',
        cnh_validade: '',
        cnh_categoria: 'D',
        cnh_uf_emissor: '',
      },
      usuarioDefault: {
        avatar: null,
        celular: null,
        cnh_categoria: null,
        cnh_emissao: null,
        cnh_registro: null,
        cnh_uf_emissor: null,
        cnh_validade: null,
        cnpj: null,
        cpf: null,
        data_nascimento: null,
        email: null,
        nacionalidade: 'Brasil',
        name: null,
        rg: null,
        rg_orgao_emissor: null,
        rg_uf_orgao_emissor: null,
        tenants: [],
        username: null,
      },
      motorista: {},
      fotoBiometria: {},
      documentos: [],
      documentosNome: [],
      btnConfirm: true,
    }
  },
  components: {
    FormWizard,
    TabContent,
    EtapaDadosMotorista,
    EtapaCpf,
    EtapaFotoBiometria,
    EtapaDocumentos,
  },
  created() {
    if (Object.keys(this.motoristaExistente).length > 0) {
      if (Object.keys(this.motoristaExistente.user).length > 0) {
        this.$nextTick(async () => {
          this.cpf = this.motoristaExistente.user.cpf
          const nacionalidade = this.motoristaExistente.nacionalidade
          this.$refs['etapaCpf'].nacionalidade = nacionalidade
          this.$refs['etapaCpf'].changeTypeMask(nacionalidade)
          this.$nextTick(async () => {
            this.$refs['etapaCpf'].cpf = this.cpf
          })
          this.verificaCPFExistente(true)
        })
      }
    }
  },
  methods: {
    ...mapActions('template', ['errorMessage', 'successMessage']),
    validarCPFInformado() {
      const refToValidate = this.$refs['etapaCpf']
      return refToValidate.validate()
    },

    async verificaCPFExistente(isValid) {
      if (!isValid) return false

      this.$nextTick(async () => {
        try {
          const hasUser = await UsuarioApi.getUsuarioByCpf(cpf.strip(this.cpf))
          if (hasUser) {
            this.isUserRegistred = true
            const motorista = await MotoristasApi.getMotorista(
              this.empresaAtual,
              {
                search: cpf.strip(this.cpf),
                public_id:
                  this.motoristaExistente?.user?.cpf == this.cpf
                    ? this.motoristaExistente.public_id
                    : undefined,
              }
            )
            Object.assign(this.usuario, hasUser)
            if (motorista.length > 0 && motorista[0]) {
              Object.assign(this.documentos, motorista[0].documentos)
              this.motorista = { public_id: motorista[0].public_id }
              this.motorista.empresa = motorista[0].empresa
              this.isMotoristaRegistred = true
              this.$refs.etapaDocumentos.setDocumentosList(
                motorista[0].documentos
              )

              if (motorista[0].foto) {
                this.fotoBiometria = {
                  foto: motorista[0].foto ? motorista[0].foto.url : null,
                }
                this.$refs.etapaFotoBiometria.setUrlImagemPreview(
                  this.fotoBiometria.foto
                )
              } else {
                this.fotoBiometria = { foto: null }
                this.$refs.etapaFotoBiometria.setUrlImagemPreview(
                  this.fotoBiometria.foto
                )
              }
            } else {
              this.dialog = true
            }
          } else {
            if (this.cpf === this.usuario.cpf) return true
            Object.assign(this.usuario, this.usuarioDefault)
            delete this.usuario.public_id
            Object.assign(this.fotoBiometria, { foto: null })
            this.documentos = []
            this.$refs.etapaDocumentos.setDocumentosList([])
            this.$refs.etapaFotoBiometria.setUrlImagemPreview(null)
            this.usuario.cpf = cpf.strip(this.cpf)
            this.isMotoristaRegistred = false
            this.isUserRegistred = false

            this.$nextTick(() => {
              this.$refs.etapaDadosMotorista.resetForm()
            })
          }
          return this.isMotoristaRegistred
        } catch (error) {
          return false
        }
      })
    },

    async cadastrarContaMotorista() {
      if (!this.empresaAtual.public_id) {
        this.errorMessage('É necessário estar logado para continuar.')
        return
      }
      if (!this.$refs.etapaDadosMotorista.validate()) {
        this.errorMessage(
          'É necessário preencher todos os campos corretamente para continuar.'
        )
        return
      }

      return true
    },
    setDocs() {},

    async validarFotoBiometria() {
      if (this.fotoBiometria.foto) {
        if (typeof this.fotoBiometria.foto === 'string') return true
        let formData = new FormData()
        formData.append('file', this.fotoBiometria.foto)
        try {
          const { attachment_key } = await FileApi.postFotoMotorista(formData)
          if (attachment_key) {
            this.motorista.foto = attachment_key
            return true
          } else {
            return false
          }
        } catch (error) {
          this.errorMessage(
            'Erro ao adicionar a imagem. Tente novamente mais tarde ou envie outra imagem!' +
              error
          )
          return false
        }
      } else {
        this.motorista.foto = null
        return true
      }
    },

    removeDocumento(doc) {
      const index = this.documentosNome.indexOf(doc.attachment_key)
      if (index >= 0) {
        delete this.documentosNome[index]
        this.documentosNome = this.documentosNome.filter(
          (documento) => documento !== null
        )
      }

      if (this.documentosNome.length > 0) {
        this.btnConfirm = false
      } else {
        this.btnConfirm = true
      }
    },

    async submitAdicionarMotorista() {
      let usuario

      const documentosAdicionados = await this.$refs.etapaDocumentos.save()
      if (!this.usuario.avatar) delete this.usuario.avatar

      this.usuario.cpf = cpf.strip(this.usuario.cpf)
      this.usuario.is_motorista = true

      if (this.usuario.data_nascimento === null) {
        this.usuario.data_nascimento = '01/01/2022'
      }
      this.usuario.data_nascimento = moment(
        this.usuario.data_nascimento
      ).format('YYYY-MM-DD')

      try {
        if (!this.isUserRegistred) {
          this.usuario.username = this.usuario.cpf
          this.usuario.password = '@@usuario2021..'
          this.usuario.password_confirm = this.usuario.password
          usuario = await UsuarioApi.addUsuario({ ...this.usuario })
        } else {
          usuario = await UsuarioApi.editarUsuario(this.usuario)
        }
      } catch (err) {
        this.errorMessage(err.response.data)
        return
      }

      Object.assign(this.usuario, usuario)
      //   this.motorista.documentos = [...this.documentosNome]
      this.motorista.documentos = [...documentosAdicionados]
      this.motorista.user = this.usuario.public_id
      try {
        let motorista
        if (!this.isMotoristaRegistred) {
          this.motorista.empresa = this.empresaAtual.public_id
          motorista = await EmpresaApi.adicionarMotorista(this.motorista)
          this.successMessage('Motorista adicionado com sucesso!')
          this.$emit('addedMotorista', motorista, this.usuario)
        } else {
          motorista = await EmpresaApi.editarMotorista(this.motorista)
          this.successMessage('Motorista atualizado com sucesso!')
          this.$emit('addedMotorista', this.motorista, this.usuario)
        }
      } catch (err) {
        this.successMessage(`Motorista adicionado com sucesso`)
        return
      }
    },

    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('usuario', ['usuarios']),
  },
}
</script>
