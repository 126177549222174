<template>
  <base-modal :formTitle="formTitle">
    <template v-slot:body>
      <v-row>
        <v-data-table :headers="headers" :items="itens" class="border">
          <template v-slot:[`item.data_inicio`]="{ item }">
            {{ ajustaData(new Date(item.data_inicio)).toLocaleString('pt-BR') }}
          </template>
          <template v-slot:[`item.data_fim`]="{ item }">
            {{ ajustaData(new Date(item.data_fim)).toLocaleString('pt-BR') }}
          </template>
          <template v-slot:[`item.qt_caminhoes`]="{ item }">
            {{ qt_restante(item) }}
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  class="mr-2 mb-2"
                  dark
                  small
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item class="pointer" @click="handleAgendar(item)"
                  >Agendar</v-list-item
                >
                <v-list-item class="pointer" @click="handleDetalhes(item)"
                  >Detalhes</v-list-item
                >
              </v-list>
            </v-menu>
          </template>
          <template v-slot:no-data>
            <span>Sem janelas</span>
          </template>
        </v-data-table>
      </v-row>
    </template>
    <template v-slot:buttons>
      <div>
        <v-btn color="primary" @click="$emit('close')"> Fechar </v-btn>
      </div>
    </template>
  </base-modal>
</template>
<script>
import BaseModal from '@/components/BaseModal/BaseModal.vue'
import { mapActions } from 'vuex'

export default {
  components: { BaseModal },
  name: 'FormJanelaCargaDescarga',
  props: {
    janelaCargaDescarga: {
      type: Object,
      required: false,
    },
    formTitle: String,
    value: {
      type: Object,
      required: false,
    },
    itens: {
      type: Array,
      default: () => [],
    },
    headers: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    ...mapActions('agendamentos', ['updateAtributoAgendamento']),
    qt_restante(item) {
      const agendamentos = item.agendamentos || []
      const restante = item.qt_caminhoes - agendamentos.length
      return restante > 0 ? restante : 0
    },
    ajustaData(date) {
      const add_3_hours = 1000 * 60 * 60 * 3
      date.setTime(date.getTime() + add_3_hours)
      return date
    },
    handleAgendar(item) {
      this.updateAtributoAgendamento({
        valor: item,
        nomeAtributo: 'janela_carga_descarga',
      })
      this.$emit('close')
    },
  },

  async created() {},
}
</script>
