<template>
  <div>
    <validation-observer ref="observer">
      <v-tabs icons-and-text v-model="tab">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#dados">
          Dados gerais <v-icon>mdi-home-city</v-icon></v-tab
        >
        <v-tab href="#documentos">
          Documentos <v-icon>mdi-format-list-bulleted-square</v-icon></v-tab
        >
        <v-tabs-items v-model="tab">
          <v-tab-item value="dados">
            <etapa-dados-implemento
              ref="etapaDadosImplemento"
              v-model="implemento"
              @save="saveDadosImplemento"
              @close="close"
              edit
              hasButtonSave
            />
          </v-tab-item>
          <v-tab-item value="documentos">
            <v-container>
              <etapa-documentos
                ref="etapaDocumentos"
                v-model="documentos"
                @setNames="setDocs"
                @remove-documento="removeDocumento"
                @save="saveDocumentos"
                @close="close"
                hasButtonSave
              />
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </validation-observer>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

import EtapaDocumentos from './etapas/EtapaDocumentos'
import EtapaDadosImplemento from './etapas/EtapaDadosImplemento'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  name: 'EditarImplemento',
  data() {
    return {
      tab: '',
      implemento: {},
      documentos: [],
    }
  },
  components: {
    EtapaDocumentos,
    EtapaDadosImplemento,
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
  created() {
    this.implemento = this.value
    this.documentos = this.value.documentos
  },

  methods: {
    ...mapActions('implemento', [
      'addImplemento',
      'editImplemento',
      'updateDocumentosImplemento',
    ]),
    ...mapActions('template', ['successMessage', 'errorMessage']),
    close() {
      this.$emit('close')
    },
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },
    wordUpper(event) {
      event.target.value = event.target.value.toUpperCase()
    },
    wordUpperChange(field, value) {
      this.value[field] = value.toUpperCase()
    },
    setDocs() {},
    removeDocumento(doc) {
      const index = this.documentos.indexOf(doc.attachment_key)
      if (index >= 0) {
        delete this.documentos[index]
        this.documentos = this.documentos.filter(
          (documento) => documento !== null
        )
      }
    },
    async saveDadosImplemento() {
      if (this.validate()) {
        try {
          const data = { ...this.implemento }
          if (data.documentos) {
            delete data.documentos
          }
          const implemento = await this.editImplemento(data)
          this.$emit('addedImplemento', implemento)
          this.successMessage('Implemento editado com sucesso!')
        } catch (error) {
          console.log(error)
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },
    async saveDocumentos() {
      if (this.validate()) {
        try {
          const documentosAdicionados = await this.$refs.etapaDocumentos.save()
          this.implemento.documentos = [...documentosAdicionados]
          const implemento = await this.updateDocumentosImplemento({
            ...this.implemento,
          })
          this.$emit('addedImplemento', implemento)
          this.successMessage('Documentos editados com sucesso!')
        } catch (error) {
          console.log(error)
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
        }
      }
    },
    async save() {},
  },
}
</script>
<style></style>
