<template>
  <v-row justify="space-around">
    <v-col v-for="(_, index) in pesagens" :key="index" cols="12" md="6" lg="4">
      <v-card>
        <v-card-text>
          <v-container>
            <v-card-title class="px-0 pt-0 my-0">
              <v-icon small title="Editar" @click="edit(index)"
                >mdi-pencil</v-icon
              >
              <v-spacer> </v-spacer>
              <span class="text-caption">{{
                pesagens[index].public_id || 'Nova pesagem'
              }}</span>
              <v-spacer></v-spacer>
              <v-icon small title="Remover" @click="remove(index)"
                >mdi-trash-can</v-icon
              >
            </v-card-title>
            <v-card-text>
              <FormPesagem v-model="pesagens[index]" :readonly="true" />
            </v-card-text>
          </v-container>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import FormPesagem from './FormPesagem.vue'
import { mapState } from 'vuex'
export default {
  components: { FormPesagem },
  name: 'DadosPesagem',
  data() {
    return {
      dialog: false,
    }
  },
  created() {},
  computed: {
    ...mapState('agendamentos', ['agendamento']),
    pesagens() {
      return this.agendamento.pesagens
    },
  },
  methods: {
    edit(index) {
      console.log('edit...', index)
      this.$emit('edit', index)
    },
    remove(index) {
      console.log('remove...', index)
      this.$emit('remove', index)
    },
  },
}
</script>
