<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="80%"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ title }}</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialog = false"> Fechar </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <form-adicionar-relacao-cliente
                @addedCliente="addedCliente"
                :empresa="empresa"
                v-if="dialog"
              ></form-adicionar-relacao-cliente>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import FormAdicionarRelacaoCliente from '@/components/administrativo/cliente/adicionarCliente/FormAdicionarRelacaoCliente.vue'

export default {
  props: {
    empresa: {
      type: Object,
      required: false,
      default: undefined,
    },
  },
  name: 'ModalCliente',
  data() {
    return {
      dialog: false,
      title: 'Adicionar Cliente',
    }
  },
  components: {
    FormAdicionarRelacaoCliente,
  },
  methods: {
    async addedCliente(cliente) {
      this.dialog = false
      this.$emit('addedCliente', cliente)
    },
  },
  computed: {},
}
</script>
