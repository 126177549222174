<template>
  <v-card-text class="pa-5 border-bottom">
    <v-form>
      <v-row class="justify-center d-flex py-10">
        <v-col cols="12" sm="5" md="5" class="pt-0">
          <div class="preview">
            <img
              v-if="urlImagemPreview"
              :src="urlImagemPreview"
              alt="image-preview"
              class="image-preview"
            />
            <div v-else />
          </div>
          <v-btn color="primary" dark class="mb-2" @click="abrirFoto"
            >Enviar foto</v-btn
          >
          <input
            type="file"
            ref="foto"
            @change="changeArquivo"
            style="display: none"
            accept="image/*"
          />
          <div class="text--remove-foto">
            <p @click="removeFoto">Remover foto</p>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions v-if="hasButtonSave">
      <v-spacer></v-spacer>
      <buttons-cadastro @close="$emit('close')" @save="save" />
    </v-card-actions>
  </v-card-text>
</template>

<script>
import ButtonsCadastro from '@/components/ButtonsCadastro/ButtonsCadastro'
import { mapActions } from 'vuex'
import FileApi from '@/services/files/index'

export default {
  name: 'EtapaFotoBiometria',
  props: {
    value: Object,
    fotoBiometria: Object,
    hasButtonSave: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: { ButtonsCadastro },
  data() {
    return {
      urlImagemPreview: null,
    }
  },
  methods: {
    ...mapActions('template', ['errorMessage']),
    abrirFoto() {
      this.$refs['foto'].click()
    },
    changeArquivo(e) {
      const files = e.target.files
      if (files.length) {
        console.log(this.fotoBiometria)
        this.fotoBiometria.foto = files[0]
        this.urlImagemPreview = URL.createObjectURL(files[0])
      }
    },
    setUrlImagemPreview(url) {
      this.urlImagemPreview = url
      this.fotoBiometria.url = url
    },
    removeFoto() {
      delete this.fotoBiometria.foto
      this.urlImagemPreview = null
    },

    async save() {
      console.log(this.fotoBiometria)
      if (await this.sendFoto()) {
        this.value = this.fotoBiometria.foto
        this.$emit('updateFoto', this.fotoBiometria.foto.attachment_key)
      } else {
        this.errorMessage('Erro ao adicionar a imagem.')
      }
    },
    async sendFoto() {
      if (this.fotoBiometria.foto) {
        if (typeof this.fotoBiometria.foto === 'string') return true
        let formData = new FormData()
        formData.append('file', this.fotoBiometria.foto)
        try {
          if (!('attachment_key' in this.fotoBiometria.foto)) {
            const { attachment_key } = await FileApi.postFotoMotorista(formData)
            if (attachment_key) {
              this.fotoBiometria.foto.attachment_key = attachment_key
              return true
            } else {
              return false
            }
          }
          return false
        } catch (error) {
          this.errorMessage(
            'Erro ao adicionar a imagem. Tente novamente mais tarde ou envie outra imagem!' +
              error
          )
          return false
        }
      } else {
        this.fotoBiometria.foto = null
        return false
      }
    },
  },
}
</script>

<style lang="scss">
.preview {
  margin: auto;
  padding-bottom: 50px;

  > div,
  > img {
    width: 300px;
    height: 300px;
    margin: auto;
    border: 3px solid #ccc;
  }

  > div {
    margin: auto;
    border: 3px solid #ccc;
    background: #ededed;
  }
}
.text--remove-foto {
  display: flex;
  justify-content: center;

  p {
    cursor: pointer;
  }
}
.text--remove-foto p:hover {
  color: #00aa9e;
}
</style>
