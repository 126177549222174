<template>
  <validation-provider v-slot="{ errors }" :name="label" rules="required">
    <v-autocomplete
      :label="label"
      :placeholder="placeholder"
      required
      return-object
      :items="items"
      :item-text="
        (item) =>
          item.business_name +
          ' - ' +
          `${item.cnpj != null ? item.cnpj : item.cpf}`
      "
      item-value="public_id"
      v-model="intValue"
      :error-messages="errors"
      :readonly="readonly"
      :disabled="disabled"
    />
  </validation-provider>
</template>

<script>
export default {
  props: {
    label: {
      required: true,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    items: {
      type: Array,
      default: () => [],
    },
    value: {
      required: false,
      type: Object,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
  },
  data() {
    return {
      intValue: this.value,
    }
  },
  watch: {
    value(val) {
      this.intValue = val
    },
    intValue(val) {
      this.$emit('input', val)
      this.$emit('change', val)
    },
  },
}
</script>

<style></style>
