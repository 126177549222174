<template>
  <div class="container mx-auto">
    <v-dialog
      v-if="showModalClassificacao"
      v-model="showModalClassificacao"
      max-width="50%"
    >
      <classificacao-modal
        :pedidoAgendado="pedidoAgendado"
        @cancelar="showModalClassificacao = false"
      />
    </v-dialog>
    <v-card
      v-for="pedidoAgendado in pedidosAClassificar"
      :key="pedidoAgendado.public_id"
      class="pa-4 d-flex align-center"
    >
      <v-card-subtitle class="text-no-wrap"
        >Pedido Nº {{ pedidoAgendado.pedido.numero_pedido }}</v-card-subtitle
      >
      <v-card-text class="pa-0">
        Produto {{ pedidoAgendado.pedido.produto.descricao }}
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="pa-4"
          color="primary"
          :disabled="pedidoAgendado.classificado"
          @click="classificar(pedidoAgendado)"
          >{{
            pedidoAgendado.classificado ? 'Classificado' : 'Classificar'
          }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ClassificacaoModal from './classificacao/ClassificacaoModal.vue'

export default {
  name: 'EtapaClassificacao',
  components: { ClassificacaoModal },
  data() {
    return {
      pedidosAClassificar: [],
      showModalClassificacao: false,
      pedidoAgendado: {},
    }
  },
  computed: {
    ...mapState('agendamentos', ['agendamento']),
    todosForamClassificados() {
      return this.pedidosAClassificar.every((pac) => pac.classificado)
    },
  },
  methods: {
    async classificar(pedidoAgendado) {
      if (!pedidoAgendado.classificado) {
        this.showModalClassificacao = true
        this.pedidoAgendado = pedidoAgendado
      }
    },
  },
  watch: {
    'agendamento.pedidos_agendados'(newVal) {
      this.pedidosAClassificar = [...newVal]
    },
  },
  created() {
    this.pedidosAClassificar = [...this.agendamento.pedidos_agendados]
  },
}
</script>
