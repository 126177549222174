<template>
  <div>
    <validation-observer ref="observer">
      <v-card-text class="py-6">
        <v-container>
          <v-row class="justify-center d-flex py-10">
            <v-data-table
              :headers="headers"
              :items="value.documentos"
              sort-by="descricao"
              class="border"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Documento adicionado</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                </v-toolbar>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small @click="removeDocumento(item)">mdi-delete</v-icon>
              </template>
              <template v-slot:no-data>
                <span>Sem documentos adicionados</span>
              </template>
            </v-data-table>
          </v-row>
        </v-container>
      </v-card-text>
    </validation-observer>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    obrigaXML: Boolean,
  },
  name: 'ModalPedidoDocumentos',
  data() {
    return {
      pedido: {},
      documentos: [],
      headers: [
        {
          text: 'Nome',
          align: 'start',
          value: 'description',
        },
        { text: 'Ações', value: 'actions', sortable: false, align: 'end' },
      ],
    }
  },

  computed: {
    ...mapState('auth', ['empresaAtual']),
  },
  created() {
    this.pedido = this.value
    this.documentos = this.value.documentos
  },

  methods: {
    ...mapActions('template', ['errorMessage']),
    close() {
      this.$emit('close')
    },
    validate() {
      this.$refs.observer.validate()
      const isValid = !this.$refs.observer.flags.invalid
      return isValid
    },
    wordUpper(event) {
      event.target.value = event.target.value.toUpperCase()
    },
    wordUpperChange(field, value) {
      this.value[field] = value.toUpperCase()
    },
    setDocs() {},
    removeDocumento(doc) {
      if (this.obrigaXML && this.value.documentos.length > 1) {
        let field, value
        if (doc.id) {
          field = doc.attachment_key ? 'attachment_key' : 'id'
          value = doc.attachment_key ? doc.attachment_key : doc.id
        } else {
          field = 'name'
          value = doc.name
        }
        console.log('deletando...')
        console.log(field, value)
        // const index = this.value.documentos.findIndex(
        //   (item) => item[field] == value
        // )
        // if (index >= 0) {
        //   delete this.value.documentos[index]
        //   this.value.documentos = this.value.documentos.filter(
        //     (documento) => documento !== null
        //   )
        // }
      } else {
        this.errorMessage('É necessario ao menos um documento.')
      }
    },
  },
}
</script>
<style></style>
