<template>
  <div>
    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        hide-overlay
        transition="dialog-bottom-transition"
        max-width="80%"
        scrollable
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="text-h5">{{ title }}</span>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="dialog = false"> Fechar </v-btn>
          </v-card-title>
          <v-card-text>
            <v-container>
              <cadastro-veiculo
                @addedVeiculo="addedVeiculo"
                :veiculoEdit="this.veiculo"
                v-if="dialog"
              />
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import CadastroVeiculo from './CadastroVeiculo'
export default {
  props: {
    veiculo: {
      type: Object,
      required: false,
    },
  },
  name: 'ModalVeiculo',
  data() {
    return {
      dialog: false,
      title: 'Adicionar Veiculo',
    }
  },
  components: {
    CadastroVeiculo,
  },
  methods: {
    async addedVeiculo(veiculo) {
      this.dialog = false
      this.$emit('addedVeiculo', veiculo)
    },
  },
  computed: {},
}
</script>
