import { mapState } from "vuex";
import { TipoControladorSenha } from "@/utils/controladores"

export const computed = {
  ...mapState('auth', ['empresaAtual']),
  ...mapState("agendamentos", ["agendamento"]),
  getSenha() {
    let senha = this.agendamento.senhas.filter(
      item =>
        item.tipo == TipoControladorSenha.Produto.id ||
        item.tipo == TipoControladorSenha.SubProduto.id
    )[0]
    return senha
  },

  mostraItem() {
    if(this.agendamento.gate.public_id == '4856f9c1-94d6-4daa-90b6-5b9d4e5c44c8'){
      return false
    }

    if(this.agendamento.gate.public_id == '9c027477-7337-4b6f-b686-9079a35dd1c0'){
      return false
    }
    return true
  },
}
