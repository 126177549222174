<template>
  <v-row>
    <v-col class="12">
      <v-card class="mb-2">
        <v-card-text class="pa-2 border-bottom">
          <h3
            class="subtitle blue-grey--text text--darken-2 font-weight-regular"
          >
            Pesagem
          </h3>
        </v-card-text>
        <v-card-text class="pa-5 border-bottom">
          <v-row>
            <v-col cols="12" md="10" lg="8" offset-md="1" offset-lg="2">
              <v-card>
                <v-card-text>
                  <v-container>
                    <validation-observer ref="observer" v-slot="{ invalid }">
                      <v-card-title>
                        <div class="d-flex flex-column align-start">
                          <span class="headline">Pesagem</span>
                          <span class="text-caption">{{
                            pesagem.public_id || 'Nova pesagem'
                          }}</span>
                        </div>
                      </v-card-title>
                      <v-card-text>
                        <FormPesagem
                          v-model="pesagem"
                          :tipos_pesagens="tipos_pesagens"
                          :pesagem_agendamento="true"
                        />
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          dense
                          color="red darken-1"
                          text
                          :disabled="invalid"
                          @click="resetaPesagem"
                          >Cancelar</v-btn
                        >
                        <v-btn
                          dense
                          color="blue darken-1"
                          text
                          :disabled="invalid"
                          @click="addPesagem"
                          >Salvar</v-btn
                        >
                      </v-card-actions>
                    </validation-observer>
                  </v-container>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <DadosPesagem
            :pesagens="pesagens"
            @edit="editPesagem"
            @remove="removePesagem"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Pesagem } from '@/utils/pesagem'
import { BRString2datetimeIso } from '@/mixins/convertion'
import PatioApi from '@/services/patio/index'

import DadosPesagem from './DadosPesagem.vue'
import FormPesagem from './FormPesagem.vue'

export default {
  mixins: [BRString2datetimeIso],
  data() {
    return {
      editedIndex: -1,
      pesagem_default: {
        public_id: '',
        peso: 0,
        data: '',
        tipo_pesagem: 0,
        observacao: '',
      },
      pesagem: {
        public_id: '',
        peso: 0,
        data: '',
        tipo_pesagem: 0,
        observacao: '',
      },
    }
  },
  components: { DadosPesagem, FormPesagem },
  created() {
    this.tipos_pesagens = [
      { id: Pesagem.PBO.id, descricao: Pesagem.PBO.name },
      { id: Pesagem.PLO.id, descricao: Pesagem.PLO.name },
    ]
  },
  computed: {
    ...mapState('agendamentos', ['agendamento']),
    pesagens() {
      return this.agendamento.pesagens
    },
  },
  methods: {
    ...mapActions('template', ['successMessage', 'errorMessage']),
    ...mapActions('agendamentos', ['agendamento', 'updateAtributoAgendamento']),
    validaPesagem() {
      if (this.pesagem.peso <= 0) {
        console.log('Peso de ser maior que 0!')
        this.errorMessage('Peso de ser maior que 0!')
        return false
      }
      let data_valida = true
      if (this.pesagem.data != '') {
        let data
        try {
          data = new Date(this.BRString2datetimeIso(this.pesagem.data))
          if (isNaN(data)) {
            data_valida = false
          }
        } catch (error) {
          console.log(error)
          data_valida = false
        }
      }
      if (!data_valida) {
        this.errorMessage(`Data (${this.pesagem.data}) precisa ser valida!`)
        return false
      }
      return true
    },
    resetaPesagem() {
      this.editedIndex = -1
      this.$nextTick(() => {
        Object.assign(this.pesagem, this.pesagem_default)
      })
    },
    async addPesagem() {
      if (!this.validaPesagem()) return
      let pesagens = this.pesagens
      if (this.editedIndex >= 0) {
        if (this.pesagem.public_id) {
          try {
            const data_pesagem = this.BRString2datetimeIso(this.pesagem.data)
            await PatioApi.editarPesagem({
              ...this.pesagem,
              data: data_pesagem ? new Date(data_pesagem).toISOString() : null,
            })
            this.successMessage('Editado com sucesso!')
          } catch (error) {
            this.resetaPesagem()
            if (error.response) {
              this.errorMessage(error.response.data)
              return
            }
            if (error.message) {
              this.errorMessage(error.message)
              return
            }
            this.errorMessage(error)
            return
          }
        }
        Object.assign(pesagens[this.editedIndex], this.pesagem)
      } else {
        pesagens.push({ ...this.pesagem })
      }
      this.updateAtributoAgendamento({
        valor: pesagens,
        nomeAtributo: 'pesagens',
      })
      this.resetaPesagem()
    },
    editPesagem(index) {
      Object.assign(this.pesagem, this.agendamento.pesagens[index])
      this.editedIndex = index
    },
    async removePesagem(index) {
      let pesagens = this.pesagens
      if (pesagens[index].public_id) {
        try {
          await PatioApi.deletePesagem(pesagens[index])
          this.successMessage('Removido com sucesso!')
        } catch (error) {
          if (error.response) {
            this.errorMessage(error.response.data)
            return
          }
          if (error.message) {
            this.errorMessage(error.message)
            return
          }
          this.errorMessage(error)
          return
        }
      }
      pesagens.splice(index, 1)
      this.updateAtributoAgendamento({
        valor: pesagens,
        nomeAtributo: 'pesagens',
      })
    },
  },
}
</script>

<style></style>
