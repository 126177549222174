<template>
  <div>
    <v-row>
      <v-col cols="12" :md="readonly ? 12 : 4">
        <validation-provider v-slot="{ errors }" name="peso" rules="required">
          <v-text-field
            dense
            type="number"
            v-model="value.peso"
            label="Peso (Kg)"
            hint="Valor da pesagem"
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-weight"
            required
            :readonly="readonly"
            :rules="[(v) => v > 0 || 'Peso deve ser inserido']"
          ></v-text-field>
        </validation-provider>
      </v-col>
      <v-col cols="12" :md="readonly ? 12 : 4">
        <validation-provider v-slot="{ errors }" name="data">
          <v-text-field
            dense
            v-model="value.data"
            label="Data da pesagem"
            hint="Data da pesagem"
            type="datetime"
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-calendar"
            v-mask="['##/##/#### ##:##:##']"
            required
          ></v-text-field>
        </validation-provider>
      </v-col>
      <v-col cols="12" :md="readonly ? 12 : 4">
        <validation-provider v-slot="{ errors }" name="tipo_pesagem">
          <v-select
            dense
            v-model="value.tipo_pesagem"
            label="Tipo"
            hint="Tipo da pesagem"
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-swap-horizontal"
            required
            :readonly="readonly"
            item-text="descricao"
            item-value="id"
            :items="tipos_pesagens"
          />
        </validation-provider> </v-col
      ><v-col
        cols="12"
        v-if="!readonly && !pesagem_agendamento"
        :md="readonly ? 12 : 6"
      >
        <validation-provider v-slot="{ errors }" name="Gate" rules="required">
          <v-autocomplete
            dense
            v-model="value.gate_service"
            label="Gate"
            hint="Gate da pesagem"
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-swap-horizontal"
            required
            :readonly="readonly"
            item-text="descricao"
            item-value="public_id"
            :items="gates_services"
          />
        </validation-provider>
      </v-col>
      <v-col
        cols="12"
        v-if="!readonly && !pesagem_agendamento"
        :md="readonly ? 12 : 6"
      >
        <validation-provider v-slot="{ errors }" name="justificativa">
          <v-autocomplete
            dense
            v-model="value.justificativa"
            label="Justificativa"
            hint="Justificativa da pesagem"
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-swap-horizontal"
            required
            :readonly="readonly"
            item-text="descricao"
            item-value="public_id"
            :items="justificativas"
          />
        </validation-provider>
      </v-col>
      <v-col cols="12" v-if="!readonly">
        <validation-provider v-slot="{ errors }" name="observacao">
          <v-textarea
            dense
            v-model="value.observacao"
            label="Observação"
            :error-messages="errors"
            persistent-hint
            prepend-icon="mdi-invoice-text-edit"
            :readonly="readonly"
          >
            <template v-slot:label>
              <div>Observação <small>(Informações breves)</small></div>
            </template>
          </v-textarea>
        </validation-provider>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { Pesagem } from '@/utils/pesagem'
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
    edit: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    tipos_pesagens: {
      type: Array,
      default: () => {
        return Object.values(Pesagem).map((i) => {
          return { id: i.id, descricao: i.name }
        })
      },
    },
    pesagem_agendamento: {
      type: Boolean,
      default: false,
    },
    justificativas: {
      type: Array,
      default: () => {
        return []
      },
    },
    gates_services: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  components: {},

  data() {
    return {}
  },
  created() {},
  computed: {},

  methods: {
    salvar() {
      this.$emit('save', this.value)
    },
  },
}
</script>

<style></style>
