<template>
  <v-card class="mb-7">
    <validation-observer ref="observer">
      <v-form>
        <v-card-text class="pa-5 border-bottom">
          <dados-info-geral />
          <dados-pedido v-if="is_default" ref="dadosPedido" />
          <dados-veiculo />
          <dados-motorista />
          <dados-remetente-destinatario v-if="is_default" />
          <dados-observacao v-if="is_default" />
        </v-card-text>
      </v-form>
    </validation-observer>
  </v-card>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import DadosInfoGeral from './DadosInfoGeral.vue'
import DadosPedido from './DadosPedido.vue'
import DadosVeiculo from './DadosVeiculo.vue'
import DadosMotorista from './DadosMotorista.vue'
import DadosRemetenteDestinatario from './DadosRemetenteDestinatario.vue'
import DadosObservacao from './DadosObservacao.vue'

import { mapActions, mapState } from 'vuex'

export default {
  components: {
    DadosInfoGeral,
    DadosPedido,
    DadosVeiculo,
    DadosMotorista,
    DadosObservacao,
    DadosRemetenteDestinatario,
  },
  computed: {
    ...mapState('agendamentos', ['agendamento']),
    is_default() {
      return ['NF', 'OC', 'VR'].includes(this.agendamento.tipo_documento)
    },
  },
  name: 'EtapaAgendamento',
  methods: {
    ...mapActions('patio', [
      'getModalidades',
      'getCarrocerias',
      'getTipoOperacao',
      'getTiposFrete',
    ]),

    ...mapActions('template', ['errorMessage']),
    async sendDocs() {
      if (this.is_default) {
        await this.$refs.dadosPedido.sendDocs()
      }
    },
    async validate() {
      await this.$refs.observer.validate()
      const fields_motorista = ['Documento', 'celular', 'registro da CNH']
      const fields_veiculo = [
        'nome',
        'Placa do Cavalo',
        'estados',
        'placa da carreta',
        'modalidade',
        'carroceria',
      ]
      // const isValid = !this.$refs.observer.flags.invalid
      let isValid = true
      const fields_exclude = [...fields_motorista, ...fields_veiculo]
      Object.keys(this.$refs.observer.errors).forEach((field) => {
        if (!fields_exclude.includes(field)) {
          if (this.$refs.observer.errors[field].length > 0) {
            isValid = false
          }
        }
      })
      return isValid
    },

    async atualizarListaTipoOperacao() {
      try {
        await this.getTipoOperacao()
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperado.')
        }
      }
    },

    async atualizarListaModalidade() {
      try {
        await this.getModalidades()
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperado.')
        }
      }
    },

    async atualizarListaCarrocerias() {
      try {
        await this.getCarrocerias()
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.errorMessage('Empresa não é um gate.')
        } else {
          this.errorMessage('Ocorreu algum erro inesperado.')
        }
      }
    },
  },
  async created() {
    await this.atualizarListaTipoOperacao()
    await this.atualizarListaModalidade()
    await this.atualizarListaCarrocerias()
    await this.getTiposFrete()
  },
}
</script>
