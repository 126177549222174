<template>
  <v-card>
    <v-card-title class="d-flex flex-column align-start">
      <span class="headline"
        >{{ editedItem.public_id ? 'Editar' : 'Adicionar' }} RFID</span
      >
      <span class="text-caption">{{
        editedItem.public_id || 'Não criada'
      }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field v-model="editedItem.rfid" label="RFID"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="editedItem.descricao"
              required
              label="Descrição"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12">
            <v-autocomplete
              v-model="editedItem.empresas"
              :items="empresasList"
              :item-text="
                (item) => `${item.business_name} - ${item.cnpj || item.cpf}`
              "
              item-value="public_id"
              return-object
              :multiple="!editedItem.public_id"
              label="Empresa"
              @keyup.native="customChange($event, handleGetEmpresas)"
              :loading="calling"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close">Cancelar</v-btn>
      <v-btn color="blue darken-1" text @click="save">Salvar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { customChange } from '@/mixins/autocomplete.js'

import TenantsApi from '@/services/tenants'
export default {
  mixins: [customChange],
  data() {
    return {
      editedItem: {
        public_id: null,
        descricao: '',
        rfid: '',
        empresas: null,
      },
      defaultItem: {
        public_id: null,
        descricao: '',
        rfid: '',
        empresas: null,
      },
      empresas: null,
    }
  },
  computed: {
    ...mapState('patio', []),
    ...mapState('auth', ['empresaAtual']),
    formTitle() {
      return this.editedIndex === -1 ? 'Novo RFID' : 'Editar RFID'
    },
    empresasList() {
      return []
        .concat(this.empresas, this.editedItem.empresas)
        .filter((item) => item)
    },
  },

  watch: {},

  async created() {},

  methods: {
    ...mapActions('patio', []),
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    editItem(item) {
      this.editedItem = item
    },
    async handleGetEmpresas(event) {
      const value = event.target.value
      this.empresas = await TenantsApi.getTenantByCPFOrCNPJ({
        pesquisa: value,
      })
    },

    close() {
      this.editedItem = {}
      this.$nextTick(() => {
        Object.assign(this.editedItem, this.defaultItem)
      })
      this.$emit('close')
    },

    save() {
      this.$emit('adicionarRFID', { ...this.editedItem })
      this.close()
    },
  },
}
</script>
